import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faClock } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

function ReservationCancel() {
  const { t, i18n } = useTranslation();
  const { token } = useParams();
  const [reservationDetails, setReservationDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [isActiveReservation, setIsActiveReservation] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  useEffect(() => {
    axios
      .get("customerAIO/cancel/" + token)
      .then((x) => {
        console.log(x.data);
        setReservationDetails(x.data);
        setLoading(false);
        setIsActiveReservation(true);
      })
      .catch((x) => {
        setLoading(false);
        setIsActiveReservation(false);
      });
  }, []);

  const CancelConf = () => {
    const accept = () => {
      setLoading(true);
      axios
        .post(
          `customerAIO/cancel/${token}/${reservationDetails.reservation.id}`
        )
        .then((x) => setLoading(false) & setIsCanceled(true));
    };

    const reject = () => {};

    confirmDialog({
      group: "templating",
      header: t("onaylama"),
      acceptLabel: t("evet"),
      rejectLabel: t("hayir"),
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 font-semibold">
          <div>{t("iptalOnay")}</div>
        </div>
      ),
      accept: () => accept(),
      reject,
    });
  };

  return (
    <>
      <ConfirmDialog group="templating" />

      <div
        className="h-full w-full flex m-0 p-0"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <div
          className="m-0 p-0 hidden md:flex md:col-6 lg:col-6"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(https://app.reserone.com/imgs/${reservationDetails?.reservation?.image})`,
          }}
        />
        <div className="flex flex-column align-items-center m-0 p-0 col bg-white">
          <div className="flex gap-2">
            <img
              className="cursor-pointer"
              onClick={() => i18n.changeLanguage("tr")}
              src={`${process.env.PUBLIC_URL}/tr.png`}
              width={50}
              alt="tr"
              style={{
                borderBottom:
                  i18n.language === "tr" ? "5px solid #F39237" : "none",
              }}
            />
            <img
              className="cursor-pointer"
              onClick={() => i18n.changeLanguage("en")}
              src={`${process.env.PUBLIC_URL}/en.png`}
              width={50}
              alt="tr"
              style={{
                borderBottom:
                  i18n.language === "en" ? "5px solid #F39237" : "none",
              }}
            />
          </div>
          {loading && (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          )}
          {!isCanceled && !loading && isActiveReservation && (
            <>
              <a
                href={reservationDetails?.reservation?.weburl}
                target="_blank"
                className="mt-4 text-center"
              >
                <img
                  className="cursor-pointer"
                  src={`https://app.reserone.com/imgs/${reservationDetails?.reservation?.logo}`}
                  height="100px"
                  alt="logo"
                />
              </a>
              <div className="text-center font-semibold">
                {t("sayin") + " " + reservationDetails?.reservation?.name};
              </div>
              <div className="text-center font-semibold my-2">
                {t("rezKodunuz")}
              </div>
              <div className="text-center font-bold">
                {reservationDetails?.reservation?.reservationToken}
              </div>
              {(reservationDetails?.reservation?.status === 8 ||
                reservationDetails?.reservation.status === 4) && (
                <div
                  className="font-bold text-xl mt-3"
                  style={{ color: "red" }}
                >
                  {t("resiptalEdildi")}
                </div>
              )}
              <div
                className="col-12 m-0 p-0 my-4"
                style={{
                  height: "4px",
                  backgroundColor: "#f1f1f1",
                  width: "90%",
                  borderRadius: 20,
                }}
              />
              <div className="text-center font-bold text-xl my-2">
                {t("rezBilgi")}
              </div>

              <div
                className="font-semibold text-lg mt-2"
                style={{ borderBottom: "1px solid grey" }}
              >
                {i18n.language === "en"
                  ? reservationDetails?.reservation?.mealNameEn
                  : reservationDetails?.reservation?.mealName}
              </div>

              <div className="flex flex-column mt-4 align-items-center gap-7 w-full font-semibold text-md">
                <div
                  className="flex justify-content-between "
                  style={{ width: "60%" }}
                >
                  <div className="flex">
                    <div className="flex align-items-center mr-2">
                      <FontAwesomeIcon icon={faCalendarCheck} fontSize="30px" />
                    </div>
                    <div>
                      <div>{t("gun")}</div>
                      <div>
                        {moment(reservationDetails?.reservation?.date).format(
                          "DD.MM.yy"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex align-items-center mr-2">
                      <FontAwesomeIcon icon={faClock} fontSize="30px" />
                    </div>
                    <div>
                      <div>{t("saat")}</div>
                      <div>
                        {reservationDetails?.reservation?.time.slice(0, 5)}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "60%" }}>
                  <div className="flex">
                    <div className="flex align-items-center mr-2">
                      <FontAwesomeIcon icon={faUsers} fontSize="25px" />
                    </div>
                    <div>
                      <div>{t("kisi")}</div>
                      <div>{reservationDetails?.reservation?.guestCount}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-content-center">
                <Button
                  loading={loading}
                  disabled={!reservationDetails.cancelable}
                  label={
                    reservationDetails.cancelable
                      ? t("iptalEt")
                      : t("iptalEdilemez")
                  }
                  style={{ backgroundColor: "darkred", fontWeight: 700 }}
                  onClick={() => CancelConf()}
                />
              </div>
              <div
                className="col-12 m-0 p-0 my-4"
                style={{
                  height: "4px",
                  backgroundColor: "#f1f1f1",
                  width: "90%",
                  borderRadius: 20,
                }}
              />
              <div className="font-semibold text-600 text-sm">
                {reservationDetails.reservation.address}
              </div>
              <div className="font-semibold text-600 text-sm text-center">
                {reservationDetails.reservation.weburl +
                  " | " +
                  reservationDetails.reservation.mail +
                  " | +" +
                  reservationDetails.reservation.areacode +
                  reservationDetails.reservation.phone}
              </div>
            </>
          )}

          {!isCanceled && !isActiveReservation && (
            <div className="font-semibold my-3">{t("iptalRezBulunamadi")}</div>
          )}
          {isCanceled && (
            <div className="font-semibold my-3">{t("reziptalEdildi")}</div>
          )}
          <a
            href="https://reserone.com/"
            target="_blank"
            className="mt-4 text-center"
          >
            <img
              className="cursor-pointer"
              src={`${process.env.PUBLIC_URL}/logobgwhite.png`}
              height="45px"
              alt="logo"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default ReservationCancel;
