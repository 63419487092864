import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
//import axios from "axios";
//import { Toast } from "primereact/toast";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faCalendar,
  faClock,
  faUsers,
  faPencil,
  faCheck,
  faWalking,
  faBuilding,
  faAddressBook,
  faLocation,
  faMapLocation,
  faLocationCrosshairs,
  faLocationPin,
  faMoneyBillTrendUp,
  faWheatAwnCircleExclamation,
  faBank,
  faMagnifyingGlassLocation,
  faPersonHiking,
  faHandsBound,
  faPersonHalfDress,
  faPersonCane,
  faBoxesStacked,
  faBriefcase,
  faWallet,
  faCrown,
  faRecycle,
  faRepeat,
  faWheelchair,
  faAddressCard,
  faStar,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faBuildingCircleXmark } from "@fortawesome/free-solid-svg-icons/faBuildingCircleXmark";
import { faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons/faMoneyBillAlt";
import { faUpwork } from "@fortawesome/free-brands-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons/faBagShopping";
import { faBandage } from "@fortawesome/free-solid-svg-icons/faBandage";

function AddReservation() {
  const [loading, setLoading] = useState(false);
  const [addCustomerDlg, setaddCustomerDlg] = useState(false);
  const personCountOptions = [
    { label: "1 Kişi", value: 1 },
    { label: "2 Kişi", value: 2 },
    { label: "3 Kişi", value: 3 },
    { label: "4 Kişi", value: 4 },
    { label: "5 Kişi", value: 5 },
    { label: "6 Kişi", value: 6 },
    { label: "7 Kişi", value: 7 },
    { label: "8 Kişi", value: 8 },
    { label: "9 Kişi", value: 9 },
    { label: "10 Kişi", value: 10 },
    { label: "11 Kişi", value: 11 },
    { label: "12 Kişi", value: 12 },
    { label: "13 Kişi", value: 13 },
    { label: "14 Kişi", value: 14 },
    { label: "15 Kişi", value: 15 },
    { label: "16 Kişi", value: 16 },
    { label: "17 Kişi", value: 17 },
    { label: "18 Kişi", value: 18 },
    { label: "19 Kişi", value: 19 },
    { label: "20 Kişi", value: 20 },
    { label: "21 Kişi", value: 21 },
    { label: "22 Kişi", value: 22 },
    { label: "23 Kişi", value: 23 },
    { label: "24 Kişi", value: 24 },
    { label: "25 Kişi", value: 25 },
  ];

  const timeOptions = [
    { label: "10:00", value: "10:00" },
    { label: "11:00", value: "11:00" },
    { label: "12:00", value: "12:00" },
    // Diğer seçenekler
  ];
  return (
    <div
      className="m-0 p-0 "
      style={{
        textAlign: "-webkit-center" /* ortalamak icin */,
      }}
    >
      <Dialog
        visible={addCustomerDlg}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ width: "50vw" }}
        onHide={() => setaddCustomerDlg(false)}
      >
        <>
          <div
            className="px-3 py-2"
            style={{
              borderBottom: "5px solid #f89d31",
              fontWeight: "bold",
              fontSize: 22,
              minWidth: "fit-content",
            }}
          >
            Müşteri Ekle
          </div>

          <div className="flex m-0 p-0">
            <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <FloatLabel>
                    <InputText id="name" />
                    <label for="name">Müşteri Adı</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText id="lastName" />
                    <label for="lastName">Soyadı</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <FloatLabel>
                    <InputText id="mail" />
                    <label for="mail">Mail</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <FloatLabel>
                    <InputText id="areaCode" />
                    <label for="areaCode">Alan Kodu</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText id="telNo" />
                    <label for="telNo">Telefon</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <FloatLabel>
                    <InputText id="customerNote" />
                    <label for="customerNote">Müşteri Notu</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <FloatLabel>
                    <InputText id="companyName" />
                    <label for="companyName">Şirket Adı</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLocationPin} />
                  </span>
                  <FloatLabel>
                    <InputText id="billAddress" />
                    <label for="billAddress">Fatura Adresi</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 500px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBank} />
                  </span>
                  <FloatLabel>
                    <InputText id="taxNo" />
                    <label for="taxNo">Vergi No</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText id="taxAdministration" />
                    <label for="taxAdministration">Vergi Dairesi</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBriefcase} />
                  </span>
                  <FloatLabel>
                    <InputText id="mission" />
                    <label for="mission">Görevi</label>
                  </FloatLabel>
                </div>
              </div>

              <div className="col-12 flex justify-content-center">
                <Button
                  label="Kaydet"
                  severity="success"
                  icon="pi pi-check"
                  className="shadow-4 my-2 px-4"
                  style={{ fontSize: 20, fontWeight: 700 }}
                />
              </div>
            </div>

            <div className="flex flex-column">
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faWallet}
                  width={50}
                  size="2x"
                  className="m-2"
                />
                <label>Bonkör</label>
              </div>
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faCrown}
                  width={50}
                  size="2x"
                  className="m-2"
                />{" "}
                <label>Vip</label>
              </div>{" "}
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faRepeat}
                  width={50}
                  size="2x"
                  className="m-2"
                />
                <label>Devamlı</label>
              </div>{" "}
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faWheelchair}
                  width={50}
                  size="2x"
                  className="m-2"
                />
                <label>Engelli</label>
              </div>{" "}
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faUsers}
                  width={50}
                  size="2x"
                  className="m-2"
                />
                <label>Kurumsal</label>
              </div>
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faAddressCard}
                  width={50}
                  size="2x"
                  className="m-2"
                />
                <label>Tanıdık</label>
              </div>{" "}
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faStar}
                  width={50}
                  size="2x"
                  className="m-2"
                />
                <label>Ünlü</label>
              </div>{" "}
              <div className="flex " style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  width={50}
                  size="2x"
                  className="m-2"
                />{" "}
                <label>Sorunlu</label>
              </div>{" "}
            </div>
          </div>
        </>
      </Dialog>
      <div className="flex flex-row shadow-4">
        <div
          className="px-3 py-2"
          style={{
            borderBottom: "5px solid #F39237",
            fontWeight: "bold",
            fontSize: 22,
            minWidth: "fit-content",
          }}
        >
          Rezervasyon Ekle
        </div>
        <div
          style={{
            borderBottomWidth: 2,
            borderBottom: "5px solid #222d33",
            width: "100%",
          }}
        ></div>
      </div>

      <div
        className="grid p-0 sm:col-12 md:col-12 xl:col-4 lg:col-12" //ekran cozunurluklerine gore boyutlama
        style={{
          gap: "1rem",
          marginTop: "10px",
        }}
      >
        <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </span>
            <FloatLabel>
              <InputText id="firstName" />
              <label for="firstName">Müşteri Adı</label>
            </FloatLabel>
          </div>
          <div className="p-inputgroup flex-1">
            <FloatLabel>
              <InputText id="lastName" />
              <label for="lastName">Soyadı</label>
            </FloatLabel>
          </div>
        </div>

        <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
          <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faPhone} />
            </span>
            <FloatLabel>
              <InputText id="areaCode" />
              <label for="areaCode">Alan Kodu</label>
            </FloatLabel>
          </div>
          <div className="p-inputgroup flex-1">
            <FloatLabel>
              <InputText id="phone" />
              <label for="phone">Telefon</label>
            </FloatLabel>
          </div>
        </div>

        <div className="col-12">
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <FloatLabel>
              <InputText id="mail" />
              <label for="mail">Mail</label>
            </FloatLabel>
          </div>
        </div>

        <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faCalendar} />
            </span>
            <Calendar placeholder="Tarih" style={{ textAlignLast: "center" }} />
          </div>
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faClock} />
            </span>
            <Dropdown placeholder="Saat" options={timeOptions} />
          </div>
        </div>

        <div className="col-12">
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faUsers} />
            </span>
            <Dropdown placeholder="Kişi Sayısı" options={personCountOptions} />
          </div>
        </div>

        <div className="col-12">
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faPencil} />
            </span>
            <FloatLabel>
              <InputText id="customerNote" />
              <label for="customerNote">Müşteri Notu</label>
            </FloatLabel>
          </div>
        </div>

        <div className="col-12">
          <div className="p-inputgroup flex-1">
            <span
              className="p-inputgroup-addon"
              style={{
                backgroundColor: "gainsboro",
                color: "black",
              }}
            >
              <FontAwesomeIcon icon={faPencil} />
            </span>
            <FloatLabel>
              <InputText id="reservationNote" />
              <label for="reservationNote">Rezervasyon Notu</label>
            </FloatLabel>
          </div>
        </div>

        <div
          className="col-12 flex"
          style={{ justifyContent: "space-between" }}
        >
          <Button
            loading={loading}
            label="Walk-in"
            severity="warning"
            icon={
              <FontAwesomeIcon
                icon={faWalking}
                style={{ marginRight: "10px" }}
              />
            }
            className="shadow-4 my-2 px-4"
            style={{ fontSize: 20, fontWeight: 700 }}
            onClick={() => setaddCustomerDlg(true)}
          />
          <Button
            loading={loading}
            label="Kaydet"
            severity="success"
            icon={
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: "10px" }} />
            }
            className="shadow-4 my-2 px-4"
            style={{ fontSize: 20, fontWeight: 700 }}
            onClick={() => setLoading(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default AddReservation;
