import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { addLocale } from "primereact/api";

function TimeManagement() {
  const { t, i18n } = useTranslation();

  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const { userInfo, ddSelectedRestaurant } = useSelector(
    (state) => state.counter
  );

  const [restaurant, setRestaurant] = useState({});
  const [selectedMeal, setSelectedMeal] = useState({});
  const [activeDays, setActiveDays] = useState([]);
  const [activeHours, setActiveHours] = useState([]);
  const [reservationList, setReservationList] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const [currentWeek, setCurrentWeek] = useState(moment());

  const startOfWeek = currentWeek.clone().startOf("isoWeek");
  const endOfWeek = currentWeek.clone().endOf("isoWeek");

  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(startOfWeek.clone().add(i, "days"));
  }
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });
  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) {
      return;
    }
    axios.get(`clientAIO/menulist/${ddSelectedRestaurant?.id}`).then((x) => {
      setRestaurant(x.data);
      setSelectedMeal(x.data.mealTimes[0]);
      setActiveDays(x.data.mealTimes[0]?.activeDays.split("~"));

      if (x.data.mealTimes.length > 0) {
        generateTimeSlots(
          x.data.mealTimes[0].startTime,
          x.data.mealTimes[0].endTime,
          x.data.reservationRange
        );
      }
    });
  }, [ddSelectedRestaurant]);

  useEffect(() => {
    GetReservationList();
  }, [ddSelectedRestaurant?.id, currentWeek, selectedMeal?.id]);

  function GetReservationList() {
    if (
      ddSelectedRestaurant?.id === undefined ||
      selectedMeal?.id === undefined
    ) {
      return;
    }
    setLoading(true);
    axios
      .post(
        `clientAIO/tmreservations/${
          ddSelectedRestaurant.id
        }?startDateTime=${startOfWeek.toISOString()}&endDateTime=${endOfWeek.toISOString()}&mealId=${
          selectedMeal.id
        }`
      )
      .then((x) => {
        setReservationList(x.data);
        setLoading(false);
      });
  }

  const handlePrevWeek = () => {
    setCurrentWeek(currentWeek.clone().subtract(1, "weeks"));
  };

  const handleNextWeek = () => {
    setCurrentWeek(currentWeek.clone().add(1, "weeks"));
  };

  function generateTimeSlots(startTime, endTime, reservationRange) {
    const timeSlots = [];

    let currentTime = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);

    while (currentTime <= end) {
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      timeSlots.push(`${hours}:${minutes}`);

      currentTime.setMinutes(currentTime.getMinutes() + reservationRange);
    }
    setActiveHours(timeSlots);
  }

  function ClickTimeSlot(day, hour) {
    if (
      selectedDates.some(
        (x) =>
          x.date === day.format("YYYY-MM-DD") &&
          x.time === hour &&
          x.mealTimeId === selectedMeal.id
      )
    ) {
      return setSelectedDates(
        selectedDates.filter(
          (x) =>
            x.date !== day.format("YYYY-MM-DD") ||
            x.time !== hour ||
            x.mealTimeId !== selectedMeal.id
        )
      );
    }
    setSelectedDates([
      ...selectedDates,
      {
        mealTimeId: selectedMeal.id,
        date: day.format("YYYY-MM-DD"),
        time: hour,
      },
    ]);
  }

  function PostFillBtn() {
    setLoading(true);
    axios
      .post(
        "clientAIO/tmfillreservations",
        selectedDates.map((x) => {
          return {
            ...x,
            time: x.time + ":00",
            restaurantId: ddSelectedRestaurant.id,
            employeeId: userInfo.userId,
            type: 1,
            customerId: 1,
          };
        })
      )
      .then((x) => {
        GetReservationList();
        setSelectedDates([]);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Seçili tarihler dolduruldu.",
          life: 3000,
        });
      });
  }
  function PostClearBtn() {
    setLoading(true);
    axios
      .post(
        "clientAIO/tmclearreservations",
        selectedDates.map((x) => {
          return {
            ...x,
            time: x.time + ":00",
            restaurantId: ddSelectedRestaurant.id,
            employeeId: userInfo.userId,
            type: 1,
            customerId: 1,
          };
        })
      )
      .then((x) => {
        GetReservationList();
        setSelectedDates([]);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Seçili tarihler boşaltıldı.",
          life: 3000,
        });
      });
  }

  return (
    <>
      <Toast ref={toast} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="flex col-12 justify-content-between flex-wrap gap-2">
          <div
            className="flex gap-3 align-items-center flex-wrap "
            style={{ fontWeight: "500", fontSize: 16 }}
          >
            {t("ogun")} :
            {restaurant.mealTimes?.map((x) => (
              <div
                className="shadow-4 px-2 flex align-items-center cursor-pointer select-none"
                onClick={() => {
                  setSelectedMeal(x);
                  setActiveDays(x.activeDays.split("~"));
                  generateTimeSlots(
                    x.startTime,
                    x.endTime,
                    restaurant.reservationRange
                  );
                }}
                style={{
                  borderRadius: 25,
                  border:
                    selectedMeal.id === x.id ? "2px solid orange" : "none",
                  fontSize: 18,
                }}
              >
                {i18n.language === "en" ? x.nameEn : x.name}
                <div style={{ fontWeight: 400, fontSize: 15, marginLeft: 5 }}>
                  {"(" +
                    x.startTime.slice(0, 5) +
                    " - " +
                    x.endTime.slice(0, 5) +
                    ")"}
                </div>
              </div>
            ))}
          </div>
          <div className="flex align-items-center gap-2">
            {t("secilenleri")}
            <Button
              label={t("doldur")}
              raised
              disabled={selectedDates.length === 0}
              loading={loading}
              style={{ background: "grey", color: "black" }}
              onClick={() => PostFillBtn()}
            />
            <Button
              label={t("temizle")}
              raised
              disabled={selectedDates.length === 0}
              loading={loading}
              style={{
                background: "#A5E6BA",
                color: "darkgreen",
                fontWeight: "700",
              }}
              onClick={() => PostClearBtn()}
            />
          </div>
        </div>
        <div
          className="gap-5 mt-2"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 10,
            fontWeight: 500,
          }}
        >
          <button onClick={handlePrevWeek}>{"<"}</button>
          <span>{`${startOfWeek.format("DD MMM")} -  ${endOfWeek.format(
            "DD MMM"
          )}`}</span>
          <button onClick={handleNextWeek}>{">"}</button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            width: "100%",
            overflowX: "auto",
          }}
        >
          {daysOfWeek.map((day, ind) => {
            const dayNumber = day.isoWeekday();
            return (
              <div
                key={day.format("DDMMYYYY")}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    backgroundColor: "#2c3e50",
                    color: "white",
                    padding: 10,
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  {day.format("DD MMM")} <br /> {day.format("ddd")}
                </div>

                {loading && ind === 3 && (
                  <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                )}

                {!loading &&
                  day.isSameOrAfter(moment().startOf("day")) &&
                  activeDays?.some((c) => parseInt(c) === dayNumber) &&
                  activeHours.map(
                    (hour) =>
                      day
                        .clone()
                        .set({
                          hour: moment(hour, "HH:mm").hour(),
                          minute: moment(hour, "HH:mm").minute(),
                        })
                        .isAfter(moment()) && (
                        <div
                          className="mx-1 my-1 shadow-2 hover:bg-orange-300 cursor-pointer select-none"
                          onClick={() => ClickTimeSlot(day, hour)}
                          key={hour}
                          style={{
                            padding: 10,
                            textAlign: "center",
                            border: "1px solid grey",
                            borderRadius: 5,
                            fontWeight: 500,
                            backgroundColor: selectedDates.some(
                              (a) =>
                                a.mealTimeId === selectedMeal.id &&
                                a.time === hour &&
                                a.date === day.format("YYYY-MM-DD")
                            )
                              ? "#A5E6BA"
                              : reservationList.some(
                                  (a) =>
                                    a.time.slice(0, 5) === hour &&
                                    a.date === day.format("YYYY-MM-DD")
                                )
                              ? "grey"
                              : "white",
                          }}
                        >
                          <div>{hour}</div>
                          <div>
                            {reservationList.some(
                              (a) =>
                                a.time.slice(0, 5) === hour &&
                                a.date === day.format("YYYY-MM-DD")
                            )
                              ? t("dolu")
                              : t("musait")}
                          </div>
                        </div>
                      )
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TimeManagement;
