import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faCalendar,
  faUsers,
  faPencil,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useTranslation } from "react-i18next";

function UpdateReservationDlg({ slcRest, slcRes, CloseUpdateDlg }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(slcRes.date));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loadingFast, setLoadingFast] = useState(false);

  const [selectedReservation, setSelectedReservation] = useState(slcRes);
  const [restaurant, setRestaurant] = useState(slcRest);
  const [selectedAreaCode, setSelectedAreaCode] = useState({
    country: "Turkey",
    code: "90",
    iso: "TR",
  });
  const [selectedMeal, setSelectedMeal] = useState({});
  const [activeHours, setActiveHours] = useState([]);
  const [customerReservations, setCustomerReservations] = useState([]);
  const [customerReservationsDlg, setCustomerReservationsDlg] = useState(false);

  const personCountOptions = Array.from({ length: 100 }, (v, i) => ({
    label: `${i + 1} Kişi`,
    value: i + 1,
  }));

  const areaCodes = [
    { country: "Afghanistan", code: "93", iso: "AF" },
    { country: "Albania", code: "355", iso: "AL" },
    { country: "Algeria", code: "213", iso: "DZ" },
    { country: "American Samoa", code: "1-684", iso: "AS" },
    { country: "Andorra", code: "376", iso: "AD" },
    { country: "Angola", code: "244", iso: "AO" },
    { country: "Anguilla", code: "1-264", iso: "AI" },
    { country: "Antarctica", code: "672", iso: "AQ" },
    { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
    { country: "Argentina", code: "54", iso: "AR" },
    { country: "Armenia", code: "374", iso: "AM" },
    { country: "Aruba", code: "297", iso: "AW" },
    { country: "Australia", code: "61", iso: "AU" },
    { country: "Austria", code: "43", iso: "AT" },
    { country: "Azerbaijan", code: "994", iso: "AZ" },
    { country: "Bahamas", code: "1-242", iso: "BS" },
    { country: "Bahrain", code: "973", iso: "BH" },
    { country: "Bangladesh", code: "880", iso: "BD" },
    { country: "Barbados", code: "1-246", iso: "BB" },
    { country: "Belarus", code: "375", iso: "BY" },
    { country: "Belgium", code: "32", iso: "BE" },
    { country: "Belize", code: "501", iso: "BZ" },
    { country: "Benin", code: "229", iso: "BJ" },
    { country: "Bermuda", code: "1-441", iso: "BM" },
    { country: "Bhutan", code: "975", iso: "BT" },
    { country: "Bolivia", code: "591", iso: "BO" },
    { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
    { country: "Botswana", code: "267", iso: "BW" },
    { country: "Brazil", code: "55", iso: "BR" },
    { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
    { country: "British Virgin Islands", code: "1-284", iso: "VG" },
    { country: "Brunei", code: "673", iso: "BN" },
    { country: "Bulgaria", code: "359", iso: "BG" },
    { country: "Burkina Faso", code: "226", iso: "BF" },
    { country: "Burundi", code: "257", iso: "BI" },
    { country: "Cambodia", code: "855", iso: "KH" },
    { country: "Cameroon", code: "237", iso: "CM" },
    { country: "Canada", code: "1", iso: "CA" },
    { country: "Cape Verde", code: "238", iso: "CV" },
    { country: "Cayman Islands", code: "1-345", iso: "KY" },
    { country: "Central African Republic", code: "236", iso: "CF" },
    { country: "Chad", code: "235", iso: "TD" },
    { country: "Chile", code: "56", iso: "CL" },
    { country: "China", code: "86", iso: "CN" },
    { country: "Christmas Island", code: "61", iso: "CX" },
    { country: "Cocos Islands", code: "61", iso: "CC" },
    { country: "Colombia", code: "57", iso: "CO" },
    { country: "Comoros", code: "269", iso: "KM" },
    { country: "Cook Islands", code: "682", iso: "CK" },
    { country: "Costa Rica", code: "506", iso: "CR" },
    { country: "Croatia", code: "385", iso: "HR" },
    { country: "Cuba", code: "53", iso: "CU" },
    { country: "Curacao", code: "599", iso: "CW" },
    { country: "Cyprus", code: "357", iso: "CY" },
    { country: "Czech Republic", code: "420", iso: "CZ" },
    { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
    { country: "Denmark", code: "45", iso: "DK" },
    { country: "Djibouti", code: "253", iso: "DJ" },
    { country: "Dominica", code: "1-767", iso: "DM" },
    { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
    { country: "East Timor", code: "670", iso: "TL" },
    { country: "Ecuador", code: "593", iso: "EC" },
    { country: "Egypt", code: "20", iso: "EG" },
    { country: "El Salvador", code: "503", iso: "SV" },
    { country: "Equatorial Guinea", code: "240", iso: "GQ" },
    { country: "Eritrea", code: "291", iso: "ER" },
    { country: "Estonia", code: "372", iso: "EE" },
    { country: "Ethiopia", code: "251", iso: "ET" },
    { country: "Falkland Islands", code: "500", iso: "FK" },
    { country: "Faroe Islands", code: "298", iso: "FO" },
    { country: "Fiji", code: "679", iso: "FJ" },
    { country: "Finland", code: "358", iso: "FI" },
    { country: "France", code: "33", iso: "FR" },
    { country: "French Polynesia", code: "689", iso: "PF" },
    { country: "Gabon", code: "241", iso: "GA" },
    { country: "Gambia", code: "220", iso: "GM" },
    { country: "Georgia", code: "995", iso: "GE" },
    { country: "Germany", code: "49", iso: "DE" },
    { country: "Ghana", code: "233", iso: "GH" },
    { country: "Gibraltar", code: "350", iso: "GI" },
    { country: "Greece", code: "30", iso: "GR" },
    { country: "Greenland", code: "299", iso: "GL" },
    { country: "Grenada", code: "1-473", iso: "GD" },
    { country: "Guam", code: "1-671", iso: "GU" },
    { country: "Guatemala", code: "502", iso: "GT" },
    { country: "Guernsey", code: "44-1481", iso: "GG" },
    { country: "Guinea", code: "224", iso: "GN" },
    { country: "Guinea-Bissau", code: "245", iso: "GW" },
    { country: "Guyana", code: "592", iso: "GY" },
    { country: "Haiti", code: "509", iso: "HT" },
    { country: "Honduras", code: "504", iso: "HN" },
    { country: "Hong Kong", code: "852", iso: "HK" },
    { country: "Hungary", code: "36", iso: "HU" },
    { country: "Iceland", code: "354", iso: "IS" },
    { country: "India", code: "91", iso: "IN" },
    { country: "Indonesia", code: "62", iso: "ID" },
    { country: "Iran", code: "98", iso: "IR" },
    { country: "Iraq", code: "964", iso: "IQ" },
    { country: "Ireland", code: "353", iso: "IE" },
    { country: "Isle of Man", code: "44-1624", iso: "IM" },
    { country: "Israel", code: "972", iso: "IL" },
    { country: "Italy", code: "39", iso: "IT" },
    { country: "Ivory Coast", code: "225", iso: "CI" },
    { country: "Jamaica", code: "1-876", iso: "JM" },
    { country: "Japan", code: "81", iso: "JP" },
    { country: "Jersey", code: "44-1534", iso: "JE" },
    { country: "Jordan", code: "962", iso: "JO" },
    { country: "Kazakhstan", code: "7", iso: "KZ" },
    { country: "Kenya", code: "254", iso: "KE" },
    { country: "Kiribati", code: "686", iso: "KI" },
    { country: "Kosovo", code: "383", iso: "XK" },
    { country: "Kuwait", code: "965", iso: "KW" },
    { country: "Kyrgyzstan", code: "996", iso: "KG" },
    { country: "Laos", code: "856", iso: "LA" },
    { country: "Latvia", code: "371", iso: "LV" },
    { country: "Lebanon", code: "961", iso: "LB" },
    { country: "Lesotho", code: "266", iso: "LS" },
    { country: "Liberia", code: "231", iso: "LR" },
    { country: "Libya", code: "218", iso: "LY" },
    { country: "Liechtenstein", code: "423", iso: "LI" },
    { country: "Lithuania", code: "370", iso: "LT" },
    { country: "Luxembourg", code: "352", iso: "LU" },
    { country: "Macao", code: "853", iso: "MO" },
    { country: "Macedonia", code: "389", iso: "MK" },
    { country: "Madagascar", code: "261", iso: "MG" },
    { country: "Malawi", code: "265", iso: "MW" },
    { country: "Malaysia", code: "60", iso: "MY" },
    { country: "Maldives", code: "960", iso: "MV" },
    { country: "Mali", code: "223", iso: "ML" },
    { country: "Malta", code: "356", iso: "MT" },
    { country: "Marshall Islands", code: "692", iso: "MH" },
    { country: "Mauritania", code: "222", iso: "MR" },
    { country: "Mauritius", code: "230", iso: "MU" },
    { country: "Mayotte", code: "262", iso: "YT" },
    { country: "Mexico", code: "52", iso: "MX" },
    { country: "Micronesia", code: "691", iso: "FM" },
    { country: "Moldova", code: "373", iso: "MD" },
    { country: "Monaco", code: "377", iso: "MC" },
    { country: "Mongolia", code: "976", iso: "MN" },
    { country: "Montenegro", code: "382", iso: "ME" },
    { country: "Montserrat", code: "1-664", iso: "MS" },
    { country: "Morocco", code: "212", iso: "MA" },
    { country: "Mozambique", code: "258", iso: "MZ" },
    { country: "Myanmar", code: "95", iso: "MM" },
    { country: "Namibia", code: "264", iso: "NA" },
    { country: "Nauru", code: "674", iso: "NR" },
    { country: "Nepal", code: "977", iso: "NP" },
    { country: "Netherlands", code: "31", iso: "NL" },
    { country: "Netherlands Antilles", code: "599", iso: "AN" },
    { country: "New Caledonia", code: "687", iso: "NC" },
    { country: "New Zealand", code: "64", iso: "NZ" },
    { country: "Nicaragua", code: "505", iso: "NI" },
    { country: "Niger", code: "227", iso: "NE" },
    { country: "Nigeria", code: "234", iso: "NG" },
    { country: "Niue", code: "683", iso: "NU" },
    { country: "North Korea", code: "850", iso: "KP" },
    { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
    { country: "Norway", code: "47", iso: "NO" },
    { country: "Oman", code: "968", iso: "OM" },
    { country: "Pakistan", code: "92", iso: "PK" },
    { country: "Palau", code: "680", iso: "PW" },
    { country: "Palestine", code: "970", iso: "PS" },
    { country: "Panama", code: "507", iso: "PA" },
    { country: "Papua New Guinea", code: "675", iso: "PG" },
    { country: "Paraguay", code: "595", iso: "PY" },
    { country: "Peru", code: "51", iso: "PE" },
    { country: "Philippines", code: "63", iso: "PH" },
    { country: "Pitcairn", code: "64", iso: "PN" },
    { country: "Poland", code: "48", iso: "PL" },
    { country: "Portugal", code: "351", iso: "PT" },
    { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
    { country: "Qatar", code: "974", iso: "QA" },
    { country: "Republic of the Congo", code: "242", iso: "CG" },
    { country: "Reunion", code: "262", iso: "RE" },
    { country: "Romania", code: "40", iso: "RO" },
    { country: "Russia", code: "7", iso: "RU" },
    { country: "Rwanda", code: "250", iso: "RW" },
    { country: "Saint Barthelemy", code: "590", iso: "BL" },
    { country: "Saint Helena", code: "290", iso: "SH" },
    { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
    { country: "Saint Lucia", code: "1-758", iso: "LC" },
    { country: "Saint Martin", code: "590", iso: "MF" },
    { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
    { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
    { country: "Samoa", code: "685", iso: "WS" },
    { country: "San Marino", code: "378", iso: "SM" },
    { country: "Sao Tome and Principe", code: "239", iso: "ST" },
    { country: "Saudi Arabia", code: "966", iso: "SA" },
    { country: "Senegal", code: "221", iso: "SN" },
    { country: "Serbia", code: "381", iso: "RS" },
    { country: "Seychelles", code: "248", iso: "SC" },
    { country: "Sierra Leone", code: "232", iso: "SL" },
    { country: "Singapore", code: "65", iso: "SG" },
    { country: "Sint Maarten", code: "1-721", iso: "SX" },
    { country: "Slovakia", code: "421", iso: "SK" },
    { country: "Slovenia", code: "386", iso: "SI" },
    { country: "Solomon Islands", code: "677", iso: "SB" },
    { country: "Somalia", code: "252", iso: "SO" },
    { country: "South Africa", code: "27", iso: "ZA" },
    { country: "South Korea", code: "82", iso: "KR" },
    { country: "South Sudan", code: "211", iso: "SS" },
    { country: "Spain", code: "34", iso: "ES" },
    { country: "Sri Lanka", code: "94", iso: "LK" },
    { country: "Sudan", code: "249", iso: "SD" },
    { country: "Suriname", code: "597", iso: "SR" },
    { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
    { country: "Swaziland", code: "268", iso: "SZ" },
    { country: "Sweden", code: "46", iso: "SE" },
    { country: "Switzerland", code: "41", iso: "CH" },
    { country: "Syria", code: "963", iso: "SY" },
    { country: "Taiwan", code: "886", iso: "TW" },
    { country: "Tajikistan", code: "992", iso: "TJ" },
    { country: "Tanzania", code: "255", iso: "TZ" },
    { country: "Thailand", code: "66", iso: "TH" },
    { country: "Togo", code: "228", iso: "TG" },
    { country: "Tokelau", code: "690", iso: "TK" },
    { country: "Tonga", code: "676", iso: "TO" },
    { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
    { country: "Tunisia", code: "216", iso: "TN" },
    { country: "Turkey", code: "90", iso: "TR" },
    { country: "Turkmenistan", code: "993", iso: "TM" },
    { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
    { country: "Tuvalu", code: "688", iso: "TV" },
    { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
    { country: "Uganda", code: "256", iso: "UG" },
    { country: "Ukraine", code: "380", iso: "UA" },
    { country: "United Arab Emirates", code: "971", iso: "AE" },
    { country: "United Kingdom", code: "44", iso: "GB" },
    { country: "United States", code: "1", iso: "US" },
    { country: "Uruguay", code: "598", iso: "UY" },
    { country: "Uzbekistan", code: "998", iso: "UZ" },
    { country: "Vanuatu", code: "678", iso: "VU" },
    { country: "Vatican", code: "379", iso: "VA" },
    { country: "Venezuela", code: "58", iso: "VE" },
    { country: "Vietnam", code: "84", iso: "VN" },
    { country: "Wallis and Futuna", code: "681", iso: "WF" },
    { country: "Western Sahara", code: "212", iso: "EH" },
    { country: "Yemen", code: "967", iso: "YE" },
    { country: "Zambia", code: "260", iso: "ZM" },
    { country: "Zimbabwe", code: "263", iso: "ZW" },
  ];
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setSelectedAreaCode(
      areaCodes.filter((x) => x.code === slcRes.customer.areaCode)[0]
    );
    setSelectedMeal(
      slcRest.mealTimes.filter((x) => x.id === slcRes.mealTimeId)[0]
    );
  }, []);

  useEffect(() => {
    if (selectedMeal?.id !== undefined) {
      const timeSlots = [];

      let currentTime = new Date(`1970-01-01T${selectedMeal.startTime}`);
      const end = new Date(`1970-01-01T${selectedMeal.endTime}`);

      while (currentTime <= end) {
        const hours = currentTime.getHours().toString().padStart(2, "0");
        const minutes = currentTime.getMinutes().toString().padStart(2, "0");
        timeSlots.push({
          label: `${hours}:${minutes}`,
          value: `${hours}:${minutes}`,
        });

        currentTime.setMinutes(
          currentTime.getMinutes() + restaurant.reservationRange
        );
      }
      setActiveHours(timeSlots);
    }
  }, [selectedMeal?.id]);

  function PutReservation() {
    setLoading(true);
    axios
      .put("clientAIO/reservation/details", {
        id: slcRes.id,
        date: moment(selectedDate).format("yy-MM-DD"),
        time: selectedReservation.time,
        mealTimeId: selectedMeal.id,
        guestCount: selectedReservation.guestCount,
        notes: selectedReservation.notes,
        customerNote: selectedReservation.customerNote,
        status: selectedReservation.status,
        customer: {
          name: selectedReservation.customer.name,
          areaCode: selectedAreaCode.code,
          phone: selectedReservation.customer.phone,
          email: selectedReservation.customer.email,
        },
      })
      .then((x) => {
        setLoading(false);

        CloseUpdateDlg(1);
      });
  }

  function GetCustomerDetails() {
    setLoading(true);
    axios
      .get(`clientAIO/reservations/customer/${selectedReservation.customerId}`)
      .then((x) => {
        setLoading(false);
        setCustomerReservations(x.data);
        setCustomerReservationsDlg(true);
      });
  }
  const isValidSelection = activeHours.some(
    (option) => option.value + ":00" === selectedReservation.time
  );
  function StatusTemp(status) {
    let bgColor = "";
    let color = "";
    let txt = "";

    switch (status) {
      case 0:
        bgColor = "white";
        color = "black";
        txt = t("konfirmeDegil");
        break;
      case 1:
        bgColor = "#fed7b2";
        color = "#faac61";
        txt = t("gelmedi");
        break;
      case 2:
        bgColor = "#a0bfbf";
        color = "#3e9393";
        txt = t("konfirme");
        break;
      case 3:
        bgColor = "#b2dab7";
        color = "#39b54a";
        txt = t("geldi");
        break;
      case 4:
        bgColor = "#f8bebe";
        color = "#e34c4c";
        txt = t("iptal");
        break;
      case 5:
        bgColor = "#a6c0a9";
        color = "#388643";
        txt = t("tamamlandi");
        break;
      case 6:
        bgColor = "#de9ee6";
        color = "#d46ee0";
        txt = t("bekliyor");
        break;
      case 7:
        bgColor = "white";
        color = "black";
        txt = t("iadeEdildi");
        break;
      case 8:
        bgColor = "pink";
        color = "darkred";
        txt = t("kiptali");
        break;

      default:
        break;
    }

    return (
      <div className="flex align-items-center justify-content-center">
        <div
          className="px-3 py-1 text-center"
          style={{
            backgroundColor: bgColor,
            color: color,
            textTransform: "uppercase",
            fontWeight: "800",
            borderRadius: 5,
            width: "fit-content",
            fontSize: 14,
          }}
        >
          {txt}
        </div>
      </div>
    );
  }
  const ChangeStatusConf = (status) => {
    const accept = (status) => {
      setLoadingFast(true);
      setSelectedReservation({
        ...selectedReservation,
        status: status,
      });
    };

    const reject = () => {};

    confirmDialog({
      group: "templating",
      header: t("onaylama"),
      acceptLabel: t("evet"),
      rejectLabel: t("hayir"),
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 font-semibold">
          <div>{t("durumGunOnay")}</div>
          <div style={{ textTransform: "capitalize" }}>
            {selectedReservation.customer.name}
          </div>
          <div className="flex gap-1">
            {StatusTemp(selectedReservation.status)}
            {">>>"}
            {StatusTemp(status)}
          </div>
        </div>
      ),
      accept: () => accept(status),
      reject,
    });
  };
  return (
    <>
      <Dialog
        className="col-12 lg:col-10 xl:col-8 p-0 m-0"
        visible={true}
        header={t("rezGuncelle") + " (" + slcRes.reservationToken + ")"}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onHide={() => {
          CloseUpdateDlg();
        }}
      >
        <div
          className="grid p-0 col-12"
          style={{
            overflow: "auto",
            maxHeight: "75vh",
          }}
        >
          <div className="col-12 font-semibold">
            {t("olusturulma")}:{" "}
            {moment(slcRes.createdDate).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faUser} />
              </span>
              <FloatLabel>
                <InputText
                  id="name"
                  value={selectedReservation.customer.name}
                  onChange={(e) => {
                    const nameValue = e.target.value;
                    if (nameValue?.length <= 50) {
                      setSelectedReservation((prev) => ({
                        ...prev,
                        customer: { ...prev.customer, name: nameValue },
                      }));
                    }
                  }}
                />
                <label htmlFor="name">{t("musAdSoyad")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faPhone} />
              </span>
              <FloatLabel>
                <Dropdown
                  id="areaCode"
                  filter
                  value={selectedAreaCode}
                  style={{ borderRadius: 3 }}
                  onChange={(e) => setSelectedAreaCode(e.value)}
                  valueTemplate={(x) => x.country + " +" + x.code}
                  itemTemplate={(x) => x.country + " +" + x.code}
                  options={areaCodes}
                  optionLabel="code"
                  placeholder="Select a Areacode"
                  scrollHeight="400px"
                />
                <label for="areaCode">{t("alanKodu")}</label>
              </FloatLabel>
            </div>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="phone"
                  value={selectedReservation.customer.phone}
                  onChange={(e) => {
                    const phoneValue = e.target.value;
                    const sanitizedPhone = phoneValue.replace(/\D/g, "");
                    if (sanitizedPhone?.length <= 12) {
                      setSelectedReservation((prev) => ({
                        ...prev,
                        customer: { ...prev.customer, phone: sanitizedPhone },
                      }));
                    }
                  }}
                />
                <label for="phone">{t("telefon")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <FloatLabel>
                <InputText
                  id="mail"
                  value={selectedReservation.customer.email}
                  onChange={(e) => {
                    const emailValue = e.target.value;

                    // E-posta adresi 75 karakterden uzun olmamalı
                    if (emailValue?.length <= 75) {
                      setSelectedReservation((prev) => ({
                        ...prev,
                        customer: { ...prev.customer, email: emailValue },
                      }));
                    }
                  }}
                />
                <label for="mail">{t("mail")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12 m-0 p-0 px-2">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                {t("ogun")}
              </span>
              <div className="flex w-full align-items-center justify-content-between">
                <div className="flex align-items-center h-full">
                  {restaurant.mealTimes?.map((x) => (
                    <div
                      className="flex align-items-center px-3 select-none cursor-pointer"
                      style={{
                        border: "1px solid grey",
                        height: "100%",
                        background:
                          selectedMeal?.id === x.id ? "#F39237" : "#EDE7D9",
                        fontWeight: 700,
                      }}
                      onClick={() => setSelectedMeal(x)}
                    >
                      {i18n.language === "en" ? x.nameEn : x.name}
                    </div>
                  ))}
                </div>
                <div className="font-semibold text-sm">
                  (
                  {selectedMeal?.startTime?.slice(0, 5) +
                    "-" +
                    selectedMeal?.endTime?.slice(0, 5)}
                  )
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              <Calendar
                placeholder={t("tarih")}
                style={{ textAlignLast: "center" }}
                value={selectedDate}
                locale={i18n.language}
                dateFormat="dd.mm.yy"
                readOnlyInput
                showButtonBar
                onChange={(e) => setSelectedDate(e.value)}
              />
            </div>
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faClock} />
              </span>
              <Dropdown
                placeholder={t("saat")}
                style={{
                  border: isValidSelection ? "1px solid #ccc" : "1px solid red",
                }}
                value={selectedReservation.time.slice(0, 5)}
                options={activeHours}
                onChange={(e) =>
                  setSelectedReservation((prev) => ({
                    ...prev,
                    time: e.value + ":00",
                  }))
                }
                scrollHeight="370px"
              />
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faUsers} />
              </span>
              <Dropdown
                placeholder={t("kisiSayisi")}
                options={personCountOptions}
                value={selectedReservation.guestCount}
                onChange={(e) =>
                  setSelectedReservation({
                    ...selectedReservation,
                    guestCount: e.value,
                  })
                }
                scrollHeight="300px"
              />
            </div>
          </div>

          <div className="col-12 py-1 px-2">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
              <FloatLabel>
                <InputTextarea
                  rows={2} // 2 satır başlangıçta gösterilecek
                  autoResize={true} // Kullanıcı yazdıkça boyutu artacak
                  id="customerNote"
                  value={selectedReservation.customerNote}
                  onChange={(e) => {
                    const noteValue = e.target.value;

                    // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                    if (noteValue?.length <= 250) {
                      setSelectedReservation({
                        ...selectedReservation,
                        customerNote: noteValue,
                      });
                    }
                  }}
                />
                <label for="customerNote">{t("musteriNotu")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12 py-2 px-2">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
              <FloatLabel>
                <InputTextarea
                  rows={2} // 2 satır başlangıçta gösterilecek
                  autoResize={true} // Kullanıcı yazdıkça boyutu artacak
                  id="reservationNote"
                  value={selectedReservation.notes}
                  onChange={(e) => {
                    const noteValue = e.target.value;

                    // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                    if (noteValue?.length <= 250) {
                      setSelectedReservation((prev) => ({
                        ...prev,
                        notes: noteValue,
                      }));
                    }
                  }}
                />
                <label for="reservationNote">{t("restoranNotu")}</label>
              </FloatLabel>
            </div>
          </div>

          {selectedReservation.provisionAmount > 0 && (
            <div className="col-12 flex flex-column font-semibold p-0 px-2">
              <div>
                {t("provTutar")}: {selectedReservation.provisionAmount}₺
              </div>
              <div>
                {t("durum")}:{" "}
                {selectedReservation.isPrePost &&
                selectedReservation.provisionAmount ===
                  selectedReservation.provisionBalance
                  ? t("satisaCevrildi")
                  : selectedReservation.provisionBalance === 0
                  ? t("iadeEdildi")
                  : t("provizyonda")}
              </div>
              <div>Payment ID: {selectedReservation.id}</div>
              {/*              <div>
                {t("son4hane")}: {selectedReservation.lastFourDigits}
              </div>*/}
            </div>
          )}

          <div className="col-12 m-0 flex justify-content-between align-items-center">
            <div className="flex flex-wrap gap-1">
              <Button
                label={t("gelmedi")}
                onClick={() => {
                  ChangeStatusConf(1);
                }}
                style={{
                  backgroundColor: "#faac61",
                  borderBottom:
                    selectedReservation.status === 1 &&
                    "5px solid midnightblue",
                }}
              />
              <Button
                label={t("konfirme")}
                onClick={() => {
                  ChangeStatusConf(2);
                }}
                style={{
                  backgroundColor: "#3e9393",
                  borderBottom:
                    selectedReservation.status === 2 &&
                    "5px solid midnightblue",
                }}
              />
              <Button
                label={t("geldi")}
                onClick={() => {
                  ChangeStatusConf(3);
                }}
                style={{
                  backgroundColor: "#39b54a",
                  borderBottom:
                    selectedReservation.status === 3 &&
                    "5px solid midnightblue",
                }}
              />
              <Button
                label={t("iptal")}
                onClick={() => {
                  ChangeStatusConf(4);
                }}
                style={{
                  backgroundColor: "#e34c4c",
                  borderBottom:
                    selectedReservation.status === 4 &&
                    "5px solid midnightblue",
                }}
              />
              <Button
                label={t("tamamlandi")}
                onClick={() => {
                  ChangeStatusConf(5);
                }}
                style={{
                  backgroundColor: "#388643",
                  borderBottom:
                    selectedReservation.status === 5 &&
                    "5px solid midnightblue",
                }}
              />

              <Button
                label={t("bekliyor")}
                onClick={() => {
                  ChangeStatusConf(6);
                }}
                style={{
                  backgroundColor: "#de9ee6",

                  borderBottom:
                    selectedReservation.status === 6 &&
                    "5px solid midnightblue",
                }}
              />
              <Button
                label={t("iadeEt")}
                disabled={
                  selectedReservation.isPrePost &&
                  selectedReservation.provisionAmount ===
                    selectedReservation.provisionBalance
                    ? false
                    : true
                }
                onClick={() => {
                  setSelectedReservation({
                    ...selectedReservation,
                    status: 7,
                  });
                }}
                style={{
                  backgroundColor: "#a42a2a",
                  borderBottom:
                    selectedReservation.status === 7 &&
                    "5px solid midnightblue",
                }}
              />
            </div>
            <div className="flex">
              <Button
                loading={loading}
                label={t("bilgi")}
                severity="warning"
                outlined
                style={{ marginRight: "20px" }}
                onClick={() => GetCustomerDetails()}
              />
              <Button
                loading={loading}
                label={t("kaydet")}
                severity="success"
                disabled={
                  isValidSelection &
                  (selectedReservation.customer.name.length > 3) &
                  (selectedReservation.customer.phone.length > 6)
                    ? ""
                    : true
                }
                icon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "10px" }}
                  />
                }
                outlined
                onClick={() => PutReservation()}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        className="col-12 lg:col-10 xl:col-10 p-0 m-0"
        visible={customerReservationsDlg}
        header={t("musteriDetaylari")}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ededed",
        }}
        headerStyle={{ backgroundColor: "#ededed" }}
        onHide={() => {
          setCustomerReservationsDlg(false);
        }}
      >
        {windowWidth > 500 && (
          <div
            className="flex w-full"
            style={{
              justifyContent: "space-between",
              overflow: "auto",
              maxHeight: "70vh",
            }}
          >
            <div className="flex flex-column" style={{ height: "100%" }}>
              <div
                style={{ fontSize: "40px", fontWeight: 600, color: "orange" }}
              >
                {selectedReservation.customer.name}
              </div>
              <div style={{ fontSize: "20px", fontWeight: 500 }}>
                {selectedReservation.customer.email}
              </div>

              <div style={{ fontSize: "20px", fontWeight: 400 }}>
                {selectedReservation.customer.areaCode}{" "}
                {selectedReservation.customer.phone}{" "}
              </div>
            </div>
            <DataTable
              value={customerReservations}
              rows={10}
              dataKey="id"
              style={{ width: "75%" }}
              loading={loading}
              emptyMessage="Musteri verisi bulunamadı."
            >
              <Column
                field="date"
                style={{ textAlign: "center" }}
                alignHeader={"center"}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                  borderTopLeftRadius: 25,
                  borderBottomLeftRadius: 5,
                }}
                body={(rowData) => {
                  const formattedDate = moment(rowData.date).format(
                    "DD.MM.YYYY"
                  );
                  const formattedTime = moment(rowData.time, "HH:mm").format(
                    "HH:mm"
                  );
                  const formattedCDate = moment(rowData.createdDate).format(
                    "DD.MM.YYYY - HH:mm"
                  );
                  return (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ fontSize: "16px", fontWeight: 800 }}>
                        {formattedDate} - {formattedTime}
                      </div>
                      <div style={{ fontSize: "17px", fontWeight: 500 }}>
                        {" "}
                        {t("olusturulma")}: {formattedCDate}
                      </div>
                    </div>
                  );
                }}
                header={t("rezTarihi")}
              />
              <Column
                field="guestCount"
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: 800,
                  color: "orange",
                }}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                }}
                alignHeader={"center"}
                header={t("kisi")}
              />
              <Column
                field="customerNote"
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: 500,
                }}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                }}
                alignHeader={"center"}
                header={t("musteriNotu")}
              />
              <Column
                field="notes"
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: 500,
                }}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                }}
                alignHeader={"center"}
                header={t("restoranNotu")}
              />
            </DataTable>
          </div>
        )}
        {windowWidth <= 500 && (
          <div
            style={{
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="flex flex-column"
              style={{
                height: "100%",
                alignItems: "center",
                paddingBottom: "30px",
              }}
            >
              <div
                style={{ fontSize: "40px", fontWeight: 600, color: "orange" }}
              >
                {selectedReservation.customer.name}
              </div>
              <div style={{ fontSize: "20px", fontWeight: 500 }}>
                {selectedReservation.customer.email}
              </div>

              <div style={{ fontSize: "20px", fontWeight: 400 }}>
                {selectedReservation.customer.areaCode}{" "}
                {selectedReservation.customer.phone}{" "}
              </div>
            </div>
            <DataTable
              value={customerReservations}
              rows={10}
              dataKey="id"
              paginator
              style={{ width: "100%" }}
              stripedRows
              loading={loading}
              emptyMessage={t("musteriBulunamadi")}
            >
              <Column
                field="date"
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 600,
                }}
                alignHeader={"center"}
                body={(rowData) => {
                  const formattedDate = moment(rowData.date).format(
                    "DD.MM.YYYY"
                  );
                  const formattedTime = moment(rowData.time, "HH:mm").format(
                    "HH:mm"
                  );
                  const formattedCDate = moment(rowData.createdDate).format(
                    "DD.MM.YYYY - HH:mm"
                  );
                  return (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ fontSize: "16px", fontWeight: 800 }}>
                        {formattedDate} - {formattedTime}
                      </div>
                      <div style={{ fontSize: "17px", fontWeight: 500 }}>
                        {" "}
                        {t("olusturulma")} {formattedCDate}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          fontWeight: 800,
                          color: "orange",
                        }}
                      >
                        {rowData.guestCount + " "}
                        {t("kisi")}
                      </div>
                      <div>{rowData.customerNote}</div>
                      <div>{rowData.notes}</div>
                    </div>
                  );
                }}
              />
            </DataTable>
          </div>
        )}
      </Dialog>
    </>
  );
}

export default UpdateReservationDlg;
