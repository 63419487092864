import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { addLocale } from "primereact/api";
import { Button } from "primereact/button";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode } from "primereact/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmDialog } from "primereact/confirmdialog";
import UpdateReservationDlg from "../components/UpdateReservationDlg";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Reservations() {
  const { t, i18n } = useTranslation();
  const toast = useRef(null);
  const [updateDlg, setUpdateDlg] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [editReservDlg, setEditReservDlg] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [reservationsData, setReservationsData] = useState([]);
  const [filteredReservations, setFilteredReservations] = useState([]);
  const { ddSelectedRestaurant } = useSelector((state) => state.counter);
  const [customerDlg, setCustomerDlg] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [activeHours, setActiveHours] = useState([]);
  const [restaurant, setRestaurant] = useState({});
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });
  useEffect(() => {
    if (ddSelectedRestaurant?.id !== undefined) {
      axios.get(`clientAIO/menulist/${ddSelectedRestaurant?.id}`).then((x) => {
        setRestaurant(x.data);
        setSelectedMeal(x.data.mealTimes[0]);
        GetReservations();
      });
    }
  }, [ddSelectedRestaurant?.id]);

  useEffect(() => {
    if (selectedMeal?.id !== undefined) {
      const timeSlots = [];

      let currentTime = new Date(`1970-01-01T${selectedMeal.startTime}`);
      const end = new Date(`1970-01-01T${selectedMeal.endTime}`);

      while (currentTime <= end) {
        const hours = currentTime.getHours().toString().padStart(2, "0");
        const minutes = currentTime.getMinutes().toString().padStart(2, "0");
        timeSlots.push({
          label: `${hours}:${minutes}`,
          value: `${hours}:${minutes}`,
        });

        currentTime.setMinutes(
          currentTime.getMinutes() + restaurant.reservationRange
        );
      }
      setActiveHours(timeSlots);
    }
  }, [selectedMeal?.id]);

  useEffect(() => {
    let filtered = reservationsData;

    const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
    const end = endDate ? new Date(endDate).setHours(0, 0, 0, 0) : null;

    if (start && end) {
      filtered = reservationsData.filter((reservation) => {
        const reservationDate = new Date(reservation.date).setHours(0, 0, 0, 0);
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

        return reservationDate >= start && reservationDate <= end;
      });
    } else if (start) {
      filtered = reservationsData.filter((reservation) => {
        const reservationDate = new Date(reservation.date).setHours(0, 0, 0, 0);
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

        return reservationDate >= start;
      });
    } else if (end) {
      filtered = reservationsData.filter((reservation) => {
        const reservationDate = new Date(reservation.date).setHours(0, 0, 0, 0);
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

        return reservationDate <= end;
      });
    }

    // Listeyi reservation.date'e göre en büyük tarihten en küçük tarihe sıralama

    setFilteredReservations(filtered);
  }, [startDate, endDate, reservationsData]);

  function GetReservations() {
    axios.get(`clientAIO/reservations/${ddSelectedRestaurant.id}`).then((x) => {
      setReservationsData(x.data);
      setFilteredReservations(x.data);
      setLoading(false);
    });
  }

  function StatusTemp(status) {
    let bgColor = "";
    let color = "";
    let txt = "";

    switch (status) {
      case 0:
        bgColor = "white";
        color = "black";
        txt = t("konfirmeDegil");
        break;
      case 1:
        bgColor = "#fed7b2";
        color = "#faac61";
        txt = t("gelmedi");
        break;
      case 2:
        bgColor = "#a0bfbf";
        color = "#3e9393";
        txt = t("konfirme");
        break;
      case 3:
        bgColor = "#b2dab7";
        color = "#39b54a";
        txt = t("geldi");
        break;
      case 4:
        bgColor = "#f8bebe";
        color = "#e34c4c";
        txt = t("iptal");
        break;
      case 5:
        bgColor = "#a6c0a9";
        color = "#388643";
        txt = t("tamamlandi");
        break;
      case 6:
        bgColor = "#de9ee6";
        color = "#d46ee0";
        txt = t("bekliyor");
        break;
      case 7:
        bgColor = "white";
        color = "black";
        txt = t("iadeEdildi");
        break;
      case 8:
        bgColor = "pink";
        color = "darkred";
        txt = t("kiptali");
        break;

      default:
        break;
    }

    return (
      <div className="flex align-items-center justify-content-center">
        <div
          className="px-3 py-1 text-center"
          style={{
            backgroundColor: bgColor,
            color: color,
            textTransform: "uppercase",
            fontWeight: "800",
            borderRadius: 5,
            width: "fit-content",
            fontSize: 14,
          }}
        >
          {txt}
        </div>
      </div>
    );
  }
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const CloseUpdateDlg = (x) => {
    if (x > 0) {
      toast.current.show({
        severity: "success",
        summary: t("basarili"),
        detail: t("rezGunBasarili"),
        life: 3000,
      });
    }
    GetReservations();
    setUpdateDlg(false);
  };
  const renderHeader = () => {
    return (
      <div
        style={{
          justifyContent: windowWidth > 500 ? "space-between" : "center",
        }}
        className="flex flex-wrap gap-2"
      >
        <div className="flex" style={{ textAlignLast: "center" }}>
          <Calendar
            placeholder={t("baslangicTarihi")}
            showButtonBar
            locale={i18n.language}
            readOnlyInput
            dateFormat="dd.mm.yy"
            variant="filled"
            value={startDate}
            style={{ paddingRight: "10px" }}
            onChange={(e) => setStartDate(e.value)}
          ></Calendar>
          <Calendar
            placeholder={t("bitisTarihi")}
            style={{ paddingRight: "10px" }}
            readOnlyInput
            locale={i18n.language}
            variant="filled"
            dateFormat="dd.mm.yy"
            value={endDate}
            showButtonBar
            onChange={(e) => setEndDate(e.value)}
          ></Calendar>
          <Button
            disabled={startDate || endDate !== null ? false : true}
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </div>

        <InputText
          value={globalFilterValue}
          style={{ width: "300px" }}
          onChange={onGlobalFilterChange}
          placeholder={t("ara")}
        />
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      <Toast ref={toast} />
      {updateDlg && (
        <UpdateReservationDlg
          slcRest={restaurant}
          slcRes={selectedReservation}
          CloseUpdateDlg={CloseUpdateDlg}
        />
      )}
      <ConfirmDialog group="templating" />
      <div>
        {windowWidth > 500 && (
          <DataTable
            value={filteredReservations}
            paginator
            rows={30}
            dataKey="id"
            filters={filters}
            loading={loading}
            onRowClick={(x) => {
              setSelectedReservation(x.data);
              setUpdateDlg(true);
            }}
            header={header}
            emptyMessage={t("rezBulunamadi")}
          >
            <Column
              field="date"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              alignHeader={"center"}
              body={(x) => (
                <div className="text-center">
                  <div className="font-semibold">
                    {moment(x.date).format("DD.MM.YYYY")} - {x.time.slice(0, 5)}
                  </div>
                  <div className="mt-2 text-sm font-semibold">
                    {i18n.language === "en" ? x.mealTimeNameEn : x.mealTimeName}
                  </div>
                  <div className="text-sm font-semibold">
                    {t("olusturulma")}:{" "}
                    {moment(x.createdDate).format("DD.MM.YYYY -  HH:mm")}
                  </div>
                </div>
              )}
              sortable
              header={t("rezTarihi")}
            />
            <Column
              field="status"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              body={(a) => StatusTemp(a.status)}
              alignHeader={"center"}
              header={t("durum")}
              sortable
            />
            <Column
              field="customer.name"
              body={(x) => (
                <div className="text-center">
                  <div
                    className="font-bold"
                    style={{ textTransform: "capitalize" }}
                  >
                    {x.customer.name}
                  </div>
                  {x.type === 2 && (
                    <div className="font-semibold text-sm">({t("manuel")})</div>
                  )}
                  {x.type === 3 && (
                    <div className="font-semibold text-sm">
                      ({t("cevrimici")})
                    </div>
                  )}
                </div>
              )}
              header={t("musteri")}
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              alignHeader={"center"}
              sortable
            />
            <Column
              field="reservationToken"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              className="font-semibold"
              alignHeader={"center"}
              align={"center"}
              header={t("rezKodu")}
              sortable
            />
            <Column
              field="guestCount"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              className="font-bold"
              alignHeader={"center"}
              align={"center"}
              header={t("kisi")}
              sortable
            />
            <Column
              field="customerNote"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              style={{ maxWidth: "200px", wordWrap: "break-word" }}
              className="font-semibold text-sm"
              alignHeader={"center"}
              align={"center"}
              header={t("musteriNotu")}
              sortable
            />
            <Column
              field="notes"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              style={{ maxWidth: "200px", wordWrap: "break-word" }}
              className="font-semibold text-sm"
              alignHeader={"center"}
              align={"center"}
              header={t("restoranNotu")}
              sortable
            />
          </DataTable>
        )}
        {windowWidth <= 500 && (
          <DataTable
            value={filteredReservations}
            paginator
            rows={30}
            dataKey="id"
            filters={filters}
            loading={loading}
            onRowClick={(x) => {
              setSelectedReservation(x.data);
              setUpdateDlg(true);
            }}
            header={header}
            emptyMessage={t("rezBulunamadi")}
            stripedRows
            globalFilterFields={[
              "customer.name",
              "customer.phone",
              "time",
              "reservationToken",
              "notes",
              "customerNote",
            ]}
          >
            <Column
              field="time"
              headerStyle={{
                backgroundColor: "#222d33",
                color: "white",
                fontWeight: 600,
              }}
              style={{ wordWrap: "break-word", maxWidth: "100px" }}
              alignHeader={"center"}
              body={(x) => (
                <div className="text-center">
                  <div className="font-semibold">
                    {moment(x.date).format("DD.MM.YYYY")}
                  </div>
                  <div className="font-semibold">{x.time.slice(0, 5)}</div>
                  <div className="mt-3 text-sm font-semibold">
                    {i18n.language === "en" ? x.mealTimeNameEn : x.mealTimeName}
                  </div>
                  <div className="text-sm font-semibold mb-2">
                    {t("olusturulma")}:{" "}
                    {moment(x.createdDate).format("DD.MM.YYYY HH:mm")}
                  </div>
                  {StatusTemp(x.status)}
                  <div
                    className="font-bold mt-2"
                    style={{ textTransform: "capitalize" }}
                  >
                    {x.customer.name}
                  </div>
                  {x.type === 2 && (
                    <div className="font-semibold text-sm">({t("manuel")})</div>
                  )}
                  {x.type === 3 && (
                    <div className="font-semibold text-sm">
                      ({t("cevrimici")})
                    </div>
                  )}
                  {x.language === "en" ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/en.png`}
                      width={20}
                      alt="tr"
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/tr.png`}
                      width={20}
                      alt="tr"
                    />
                  )}
                  <div className="font-semibold text-md mt-2">
                    {x.reservationToken}
                  </div>
                  <div className="font-semibold text-md mt-2">
                    {x.guestCount} {t("kisi")}
                  </div>
                  <div className="mt-3 text-sm font-semibold">
                    {x.customerNote}
                  </div>
                  <div className="mt-3 text-sm font-semibold">{x.notes}</div>
                </div>
              )}
            />
          </DataTable>
        )}
      </div>
    </>
  );
}

export default Reservations;
