import { faCalendarCheck, faClock } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { addLocale } from "primereact/api";
import axios from "axios";
import moment from "moment";
import { ProgressBar } from "primereact/progressbar";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { Fieldset } from "primereact/fieldset";
import CryptoJS from "crypto-js";
import { Toast } from "primereact/toast";
import { InputMask } from "primereact/inputmask";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { InputOtp } from "primereact/inputotp";
import { Checkbox } from "primereact/checkbox";
import { v4 as uuidv4 } from "uuid";
import { HubConnectionBuilder } from "@microsoft/signalr";

function IFrameReservation() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t, i18n } = useTranslation();
  const toast = useRef(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [restaurantData, setRestaurantData] = useState({});
  const [reservationData, setReservationData] = useState({
    customerNote: "",
    name: "",
    email: "",
    phone: "",
  });
  const [selectedAreaCode, setSelectedAreaCode] = useState({
    country: "Turkey",
    code: "90",
    iso: "TR",
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [cardData, setCardData] = useState({ name: "", number: "", cvc: "" });

  const [selectedTags, setSelectedTags] = useState([]);

  const [otpDlg, setOtpDlg] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [wrongOtp, setWrongOtp] = useState(false);

  //const [kvkkAgreement, setKvkkAgreement] = useState(false);
  const [iletiAgreement, setIletiAgreement] = useState(false);
  const [aydinlatmaAgreement, setAydinlatmaAgreement] = useState(false);
  const [cookieAgreement, setCookieAgreement] = useState(false);
  const [agreementDlg, setAgreementDlg] = useState(false);
  const [selectedAgreement, setSelectedAgreement] = useState(0);
  const [threeDsDlg, setThreeDsDlg] = useState(false);
  const [threeDsContent, setThreeDsContent] = useState(null);
  const [paytrData, setPaytrData] = useState(null);

  const areaCodes = [
    { country: "Afghanistan", code: "93", iso: "AF" },
    { country: "Albania", code: "355", iso: "AL" },
    { country: "Algeria", code: "213", iso: "DZ" },
    { country: "American Samoa", code: "1-684", iso: "AS" },
    { country: "Andorra", code: "376", iso: "AD" },
    { country: "Angola", code: "244", iso: "AO" },
    { country: "Anguilla", code: "1-264", iso: "AI" },
    { country: "Antarctica", code: "672", iso: "AQ" },
    { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
    { country: "Argentina", code: "54", iso: "AR" },
    { country: "Armenia", code: "374", iso: "AM" },
    { country: "Aruba", code: "297", iso: "AW" },
    { country: "Australia", code: "61", iso: "AU" },
    { country: "Austria", code: "43", iso: "AT" },
    { country: "Azerbaijan", code: "994", iso: "AZ" },
    { country: "Bahamas", code: "1-242", iso: "BS" },
    { country: "Bahrain", code: "973", iso: "BH" },
    { country: "Bangladesh", code: "880", iso: "BD" },
    { country: "Barbados", code: "1-246", iso: "BB" },
    { country: "Belarus", code: "375", iso: "BY" },
    { country: "Belgium", code: "32", iso: "BE" },
    { country: "Belize", code: "501", iso: "BZ" },
    { country: "Benin", code: "229", iso: "BJ" },
    { country: "Bermuda", code: "1-441", iso: "BM" },
    { country: "Bhutan", code: "975", iso: "BT" },
    { country: "Bolivia", code: "591", iso: "BO" },
    { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
    { country: "Botswana", code: "267", iso: "BW" },
    { country: "Brazil", code: "55", iso: "BR" },
    { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
    { country: "British Virgin Islands", code: "1-284", iso: "VG" },
    { country: "Brunei", code: "673", iso: "BN" },
    { country: "Bulgaria", code: "359", iso: "BG" },
    { country: "Burkina Faso", code: "226", iso: "BF" },
    { country: "Burundi", code: "257", iso: "BI" },
    { country: "Cambodia", code: "855", iso: "KH" },
    { country: "Cameroon", code: "237", iso: "CM" },
    { country: "Canada", code: "1", iso: "CA" },
    { country: "Cape Verde", code: "238", iso: "CV" },
    { country: "Cayman Islands", code: "1-345", iso: "KY" },
    { country: "Central African Republic", code: "236", iso: "CF" },
    { country: "Chad", code: "235", iso: "TD" },
    { country: "Chile", code: "56", iso: "CL" },
    { country: "China", code: "86", iso: "CN" },
    { country: "Christmas Island", code: "61", iso: "CX" },
    { country: "Cocos Islands", code: "61", iso: "CC" },
    { country: "Colombia", code: "57", iso: "CO" },
    { country: "Comoros", code: "269", iso: "KM" },
    { country: "Cook Islands", code: "682", iso: "CK" },
    { country: "Costa Rica", code: "506", iso: "CR" },
    { country: "Croatia", code: "385", iso: "HR" },
    { country: "Cuba", code: "53", iso: "CU" },
    { country: "Curacao", code: "599", iso: "CW" },
    { country: "Cyprus", code: "357", iso: "CY" },
    { country: "Czech Republic", code: "420", iso: "CZ" },
    { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
    { country: "Denmark", code: "45", iso: "DK" },
    { country: "Djibouti", code: "253", iso: "DJ" },
    { country: "Dominica", code: "1-767", iso: "DM" },
    { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
    { country: "East Timor", code: "670", iso: "TL" },
    { country: "Ecuador", code: "593", iso: "EC" },
    { country: "Egypt", code: "20", iso: "EG" },
    { country: "El Salvador", code: "503", iso: "SV" },
    { country: "Equatorial Guinea", code: "240", iso: "GQ" },
    { country: "Eritrea", code: "291", iso: "ER" },
    { country: "Estonia", code: "372", iso: "EE" },
    { country: "Ethiopia", code: "251", iso: "ET" },
    { country: "Falkland Islands", code: "500", iso: "FK" },
    { country: "Faroe Islands", code: "298", iso: "FO" },
    { country: "Fiji", code: "679", iso: "FJ" },
    { country: "Finland", code: "358", iso: "FI" },
    { country: "France", code: "33", iso: "FR" },
    { country: "French Polynesia", code: "689", iso: "PF" },
    { country: "Gabon", code: "241", iso: "GA" },
    { country: "Gambia", code: "220", iso: "GM" },
    { country: "Georgia", code: "995", iso: "GE" },
    { country: "Germany", code: "49", iso: "DE" },
    { country: "Ghana", code: "233", iso: "GH" },
    { country: "Gibraltar", code: "350", iso: "GI" },
    { country: "Greece", code: "30", iso: "GR" },
    { country: "Greenland", code: "299", iso: "GL" },
    { country: "Grenada", code: "1-473", iso: "GD" },
    { country: "Guam", code: "1-671", iso: "GU" },
    { country: "Guatemala", code: "502", iso: "GT" },
    { country: "Guernsey", code: "44-1481", iso: "GG" },
    { country: "Guinea", code: "224", iso: "GN" },
    { country: "Guinea-Bissau", code: "245", iso: "GW" },
    { country: "Guyana", code: "592", iso: "GY" },
    { country: "Haiti", code: "509", iso: "HT" },
    { country: "Honduras", code: "504", iso: "HN" },
    { country: "Hong Kong", code: "852", iso: "HK" },
    { country: "Hungary", code: "36", iso: "HU" },
    { country: "Iceland", code: "354", iso: "IS" },
    { country: "India", code: "91", iso: "IN" },
    { country: "Indonesia", code: "62", iso: "ID" },
    { country: "Iran", code: "98", iso: "IR" },
    { country: "Iraq", code: "964", iso: "IQ" },
    { country: "Ireland", code: "353", iso: "IE" },
    { country: "Isle of Man", code: "44-1624", iso: "IM" },
    { country: "Israel", code: "972", iso: "IL" },
    { country: "Italy", code: "39", iso: "IT" },
    { country: "Ivory Coast", code: "225", iso: "CI" },
    { country: "Jamaica", code: "1-876", iso: "JM" },
    { country: "Japan", code: "81", iso: "JP" },
    { country: "Jersey", code: "44-1534", iso: "JE" },
    { country: "Jordan", code: "962", iso: "JO" },
    { country: "Kazakhstan", code: "7", iso: "KZ" },
    { country: "Kenya", code: "254", iso: "KE" },
    { country: "Kiribati", code: "686", iso: "KI" },
    { country: "Kosovo", code: "383", iso: "XK" },
    { country: "Kuwait", code: "965", iso: "KW" },
    { country: "Kyrgyzstan", code: "996", iso: "KG" },
    { country: "Laos", code: "856", iso: "LA" },
    { country: "Latvia", code: "371", iso: "LV" },
    { country: "Lebanon", code: "961", iso: "LB" },
    { country: "Lesotho", code: "266", iso: "LS" },
    { country: "Liberia", code: "231", iso: "LR" },
    { country: "Libya", code: "218", iso: "LY" },
    { country: "Liechtenstein", code: "423", iso: "LI" },
    { country: "Lithuania", code: "370", iso: "LT" },
    { country: "Luxembourg", code: "352", iso: "LU" },
    { country: "Macao", code: "853", iso: "MO" },
    { country: "Macedonia", code: "389", iso: "MK" },
    { country: "Madagascar", code: "261", iso: "MG" },
    { country: "Malawi", code: "265", iso: "MW" },
    { country: "Malaysia", code: "60", iso: "MY" },
    { country: "Maldives", code: "960", iso: "MV" },
    { country: "Mali", code: "223", iso: "ML" },
    { country: "Malta", code: "356", iso: "MT" },
    { country: "Marshall Islands", code: "692", iso: "MH" },
    { country: "Mauritania", code: "222", iso: "MR" },
    { country: "Mauritius", code: "230", iso: "MU" },
    { country: "Mayotte", code: "262", iso: "YT" },
    { country: "Mexico", code: "52", iso: "MX" },
    { country: "Micronesia", code: "691", iso: "FM" },
    { country: "Moldova", code: "373", iso: "MD" },
    { country: "Monaco", code: "377", iso: "MC" },
    { country: "Mongolia", code: "976", iso: "MN" },
    { country: "Montenegro", code: "382", iso: "ME" },
    { country: "Montserrat", code: "1-664", iso: "MS" },
    { country: "Morocco", code: "212", iso: "MA" },
    { country: "Mozambique", code: "258", iso: "MZ" },
    { country: "Myanmar", code: "95", iso: "MM" },
    { country: "Namibia", code: "264", iso: "NA" },
    { country: "Nauru", code: "674", iso: "NR" },
    { country: "Nepal", code: "977", iso: "NP" },
    { country: "Netherlands", code: "31", iso: "NL" },
    { country: "Netherlands Antilles", code: "599", iso: "AN" },
    { country: "New Caledonia", code: "687", iso: "NC" },
    { country: "New Zealand", code: "64", iso: "NZ" },
    { country: "Nicaragua", code: "505", iso: "NI" },
    { country: "Niger", code: "227", iso: "NE" },
    { country: "Nigeria", code: "234", iso: "NG" },
    { country: "Niue", code: "683", iso: "NU" },
    { country: "North Korea", code: "850", iso: "KP" },
    { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
    { country: "Norway", code: "47", iso: "NO" },
    { country: "Oman", code: "968", iso: "OM" },
    { country: "Pakistan", code: "92", iso: "PK" },
    { country: "Palau", code: "680", iso: "PW" },
    { country: "Palestine", code: "970", iso: "PS" },
    { country: "Panama", code: "507", iso: "PA" },
    { country: "Papua New Guinea", code: "675", iso: "PG" },
    { country: "Paraguay", code: "595", iso: "PY" },
    { country: "Peru", code: "51", iso: "PE" },
    { country: "Philippines", code: "63", iso: "PH" },
    { country: "Pitcairn", code: "64", iso: "PN" },
    { country: "Poland", code: "48", iso: "PL" },
    { country: "Portugal", code: "351", iso: "PT" },
    { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
    { country: "Qatar", code: "974", iso: "QA" },
    { country: "Republic of the Congo", code: "242", iso: "CG" },
    { country: "Reunion", code: "262", iso: "RE" },
    { country: "Romania", code: "40", iso: "RO" },
    { country: "Russia", code: "7", iso: "RU" },
    { country: "Rwanda", code: "250", iso: "RW" },
    { country: "Saint Barthelemy", code: "590", iso: "BL" },
    { country: "Saint Helena", code: "290", iso: "SH" },
    { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
    { country: "Saint Lucia", code: "1-758", iso: "LC" },
    { country: "Saint Martin", code: "590", iso: "MF" },
    { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
    { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
    { country: "Samoa", code: "685", iso: "WS" },
    { country: "San Marino", code: "378", iso: "SM" },
    { country: "Sao Tome and Principe", code: "239", iso: "ST" },
    { country: "Saudi Arabia", code: "966", iso: "SA" },
    { country: "Senegal", code: "221", iso: "SN" },
    { country: "Serbia", code: "381", iso: "RS" },
    { country: "Seychelles", code: "248", iso: "SC" },
    { country: "Sierra Leone", code: "232", iso: "SL" },
    { country: "Singapore", code: "65", iso: "SG" },
    { country: "Sint Maarten", code: "1-721", iso: "SX" },
    { country: "Slovakia", code: "421", iso: "SK" },
    { country: "Slovenia", code: "386", iso: "SI" },
    { country: "Solomon Islands", code: "677", iso: "SB" },
    { country: "Somalia", code: "252", iso: "SO" },
    { country: "South Africa", code: "27", iso: "ZA" },
    { country: "South Korea", code: "82", iso: "KR" },
    { country: "South Sudan", code: "211", iso: "SS" },
    { country: "Spain", code: "34", iso: "ES" },
    { country: "Sri Lanka", code: "94", iso: "LK" },
    { country: "Sudan", code: "249", iso: "SD" },
    { country: "Suriname", code: "597", iso: "SR" },
    { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
    { country: "Swaziland", code: "268", iso: "SZ" },
    { country: "Sweden", code: "46", iso: "SE" },
    { country: "Switzerland", code: "41", iso: "CH" },
    { country: "Syria", code: "963", iso: "SY" },
    { country: "Taiwan", code: "886", iso: "TW" },
    { country: "Tajikistan", code: "992", iso: "TJ" },
    { country: "Tanzania", code: "255", iso: "TZ" },
    { country: "Thailand", code: "66", iso: "TH" },
    { country: "Togo", code: "228", iso: "TG" },
    { country: "Tokelau", code: "690", iso: "TK" },
    { country: "Tonga", code: "676", iso: "TO" },
    { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
    { country: "Tunisia", code: "216", iso: "TN" },
    { country: "Turkey", code: "90", iso: "TR" },
    { country: "Turkmenistan", code: "993", iso: "TM" },
    { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
    { country: "Tuvalu", code: "688", iso: "TV" },
    { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
    { country: "Uganda", code: "256", iso: "UG" },
    { country: "Ukraine", code: "380", iso: "UA" },
    { country: "United Arab Emirates", code: "971", iso: "AE" },
    { country: "United Kingdom", code: "44", iso: "GB" },
    { country: "United States", code: "1", iso: "US" },
    { country: "Uruguay", code: "598", iso: "UY" },
    { country: "Uzbekistan", code: "998", iso: "UZ" },
    { country: "Vanuatu", code: "678", iso: "VU" },
    { country: "Vatican", code: "379", iso: "VA" },
    { country: "Venezuela", code: "58", iso: "VE" },
    { country: "Vietnam", code: "84", iso: "VN" },
    { country: "Wallis and Futuna", code: "681", iso: "WF" },
    { country: "Western Sahara", code: "212", iso: "EH" },
    { country: "Yemen", code: "967", iso: "YE" },
    { country: "Zambia", code: "260", iso: "ZM" },
    { country: "Zimbabwe", code: "263", iso: "ZW" },
  ];
  const months = [
    { ind: "1", code: "1" },
    { ind: "2", code: "2" },
    { ind: "3", code: "3" },
    { ind: "4", code: "4" },
    { ind: "5", code: "5" },
    { ind: "6", code: "6" },
    { ind: "7", code: "7" },
    { ind: "8", code: "8" },
    { ind: "9", code: "9" },
    { ind: "10", code: "10" },
    { ind: "11", code: "11" },
    { ind: "12", code: "12" },
  ];
  const years = [
    { ind: "2024", code: "24" },
    { ind: "2025", code: "25" },
    { ind: "2026", code: "26" },
    { ind: "2027", code: "27" },
    { ind: "2028", code: "28" },
    { ind: "2029", code: "29" },
    { ind: "2030", code: "30" },
    { ind: "2031", code: "31" },
    { ind: "2032", code: "32" },
    { ind: "2033", code: "33" },
    { ind: "2034", code: "34" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const lang = queryParameters.get("lang");
    if (lang === "en") {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    const localUuid = localStorage.getItem("uuid");
    const closeCookie = localStorage.getItem("closedCookie");

    if (localUuid === null || localUuid === undefined) {
      const uniqueId = uuidv4();
      axios
        .post("customerAIO/cookie", {
          uuid: uniqueId,
        })
        .then((x) => {
          localStorage.setItem("uuid", uniqueId);
        });
    }

    if (closeCookie !== null && closeCookie !== undefined) {
      setCookieAgreement(true);
    }
  }, []);

  useEffect(() => {
    axios.get(`customerAIO/iframerestaurant/${id}`).then((x) => {
      setRestaurantData({
        ...x.data,
        monthNow: moment(x.data.dateNow).month(),
        dayNow: moment(x.data.dateNow).date(),
        yearNow: moment(x.data.dateNow).year(),
      });
      setLoading(false);
    });
  }, []);

  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });

  function GetMealTimesAndReservations(dateCalendar) {
    const selectedDay = moment(dateCalendar).isoWeekday();

    axios
      .get(
        `customerAIO/iframereservations/${restaurantData.id}/${moment(
          dateCalendar
        ).format("yy-MM-DD")}`
      )
      .then((x) => {
        setLoading(false);
        setStep(1);

        let newList = x.data.map((a) => {
          const timeSlots = [];

          let currentTime = new Date(`${a.date}T${a.startTime}`);
          const end = new Date(`${a.date}T${a.endTime}`);
          if (
            !a.activeDays.split("~").some((c) => c === selectedDay.toString())
          ) {
            // seçilen tarih öğün için aktif gün değilse
            return { ...a, timeSlots: timeSlots };
          }

          while (currentTime <= end) {
            const hours = currentTime.getHours().toString().padStart(2, "0");
            const minutes = currentTime
              .getMinutes()
              .toString()
              .padStart(2, "0");
            if (
              a.reservations.some(
                (c) =>
                  (c.time === `${hours}:${minutes}:00` &&
                    c.resCount >= restaurantData.sameTimeResCount) || //aynı saate alınabilen rezervasyon sayısı 2
                  (c.time === `${hours}:${minutes}:00` && c.blocked) // zaman yönetiminden kapatılmışsa
              )
            ) {
            } else if (
              new Date(restaurantData.dateNow).setTime(
                new Date(restaurantData.dateNow).getTime() +
                  restaurantData.earliestHour * 60 * 60 * 1000
              ) < currentTime.getTime()
            ) {
              timeSlots.push(`${hours}:${minutes}`);
            }

            currentTime.setMinutes(
              currentTime.getMinutes() + restaurantData.reservationRange
            );
          }
          return { ...a, timeSlots: timeSlots };
        });
        setRestaurantData({ ...restaurantData, mealTimes: newList });
      });
  }

  function PostReservation() {
    setLoading(true);

    const noteWithTags =
      selectedTags.length > 0
        ? selectedTags.map((item) => item.name).join(" | ") +
          " | " +
          reservationData.customerNote
        : "" + reservationData.customerNote;

    axios
      .post("customerAIO/iframereservation", {
        customer: {
          name: reservationData.name,
          email: reservationData.email,
          areaCode: selectedAreaCode.code,
          phone: reservationData.phone,
        },
        guestCount: reservationData.guestCount,
        mealTimeId: reservationData.mealTimeId,
        date: moment(reservationData.date).format("yy-MM-DD"),
        time: reservationData.time + ":00",
        restaurantId: restaurantData.id,
        customerNote: noteWithTags,
        language: i18n.language,
      })
      .then((x) => {
        setReservationData({
          ...reservationData,
          reservationToken: x.data.reservationToken,
          reservationId: x.data.reservationId,
        });
        if (x.data.type === 1) {
          setStep(4);
        }
        if (x.data.type === 2) {
          //provizyon alınacaksa
          setStep(5);
        }
        if (x.data.type === 3) {
          //otp alınacaksa
          setOtpDlg(true);
        }
        setLoading(false);
      });
  }

  function PostPayment() {
    setLoading(true);
    const secretKey = "MPuxT04KmZOFsUTJ0pH1tHAakqrh8Kqd";
    const iv = "qdKwS3Jkc30aHUMF";

    const jsonData = {
      ...cardData,
      number: cardData.number.replace(/\s+/g, ""),
      reservationId: reservationData.reservationId,
      month: selectedMonth.code,
      year: selectedYear.code,
    };

    const jsonString = JSON.stringify(jsonData);

    const encrypted = CryptoJS.AES.encrypt(
      jsonString,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    axios
      .post("customerAIO/iframepaymenttr", {
        reservationId: reservationData.reservationId,
        hashedCard: encrypted.toString(),
      })
      .then((x) => {
        setLoading(false);
        if (x.data.threeDs === false) {
          setStep(4);
          return;
        }
        setThreeDsContent(
          decodeURIComponent(escape(window.atob(x.data.datas.html_content)))
        );
        setThreeDsDlg(true);
      })
      .catch((x) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: t("hata"),
          detail: t("kartHata"),
          life: 5000,
        });
      });
  }
  /* function PostPaymentOld() {
    setLoading(true);
    const secretKey = "MPuxT04KmZOFsUTJ0pH1tHAakqrh8Kqd";
    const iv = "qdKwS3Jkc30aHUMF";

    const jsonData = {
      ...cardData,
      number: cardData.number.replace(/\s+/g, ""),
      reservationId: reservationData.reservationId,
      month: selectedMonth.code,
      year: selectedYear.code,
    };

    const jsonString = JSON.stringify(jsonData);

    const encrypted = CryptoJS.AES.encrypt(
      jsonString,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    axios
      .post("customerAIO/iframepayment", {
        reservationId: reservationData.reservationId,
        hashedCard: encrypted.toString(),
      })
      .then((x) => {
        setThreeDsContent(x.data.htmlContent);
        setThreeDsDlg(true);
        //setStep(4);
        setLoading(false);
      })
      .catch((x) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: t("hata"),
          detail: t("kartHata"),
          life: 5000,
        });
      });
  }*/

  function ChangeStep(type) {
    switch (type) {
      case 0:
        setStep(type);
        break;
      case 1:
        if (step > 0) {
          setStep(type);
        }
        break;
      case 2:
        if (step > 1) {
          setStep(type);
        }
        break;
      case 3:
        if (step > 2) {
          setStep(type);
        }
        break;

      default:
        break;
    }
  }

  function Step0Temp() {
    function EndDateCalc() {
      const currentDate = new Date(restaurantData.dateNow);
      const newDate = new Date(restaurantData.dateNow);
      newDate.setDate(currentDate.getDate() + restaurantData.latestDay);

      return newDate;
    }

    const dateTemplate = (date) => {
      const minDate = new Date(
        restaurantData.yearNow,
        restaurantData.monthNow,
        restaurantData.dayNow
      );
      const maxDate = new Date(minDate);
      maxDate.setDate(minDate.getDate() + restaurantData.latestDay);

      const showDate = new Date(date.year, date.month, date.day);
      if (
        showDate.getTime() >= minDate.getTime() &&
        showDate.getTime() <= maxDate.getTime()
      ) {
        return <strong>{date.day}</strong>;
      }
      return date.day;
    };

    return (
      <>
        <div className="mb-2">{t("rezTarSec")}</div>
        <Calendar
          inline
          disabled={loading}
          locale={i18n.language}
          panelClassName="shadow-4"
          panelStyle={{ border: "none" }}
          dateTemplate={dateTemplate}
          value={reservationData.date}
          minDate={new Date(restaurantData.dateNow)}
          maxDate={EndDateCalc()}
          onChange={(e) =>
            setReservationData({ ...reservationData, date: e.value }) &
            setLoading(true) &
            GetMealTimesAndReservations(e.value)
          }
        />
      </>
    );
  }

  function Step1Temp() {
    return (
      <>
        <div className="mb-2">{t("rezSaatSec")}</div>
        <div
          className="shadow-4 p-2 flex flex-column align-items-center"
          style={{ width: windowWidth < 400 ? "95%" : "80%", borderRadius: 5 }}
        >
          {restaurantData?.mealTimes?.map((x) =>
            x.timeSlots.length > 0 ? (
              <>
                <div
                  className="font-bold text-lg mt-2 mb-3 "
                  style={{ borderBottom: "1px solid #F39237" }}
                >
                  {i18n.language === "en" ? x.nameEn : x.name}
                </div>
                <div className="flex flex-wrap gap-2 justify-content-center">
                  {x.timeSlots.map((a) => (
                    <div
                      className="shadow-1 py-3 text-center select-none cursor-pointer hover:bg-orange-300"
                      style={{
                        backgroundColor:
                          reservationData.time === a &&
                          reservationData.mealTimeId === x.id
                            ? "#F39237"
                            : "#f9f9f9",
                        border: "1px solid gainsboro",
                        borderRadius: 5,
                        fontWeight: 500,
                        fontSize: 16,
                        minWidth: 60,
                      }}
                      onClick={() =>
                        setReservationData({
                          ...reservationData,
                          time: a,
                          mealTimeId: x.id,
                        }) & setStep(2)
                      }
                    >
                      {a}
                    </div>
                  ))}
                </div>
                {x.infoMsg !== "" && (
                  <Message
                    className="my-2 py-1 shadow-3"
                    severity="warn"
                    text="Warning Message"
                    style={{
                      borderRadius: 5,
                      width: "90%",
                      color: "dimgray",
                    }}
                    content={
                      <div className="flex flex-column align-items-center">
                        <div className="font-bold text-md">{t("bilgi")}</div>
                        <div className="font-semibold text-sm mt-1">
                          {i18n.language === "en" ? x.infoMsgEn : x.infoMsg}
                        </div>
                      </div>
                    }
                  />
                )}
              </>
            ) : (
              <>
                <div
                  className="font-bold text-lg mt-2 mb-3 "
                  style={{ borderBottom: "1px solid #F39237" }}
                >
                  {i18n.language === "en" ? x.nameEn : x.name}
                </div>
                <Message
                  className="my-2 py-1 shadow-3"
                  severity="warn"
                  text="Warning Message"
                  style={{
                    borderRadius: 5,
                    width: "90%",
                    color: "dimgray",
                  }}
                  content={
                    <div className="flex flex-column align-items-center">
                      <div className="font-bold text-md">{t("bilgi")}</div>
                      <div className="font-semibold text-sm mt-1">
                        {t("rezUygunSaatYok")}
                      </div>
                      <div
                        className="font-semibold text-sm mt-1 cursor-pointer"
                        style={{ textDecoration: "underline" }}
                        onClick={() => setStep(0)}
                      >
                        {t("farkliTarihSec")}
                      </div>
                    </div>
                  }
                />
              </>
            )
          )}
        </div>
      </>
    );
  }

  function Step2Temp() {
    const range = Array.from(
      { length: restaurantData.maxPeople - restaurantData.minPeople + 1 },
      (_, index) => restaurantData.minPeople + index
    );
    return (
      <>
        <div className="mb-2">{t("konSaySec")}</div>
        <div
          className="p-2 shadow-4 flex flex-wrap justify-content-center gap-2"
          style={{ width: windowWidth < 400 ? "95%" : "80%", borderRadius: 5 }}
        >
          {range.map((x) => (
            <div
              className="shadow-1 py-3 text-center select-none cursor-pointer hover:bg-orange-300"
              style={{
                backgroundColor:
                  reservationData.guestCount === x ? "#F39237" : "#f9f9f9",
                border: "1px solid gainsboro",
                borderRadius: 5,
                fontWeight: 500,
                fontSize: 16,
                minWidth: 60,
              }}
              onClick={() =>
                setReservationData({ ...reservationData, guestCount: x }) &
                setStep(3)
              }
            >
              {x}
            </div>
          ))}
          <div className="col-12 m-0 p-0">
            {restaurantData.guestCountMsg !== "" && (
              <Message
                className="shadow-3 mt-2"
                severity="warn"
                text="Warning Message"
                style={{
                  borderRadius: 5,
                  width: "100%",
                  color: "dimgray",
                }}
                content={
                  <div className="flex flex-column align-items-center">
                    <div className="font-bold text-md">{t("bilgi")}</div>
                    <div className="font-semibold text-sm mt-1">
                      {i18n.language === "en"
                        ? restaurantData.guestCountMsgEn
                        : restaurantData.guestCountMsg}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </>
    );
  }

  function Step3Temp() {
    return (
      <>
        <div className="mb-2">{t("bilgiDol")}</div>
        <div
          className="p-2 shadow-4 flex flex-wrap justify-content-center gap-1"
          style={{ width: windowWidth < 400 ? "95%" : "80%", borderRadius: 5 }}
        >
          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="name"
                  value={reservationData.name}
                  onChange={(e) =>
                    setReservationData({
                      ...reservationData,
                      name: e.target.value,
                    })
                  }
                />
                <label for="name">{t("isimSoyisim")}*</label>
              </FloatLabel>
            </div>
          </div>
          <div
            className="col-12 flex-wrap"
            style={{ display: "flex", gap: "1rem" }}
          >
            <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
              <FloatLabel>
                <Dropdown
                  filter
                  id="areaCode"
                  value={selectedAreaCode}
                  style={{ borderRadius: 3 }}
                  onChange={(e) =>
                    setSelectedAreaCode(e.value) &
                    setReservationData({
                      ...reservationData,
                      areaCode: parseInt(e.value.code),
                    })
                  }
                  valueTemplate={(x) => x.country + " +" + x.code}
                  itemTemplate={(x) => x.country + " +" + x.code}
                  options={areaCodes}
                  optionLabel="code"
                  placeholder="Select a Areacode"
                />
                <label for="areaCode">{t("alanKodu")}</label>
              </FloatLabel>
            </div>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="phone"
                  autoComplete="one-time-code"
                  value={reservationData.phone}
                  style={{ minWidth: 150 }}
                  onChange={(e) => {
                    let input = e.target.value;

                    // Sadece sayıları kabul et
                    const isNumeric = /^\d*$/.test(input);

                    // İlk karakter sıfır olmamalı ve sadece sayılar girilmeli ve uzunluk 15 haneyi geçmemeli
                    if (isNumeric && input.length <= 15) {
                      if (input.length === 0 || true) {
                        setReservationData({
                          ...reservationData,
                          phone: input,
                        });
                      }
                    }
                  }}
                  maxLength={15}
                />
                <label for="phone">{t("telefon")}*</label>
              </FloatLabel>
            </div>
          </div>
          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="email"
                  value={reservationData.email}
                  onChange={(e) =>
                    setReservationData({
                      ...reservationData,
                      email: e.target.value,
                    })
                  }
                />
                <label for="email">{t("mail")}*</label>
              </FloatLabel>
            </div>
          </div>
          {restaurantData.tagsOpen && restaurantData.tags.length > 0 && (
            <div
              className="col-12"
              style={{ display: "flex", gap: ".5rem", flexWrap: "wrap" }}
            >
              {restaurantData.tags.map((x) => (
                <div
                  className="px-2 py-1 select-none cursor-pointer shadow-3 font-semibold"
                  style={{
                    border: "1px solid grey",
                    borderRadius: 25,
                    backgroundColor: selectedTags.some((a) => a.id === x.id)
                      ? "#F39237"
                      : "white",
                  }}
                  onClick={() => {
                    const isHave = selectedTags.some((a) => a.id === x.id);
                    if (isHave) {
                      setSelectedTags((prev) =>
                        prev.filter((a) => a.id !== x.id)
                      );
                    } else {
                      setSelectedTags((prev) => [...prev, x]);
                    }
                  }}
                >
                  {i18n.language === "en" ? x.nameEn : x.name}
                </div>
              ))}
            </div>
          )}
          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputTextarea
                  maxLength={250}
                  id="customerNote"
                  value={reservationData.customerNote}
                  onChange={(e) =>
                    setReservationData({
                      ...reservationData,
                      customerNote: e.target.value,
                    })
                  }
                  rows={5}
                  cols={30}
                  style={{ borderRadius: 3 }}
                />
                <label htmlFor="customerNote">{t("ozelIstek")}</label>
              </FloatLabel>
            </div>
          </div>
          <div className="mb-2 text-sm font-semibold" style={{ color: "grey" }}>
            {t("herhangiAlerji")}
          </div>
          <div className="col-12 flex align-items-center gap-2">
            <Checkbox
              inputId="agreementchx"
              name="agreementchx"
              onChange={() => setAydinlatmaAgreement(!aydinlatmaAgreement)}
              checked={aydinlatmaAgreement}
            />
            <label
              htmlFor="agreementchx"
              className="select-none cursor-pointer text-700 font-semibold"
            >
              <a
                href={`https://app.reserone.com/agreements/Aydinlatma.pdf`}
                target="_blank"
              >
                {t("aydinlatmaMetni")}
              </a>
              {t("okudumOnayliyorum")}
            </label>
          </div>
          <div className="col-12 flex align-items-center gap-2">
            <Checkbox
              inputId="agreement2chx"
              name="agreementchx"
              onChange={() => setIletiAgreement(!iletiAgreement)}
              checked={iletiAgreement}
            />
            <label
              htmlFor="agreement2chx"
              className="select-none cursor-pointer text-700 font-semibold"
            >
              <a
                href={`https://app.reserone.com/agreements/Ticari_Elektronik_İleti.pdf`}
                target="_blank"
              >
                {t("ticariElektronik")}
              </a>
              {t("okudumOnayliyorum")}
            </label>
          </div>

          <div className="col-12">
            <Button
              disabled={
                aydinlatmaAgreement &&
                iletiAgreement &&
                reservationData.phone.length > 5 &&
                reservationData.name.length > 2 &&
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(reservationData.email)
                  ? false
                  : true
              }
              onClick={() => PostReservation()}
              loading={loading}
              className="w-full"
              label={t("rezOlustur")}
              style={{ backgroundColor: "#F39237" }}
            />
          </div>
        </div>
      </>
    );
  }

  function Step4Temp() {
    return (
      <>
        <div
          className="shadow-4 py-4 mt-5 flex flex-column align-items-center"
          style={{ width: windowWidth < 400 ? "95%" : "80%", borderRadius: 5 }}
        >
          <div className="flex flex-column align-items-center gap-7 w-full">
            <div className="text-lg font-bold">{t("talepAlindi")}</div>
            <div className="text-md font-semibold flex">
              {t("rezKodu")}:
              <div
                className="ml-2"
                style={{
                  letterSpacing: 2,
                  color: "darkgreen",
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                {reservationData.reservationToken}
              </div>
            </div>
            <div className="text-md font-semibold">
              {t("sayin")} {reservationData.name}
            </div>
            <div
              className="text-sm font-semibold text-center pb-5"
              style={{ borderBottom: "2px solid grey", width: "90%" }}
            >
              {t("rezBasarili")}
            </div>
          </div>
          <div className="flex flex-column mt-4 align-items-center gap-7 w-full font-semibold text-md">
            <div className="text-lg font-bold">{t("rezBilgi")}</div>
            <div
              className="flex justify-content-between "
              style={{ width: "60%" }}
            >
              <div className="flex">
                <div className="flex align-items-center mr-2">
                  <FontAwesomeIcon icon={faCalendarCheck} fontSize="30px" />
                </div>
                <div>
                  <div>{t("gun")}</div>
                  <div>{moment(reservationData.date).format("DD.MM.yy")}</div>
                </div>
              </div>
              <div className="flex">
                <div className="flex align-items-center mr-2">
                  <FontAwesomeIcon icon={faClock} fontSize="30px" />
                </div>
                <div>
                  <div>{t("saat")}</div>
                  <div>{reservationData.time}</div>
                </div>
              </div>
            </div>
            <div style={{ width: "60%" }}>
              <div className="flex">
                <div className="flex align-items-center mr-2">
                  <FontAwesomeIcon icon={faUsers} fontSize="25px" />
                </div>
                <div>
                  <div>{t("kisi")}</div>
                  <div>{reservationData.guestCount}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  //step === 5 provizyon aktifse kart ekranı
  function Step5Temp() {
    return (
      <div
        className="p-2 shadow-4 flex flex-wrap justify-content-center gap-1"
        style={{
          width: windowWidth < 400 ? "95%" : "80%",
          borderRadius: 5,
          backgroundColor: "#f9f9f9",
        }}
      >
        <div className="font-bold text-lg">{t("rezOdemesi")}</div>

        <Fieldset legend="Kart Bilgileri" style={{ width: "100%" }}>
          <div
            className="col-12 mt-0 pt-0 font-semibold text-md"
            style={{ color: "blue" }}
          >
            **{t("sadeceKredi")}
          </div>
          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="cardName"
                  value={cardData.name}
                  onChange={(e) =>
                    setCardData({
                      ...cardData,
                      name: e.target.value,
                    })
                  }
                />
                <label for="cardName">{t("cardName")}</label>
              </FloatLabel>
            </div>
          </div>
          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputMask
                  id="cardNumber"
                  value={cardData.number}
                  onChange={(e) =>
                    setCardData({
                      ...cardData,
                      number: e.target.value,
                    })
                  }
                  mask="9999 9999 9999 9999"
                />
                <label for="cardNumber">{t("cardNumber")}</label>
              </FloatLabel>
            </div>
          </div>
          <div
            className="col-12 flex-wrap"
            style={{ display: "flex", gap: "1rem" }}
          >
            <div className="p-inputgroup" style={{ flex: "0 0 100px" }}>
              <FloatLabel>
                <Dropdown
                  id="month"
                  value={selectedMonth}
                  style={{ borderRadius: 3 }}
                  onChange={(e) => setSelectedMonth(e.value)}
                  options={months}
                  optionLabel="ind"
                  placeholder="Month"
                />
                <label for="month">{t("ay")}</label>
              </FloatLabel>
            </div>
            <div className="p-inputgroup" style={{ flex: "0 0 100px" }}>
              <FloatLabel>
                <Dropdown
                  id="year"
                  value={selectedYear}
                  style={{ borderRadius: 3 }}
                  onChange={(e) => setSelectedYear(e.value)}
                  options={years}
                  optionLabel="ind"
                  placeholder="Year"
                />
                <label for="year">{t("yil")}</label>
              </FloatLabel>
            </div>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="cvc"
                  style={{ minWidth: "70px" }}
                  value={cardData.cvc}
                  onChange={(e) =>
                    setCardData({
                      ...cardData,
                      cvc: e.target.value,
                    })
                  }
                />
                <label for="cvc">CVC</label>
              </FloatLabel>
            </div>
          </div>
        </Fieldset>
        <Fieldset legend={t("bilgi")} style={{ width: "100%" }}>
          <div className="font-semibold">
            {t("konukSayisi")}: {reservationData.guestCount}
          </div>
          <div className="font-semibold">
            {t("provTutar")}:{" "}
            {parseInt(
              reservationData.guestCount * restaurantData.provisionAmount
            )}
            ₺
          </div>
          <div className="font-semibold mt-2">{t("provizyonIade")}</div>
          <div className="font-semibold">{t("kartsaklanmiyor")}</div>
        </Fieldset>
        <Button
          label={t("odemeYap")}
          onClick={() => PostPayment()}
          loading={loading}
          className="shadow-4 my-3 font-bold text-lg"
          style={{ backgroundColor: "#F39237", color: "black" }}
        />
      </div>
    );
  }

  function OtpDlgTemp() {
    return (
      <Dialog
        header={t("dogrulama")}
        visible={otpDlg}
        closable={true}
        onHide={() => setOtpDlg(false)}
      >
        <div className="flex m-2 flex-column justify-content-center align-items-center gap-3">
          <div className="font-bold text-lg">{t("otpGir")}</div>
          <InputOtp
            value={otpValue}
            onChange={(e) => {
              setOtpValue(e.value);
              setWrongOtp(false);
              if (e.value.length === 6) {
                axios
                  .get(
                    `customerAIO/validate/${reservationData.reservationId}/${e.value}`
                  )
                  .then(() => {
                    setOtpDlg(false);
                    setLoading(false);
                    setOtpValue("");
                    setStep(4);
                  })
                  .catch(
                    () =>
                      setWrongOtp(true) & setLoading(false) & setOtpValue("")
                  );
              }
            }}
            integerOnly
            length={6}
          />
          {wrongOtp && (
            <div className="font-bold" style={{ color: "darkred" }}>
              {t("errorOtp")}
            </div>
          )}
          <Button
            label={t("dogrula")}
            erro
            loading={loading}
            disabled={otpValue.length < 6}
            onClick={() => {
              axios
                .get(
                  `customerAIO/validate/${reservationData.reservationId}/${otpValue}`
                )
                .then(() => {
                  setOtpDlg(false);
                  setLoading(false);
                  setOtpValue("");
                  setStep(4);
                })
                .catch(
                  () => setWrongOtp(true) & setLoading(false) & setOtpValue("")
                );
            }}
          />
        </div>
      </Dialog>
    );
  }

  function AgreementDlgTmp() {
    return (
      <Dialog
        maximized={true}
        visible={agreementDlg}
        closable={true}
        onHide={() => setAgreementDlg(false) & setSelectedAgreement(0)}
      ></Dialog>
    );
  }

  function ThreeDsDlgTemp() {
    return (
      <Dialog
        maximized={true}
        visible={threeDsDlg}
        closable={true}
        onHide={() => setThreeDsDlg(false)}
      >
        <div>
          {threeDsContent !== null ? (
            <iframe
              srcDoc={threeDsContent}
              style={{ width: "100%", height: "600px", border: "none" }}
            />
          ) : (
            <p>Ödeme sayfası yükleniyor...</p>
          )}
        </div>
      </Dialog>
    );
  }

  useEffect(() => {
    if (step !== 5) return;
    if (
      reservationData?.reservationId === null ||
      reservationData?.reservationId === undefined
    ) {
      return;
    }
    const connection = new HubConnectionBuilder()
      .withUrl("https://api.reserone.com/msghub")
      //.withUrl("https://localhost:7100/msghub")
      .withAutomaticReconnect()
      .build();

    let conId = reservationData.reservationId + ""; // conversation id customer ekranları için veya restoran için restid;
    connection
      .start()
      .then(() => {
        connection
          .invoke("RegisterTransaction", conId)
          .then(() => {})
          .catch((err) => console.error(err));
        connection.on("ReceiveMessage", (message) => {
          if (message === "success") {
            setThreeDsDlg(false);
            setStep(4);
            setLoading(false);
            toast.current.show({
              severity: "success",
              summary: "Başarılı",
              detail: "Ödeme başarıyla gerçekleşti.",
              life: 5000,
            });
          } else if (message === "failure") {
            toast.current.show({
              severity: "error",
              summary: "HATA",
              detail: "Bir hata oluştu.",
              life: 5000,
            });
            setThreeDsDlg(false);
            setLoading(false);
          }
        });
      })
      .catch((err) => console.error("Bağlantı hatası:", err));

    return () => {
      connection.stop();
    };
  }, [step, reservationData?.reservationId]);

  return (
    <>
      {OtpDlgTemp()}
      {AgreementDlgTmp()}
      {ThreeDsDlgTemp()}
      <Toast ref={toast} />
      <div
        className="h-full w-full flex m-0 p-0"
        style={{ backgroundColor: "#fff", overflow: "hidden" }}
      >
        <div
          className="m-0 p-0 hidden md:flex md:col-6 lg:col-6"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(https://app.reserone.com/imgs/${restaurantData.imageurl})`,
          }}
        ></div>
        <div
          className="flex flex-column align-items-center m-0 col h-full"
          style={{
            overflowY: "auto",
            //paddingBottom: "90px",
          }}
        >
          {loading && (
            <ProgressBar
              mode="indeterminate"
              color="#F39237"
              style={{ height: "3px", position: "absolute", width: "50%" }}
            />
          )}

          {step !== 4 && (
            <>
              <div className="flex gap-2">
                <img
                  className="cursor-pointer"
                  onClick={() => i18n.changeLanguage("tr")}
                  src={`${process.env.PUBLIC_URL}/tr.png`}
                  width={50}
                  alt="tr"
                  style={{
                    borderBottom:
                      i18n.language === "tr" ? "5px solid #F39237" : "none",
                  }}
                />
                <img
                  className="cursor-pointer"
                  onClick={() => i18n.changeLanguage("en")}
                  src={`${process.env.PUBLIC_URL}/en.png`}
                  width={50}
                  alt="tr"
                  style={{
                    borderBottom:
                      i18n.language === "en" ? "5px solid #F39237" : "none",
                  }}
                />
              </div>
              {restaurantData.logourl !== null && (
                <a
                  href={restaurantData.weburl}
                  className="cursor-pointer"
                  target="_blank"
                  // width={"20%"}
                >
                  <img
                    className="mt-3 mb-1"
                    src={
                      "https://app.reserone.com/imgs/" + restaurantData.logourl
                    }
                    alt="logo"
                    style={{ height: 150 }}
                  />
                </a>
              )}
              {restaurantData?.logourl?.length === 0 && (
                <div className="my-2 font-semibold text-lg">
                  {restaurantData?.name}
                </div>
              )}
              <div
                className="flex mt-3 mb-3 text-md"
                style={{
                  color: "grey",
                  gap: windowWidth < 400 ? "0.5rem" : "1.5rem",
                  fontSize: windowWidth < 400 ? "0.875rem" : "1rem",
                }}
              >
                <div
                  className="pt-2 flex flex-column align-items-center cursor-pointer select-none hover:text-black-alpha-70"
                  onClick={() => ChangeStep(0)}
                >
                  <FontAwesomeIcon
                    style={{ color: step >= 1 ? "#F39237" : null }}
                    icon={faCalendarCheck}
                    fontSize={windowWidth < 400 ? 20 : 30}
                  />
                  {t("gun")}
                  {reservationData.date !== undefined && (
                    <div style={{ fontWeight: 600, color: "black" }}>
                      {moment(reservationData.date).format("DD.MM.yy")}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    borderRight: "2px solid gainsboro",
                  }}
                />
                <div
                  className="pt-2 flex flex-column align-items-center cursor-pointer select-none hover:text-black-alpha-70"
                  onClick={() => ChangeStep(1)}
                >
                  <FontAwesomeIcon
                    style={{ color: step >= 2 ? "#F39237" : null }}
                    icon={faClock}
                    fontSize={windowWidth < 400 ? 20 : 30}
                  />
                  {t("saat")}
                  {reservationData.time !== undefined && (
                    <div style={{ fontWeight: 600, color: "black" }}>
                      {reservationData.time}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    borderRight: "2px solid gainsboro",
                  }}
                />
                <div
                  className="pt-2 flex flex-column align-items-center cursor-pointer select-none hover:text-black-alpha-70"
                  onClick={() => ChangeStep(2)}
                >
                  <FontAwesomeIcon
                    style={{ color: step >= 3 ? "#F39237" : null }}
                    icon={faUsers}
                    fontSize={windowWidth < 400 ? 20 : 30}
                  />
                  {t("kisi")}
                  {reservationData.guestCount !== undefined && (
                    <div style={{ fontWeight: 600, color: "black" }}>
                      {reservationData.guestCount}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    borderRight: "2px solid gainsboro",
                  }}
                />
                <div
                  className="pt-2 flex flex-column align-items-center cursor-pointer select-none hover:text-black-alpha-70"
                  style={{}}
                  onClick={() => ChangeStep(3)}
                >
                  <FontAwesomeIcon
                    style={{ color: step >= 4 ? "#F39237" : null }}
                    icon={faCheck}
                    fontSize={windowWidth < 400 ? 20 : 30}
                  />
                  {t("tamamlandi")}
                </div>
              </div>
            </>
          )}
          {restaurantData?.id > 0 && step === 0 && Step0Temp()}
          {step === 1 && Step1Temp()}
          {step === 2 && Step2Temp()}
          {step === 3 && Step3Temp()}
          {step === 4 && Step4Temp()}
          {step === 5 && Step5Temp()}

          <a href="https://reserone.com/" target="_blank" className="mt-4">
            <img
              className="cursor-pointer"
              src={`${process.env.PUBLIC_URL}/logobgwhite.png`}
              height="45px"
              alt="logo"
            />
          </a>
        </div>
        {!cookieAgreement && (
          <div
            className="absolute bottom-0 left-0 right-0 font-semibold text-sm text-300 flex flex-row align-items-center justify-content-between p-2"
            style={{ background: "black", color: "white" }}
          >
            <span>
              {t("cookieMessage")}
              <a
                href={`https://app.reserone.com/agreements/Cerez_Politikasi.pdf`}
                target="_blank"
                className="ml-2"
              >
                {t("readMore")}
              </a>
            </span>

            <Button
              onClick={() => {
                localStorage.setItem("closedCookie", "true");
                setCookieAgreement(true);
              }}
              className="ml-3 p-1"
              label={t("kapat")}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default IFrameReservation;
