import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, Outlet } from "react-router-dom";
import { userInfoCRUD } from "../redux/counter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faHome,
  faHotel,
  faUser,
  faUsers,
  faUsersViewfinder,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

function SiderbarProAdmin() {
  const { userInfo } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [waitingAuth, setWaitingAuth] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sidebarActive, setSidebarActive] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const decoded = jwtDecode(token);
      const idClaim =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/serialnumber";

      axios
        .post("login/tokencheck", {
          id: decoded[idClaim],
        })
        .then((x) => {
          localStorage.setItem("token", x.data.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${x.data.token}`;

          dispatch(
            userInfoCRUD({
              token: x.data.token,
              email: x.data.email,
              lastname: x.data.lastname,
              name: x.data.name,
              userId: x.data.userId,
              userLevel: x.data.userLevel,
            })
          );
          if (x.data.userLevel === 2) {
            navigate("/client");
          }
          setWaitingAuth(false);
        })
        .catch((x) => {
          localStorage.removeItem("token");
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, []);
  return (
    <>
      {" "}
      <div
        style={{
          display: "flex",
          height: "100%",
          direction: "ltr",
        }}
      >
        <Sidebar
          backgroundColor="#222d33"
          style={{
            overflowY: "auto",
            minWidth: sidebarActive ? "210px" : "70px",
            maxWidth: sidebarActive ? "210px" : "70px",
          }}
        >
          <Menu
            menuItemStyles={{
              button: {
                color: "white",
                fontWeight: "bold",
                transition: "0.25s",
                height: "auto",
                minHeight: 50,
                ":hover": { color: "#F39237" },
              },
            }}
          >
            <MenuItem component={<Link to="/management" />}>
              <div
                className="flex flex-row "
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className=" ">
                  <FontAwesomeIcon
                    icon={faHome}
                    fontSize={!sidebarActive ? "25px" : "35px"}
                  />{" "}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  {sidebarActive && "  Ana Sayfa"}
                </div>
              </div>
            </MenuItem>
            <MenuItem component={<Link to="/management/companies" />}>
              <div
                className="flex flex-row "
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className=" ">
                  <FontAwesomeIcon
                    icon={faHotel}
                    fontSize={!sidebarActive ? "25px" : "35px"}
                  />{" "}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  {sidebarActive && "  Şirket Listesi"}
                </div>
              </div>
            </MenuItem>
            <MenuItem component={<Link to="/management/restaurants" />}>
              <div
                className="flex flex-row "
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className=" ">
                  <FontAwesomeIcon
                    icon={faUtensils}
                    fontSize={!sidebarActive ? "25px" : "35px"}
                  />{" "}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  {sidebarActive && "  Restoran Listesi"}
                </div>
              </div>
            </MenuItem>
            <MenuItem component={<Link to="/management/users" />}>
              <div
                className="flex flex-row "
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className=" ">
                  <FontAwesomeIcon
                    icon={faUser}
                    fontSize={!sidebarActive ? "25px" : "35px"}
                  />{" "}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  {sidebarActive && "  Kullanıcı Listesi"}
                </div>
              </div>
            </MenuItem>
            <MenuItem component={<Link to="/client" />}>
              <div
                className="flex flex-row "
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className=" ">
                  <FontAwesomeIcon
                    icon={faUsersViewfinder}
                    fontSize={!sidebarActive ? "20px" : "30px"}
                  />{" "}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  {sidebarActive && "  Restoran Ekranı"}
                </div>
              </div>
            </MenuItem>
            <MenuItem>
              <div
                className="my-2 py-2"
                onClick={() => setSidebarActive(!sidebarActive)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={sidebarActive ? faArrowLeft : faArrowRight}
                  fontSize={!sidebarActive ? "25px" : "35px"}
                />
              </div>
            </MenuItem>
          </Menu>
        </Sidebar>

        <div style={{ width: "100%", overflowY: "auto" }}>
          {!waitingAuth && <Outlet />}
        </div>
      </div>
    </>
  );
}

export default SiderbarProAdmin;
