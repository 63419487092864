import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faLocationPin,
  faBank,
  faAdd,
  faPencil,
  faBriefcase,
  faWallet,
  faCrown,
  faRepeat,
  faWheelchair,
  faUsers,
  faAddressCard,
  faStar,
  faTriangleExclamation,
  faCheck,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment/moment";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

function Customers() {
  const { t, i18n } = useTranslation();

  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const { ddSelectedRestaurant } = useSelector((state) => state.counter);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [addCustomerDlg, setaddCustomerDlg] = useState(false);
  const [editCustomerDlg, setEditCustomerDlg] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [customerReservations, setCustomerReservations] = useState([]);
  const [customerReservationsDlg, setCustomerReservationsDlg] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedAreaCode, setSelectedAreaCode] = useState({
    country: "Turkey",
    code: "90",
    iso: "TR",
  });
  const areaCodes = [
    { country: "Afghanistan", code: "93", iso: "AF" },
    { country: "Albania", code: "355", iso: "AL" },
    { country: "Algeria", code: "213", iso: "DZ" },
    { country: "American Samoa", code: "1-684", iso: "AS" },
    { country: "Andorra", code: "376", iso: "AD" },
    { country: "Angola", code: "244", iso: "AO" },
    { country: "Anguilla", code: "1-264", iso: "AI" },
    { country: "Antarctica", code: "672", iso: "AQ" },
    { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
    { country: "Argentina", code: "54", iso: "AR" },
    { country: "Armenia", code: "374", iso: "AM" },
    { country: "Aruba", code: "297", iso: "AW" },
    { country: "Australia", code: "61", iso: "AU" },
    { country: "Austria", code: "43", iso: "AT" },
    { country: "Azerbaijan", code: "994", iso: "AZ" },
    { country: "Bahamas", code: "1-242", iso: "BS" },
    { country: "Bahrain", code: "973", iso: "BH" },
    { country: "Bangladesh", code: "880", iso: "BD" },
    { country: "Barbados", code: "1-246", iso: "BB" },
    { country: "Belarus", code: "375", iso: "BY" },
    { country: "Belgium", code: "32", iso: "BE" },
    { country: "Belize", code: "501", iso: "BZ" },
    { country: "Benin", code: "229", iso: "BJ" },
    { country: "Bermuda", code: "1-441", iso: "BM" },
    { country: "Bhutan", code: "975", iso: "BT" },
    { country: "Bolivia", code: "591", iso: "BO" },
    { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
    { country: "Botswana", code: "267", iso: "BW" },
    { country: "Brazil", code: "55", iso: "BR" },
    { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
    { country: "British Virgin Islands", code: "1-284", iso: "VG" },
    { country: "Brunei", code: "673", iso: "BN" },
    { country: "Bulgaria", code: "359", iso: "BG" },
    { country: "Burkina Faso", code: "226", iso: "BF" },
    { country: "Burundi", code: "257", iso: "BI" },
    { country: "Cambodia", code: "855", iso: "KH" },
    { country: "Cameroon", code: "237", iso: "CM" },
    { country: "Canada", code: "1", iso: "CA" },
    { country: "Cape Verde", code: "238", iso: "CV" },
    { country: "Cayman Islands", code: "1-345", iso: "KY" },
    { country: "Central African Republic", code: "236", iso: "CF" },
    { country: "Chad", code: "235", iso: "TD" },
    { country: "Chile", code: "56", iso: "CL" },
    { country: "China", code: "86", iso: "CN" },
    { country: "Christmas Island", code: "61", iso: "CX" },
    { country: "Cocos Islands", code: "61", iso: "CC" },
    { country: "Colombia", code: "57", iso: "CO" },
    { country: "Comoros", code: "269", iso: "KM" },
    { country: "Cook Islands", code: "682", iso: "CK" },
    { country: "Costa Rica", code: "506", iso: "CR" },
    { country: "Croatia", code: "385", iso: "HR" },
    { country: "Cuba", code: "53", iso: "CU" },
    { country: "Curacao", code: "599", iso: "CW" },
    { country: "Cyprus", code: "357", iso: "CY" },
    { country: "Czech Republic", code: "420", iso: "CZ" },
    { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
    { country: "Denmark", code: "45", iso: "DK" },
    { country: "Djibouti", code: "253", iso: "DJ" },
    { country: "Dominica", code: "1-767", iso: "DM" },
    { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
    { country: "East Timor", code: "670", iso: "TL" },
    { country: "Ecuador", code: "593", iso: "EC" },
    { country: "Egypt", code: "20", iso: "EG" },
    { country: "El Salvador", code: "503", iso: "SV" },
    { country: "Equatorial Guinea", code: "240", iso: "GQ" },
    { country: "Eritrea", code: "291", iso: "ER" },
    { country: "Estonia", code: "372", iso: "EE" },
    { country: "Ethiopia", code: "251", iso: "ET" },
    { country: "Falkland Islands", code: "500", iso: "FK" },
    { country: "Faroe Islands", code: "298", iso: "FO" },
    { country: "Fiji", code: "679", iso: "FJ" },
    { country: "Finland", code: "358", iso: "FI" },
    { country: "France", code: "33", iso: "FR" },
    { country: "French Polynesia", code: "689", iso: "PF" },
    { country: "Gabon", code: "241", iso: "GA" },
    { country: "Gambia", code: "220", iso: "GM" },
    { country: "Georgia", code: "995", iso: "GE" },
    { country: "Germany", code: "49", iso: "DE" },
    { country: "Ghana", code: "233", iso: "GH" },
    { country: "Gibraltar", code: "350", iso: "GI" },
    { country: "Greece", code: "30", iso: "GR" },
    { country: "Greenland", code: "299", iso: "GL" },
    { country: "Grenada", code: "1-473", iso: "GD" },
    { country: "Guam", code: "1-671", iso: "GU" },
    { country: "Guatemala", code: "502", iso: "GT" },
    { country: "Guernsey", code: "44-1481", iso: "GG" },
    { country: "Guinea", code: "224", iso: "GN" },
    { country: "Guinea-Bissau", code: "245", iso: "GW" },
    { country: "Guyana", code: "592", iso: "GY" },
    { country: "Haiti", code: "509", iso: "HT" },
    { country: "Honduras", code: "504", iso: "HN" },
    { country: "Hong Kong", code: "852", iso: "HK" },
    { country: "Hungary", code: "36", iso: "HU" },
    { country: "Iceland", code: "354", iso: "IS" },
    { country: "India", code: "91", iso: "IN" },
    { country: "Indonesia", code: "62", iso: "ID" },
    { country: "Iran", code: "98", iso: "IR" },
    { country: "Iraq", code: "964", iso: "IQ" },
    { country: "Ireland", code: "353", iso: "IE" },
    { country: "Isle of Man", code: "44-1624", iso: "IM" },
    { country: "Israel", code: "972", iso: "IL" },
    { country: "Italy", code: "39", iso: "IT" },
    { country: "Ivory Coast", code: "225", iso: "CI" },
    { country: "Jamaica", code: "1-876", iso: "JM" },
    { country: "Japan", code: "81", iso: "JP" },
    { country: "Jersey", code: "44-1534", iso: "JE" },
    { country: "Jordan", code: "962", iso: "JO" },
    { country: "Kazakhstan", code: "7", iso: "KZ" },
    { country: "Kenya", code: "254", iso: "KE" },
    { country: "Kiribati", code: "686", iso: "KI" },
    { country: "Kosovo", code: "383", iso: "XK" },
    { country: "Kuwait", code: "965", iso: "KW" },
    { country: "Kyrgyzstan", code: "996", iso: "KG" },
    { country: "Laos", code: "856", iso: "LA" },
    { country: "Latvia", code: "371", iso: "LV" },
    { country: "Lebanon", code: "961", iso: "LB" },
    { country: "Lesotho", code: "266", iso: "LS" },
    { country: "Liberia", code: "231", iso: "LR" },
    { country: "Libya", code: "218", iso: "LY" },
    { country: "Liechtenstein", code: "423", iso: "LI" },
    { country: "Lithuania", code: "370", iso: "LT" },
    { country: "Luxembourg", code: "352", iso: "LU" },
    { country: "Macao", code: "853", iso: "MO" },
    { country: "Macedonia", code: "389", iso: "MK" },
    { country: "Madagascar", code: "261", iso: "MG" },
    { country: "Malawi", code: "265", iso: "MW" },
    { country: "Malaysia", code: "60", iso: "MY" },
    { country: "Maldives", code: "960", iso: "MV" },
    { country: "Mali", code: "223", iso: "ML" },
    { country: "Malta", code: "356", iso: "MT" },
    { country: "Marshall Islands", code: "692", iso: "MH" },
    { country: "Mauritania", code: "222", iso: "MR" },
    { country: "Mauritius", code: "230", iso: "MU" },
    { country: "Mayotte", code: "262", iso: "YT" },
    { country: "Mexico", code: "52", iso: "MX" },
    { country: "Micronesia", code: "691", iso: "FM" },
    { country: "Moldova", code: "373", iso: "MD" },
    { country: "Monaco", code: "377", iso: "MC" },
    { country: "Mongolia", code: "976", iso: "MN" },
    { country: "Montenegro", code: "382", iso: "ME" },
    { country: "Montserrat", code: "1-664", iso: "MS" },
    { country: "Morocco", code: "212", iso: "MA" },
    { country: "Mozambique", code: "258", iso: "MZ" },
    { country: "Myanmar", code: "95", iso: "MM" },
    { country: "Namibia", code: "264", iso: "NA" },
    { country: "Nauru", code: "674", iso: "NR" },
    { country: "Nepal", code: "977", iso: "NP" },
    { country: "Netherlands", code: "31", iso: "NL" },
    { country: "Netherlands Antilles", code: "599", iso: "AN" },
    { country: "New Caledonia", code: "687", iso: "NC" },
    { country: "New Zealand", code: "64", iso: "NZ" },
    { country: "Nicaragua", code: "505", iso: "NI" },
    { country: "Niger", code: "227", iso: "NE" },
    { country: "Nigeria", code: "234", iso: "NG" },
    { country: "Niue", code: "683", iso: "NU" },
    { country: "North Korea", code: "850", iso: "KP" },
    { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
    { country: "Norway", code: "47", iso: "NO" },
    { country: "Oman", code: "968", iso: "OM" },
    { country: "Pakistan", code: "92", iso: "PK" },
    { country: "Palau", code: "680", iso: "PW" },
    { country: "Palestine", code: "970", iso: "PS" },
    { country: "Panama", code: "507", iso: "PA" },
    { country: "Papua New Guinea", code: "675", iso: "PG" },
    { country: "Paraguay", code: "595", iso: "PY" },
    { country: "Peru", code: "51", iso: "PE" },
    { country: "Philippines", code: "63", iso: "PH" },
    { country: "Pitcairn", code: "64", iso: "PN" },
    { country: "Poland", code: "48", iso: "PL" },
    { country: "Portugal", code: "351", iso: "PT" },
    { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
    { country: "Qatar", code: "974", iso: "QA" },
    { country: "Republic of the Congo", code: "242", iso: "CG" },
    { country: "Reunion", code: "262", iso: "RE" },
    { country: "Romania", code: "40", iso: "RO" },
    { country: "Russia", code: "7", iso: "RU" },
    { country: "Rwanda", code: "250", iso: "RW" },
    { country: "Saint Barthelemy", code: "590", iso: "BL" },
    { country: "Saint Helena", code: "290", iso: "SH" },
    { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
    { country: "Saint Lucia", code: "1-758", iso: "LC" },
    { country: "Saint Martin", code: "590", iso: "MF" },
    { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
    { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
    { country: "Samoa", code: "685", iso: "WS" },
    { country: "San Marino", code: "378", iso: "SM" },
    { country: "Sao Tome and Principe", code: "239", iso: "ST" },
    { country: "Saudi Arabia", code: "966", iso: "SA" },
    { country: "Senegal", code: "221", iso: "SN" },
    { country: "Serbia", code: "381", iso: "RS" },
    { country: "Seychelles", code: "248", iso: "SC" },
    { country: "Sierra Leone", code: "232", iso: "SL" },
    { country: "Singapore", code: "65", iso: "SG" },
    { country: "Sint Maarten", code: "1-721", iso: "SX" },
    { country: "Slovakia", code: "421", iso: "SK" },
    { country: "Slovenia", code: "386", iso: "SI" },
    { country: "Solomon Islands", code: "677", iso: "SB" },
    { country: "Somalia", code: "252", iso: "SO" },
    { country: "South Africa", code: "27", iso: "ZA" },
    { country: "South Korea", code: "82", iso: "KR" },
    { country: "South Sudan", code: "211", iso: "SS" },
    { country: "Spain", code: "34", iso: "ES" },
    { country: "Sri Lanka", code: "94", iso: "LK" },
    { country: "Sudan", code: "249", iso: "SD" },
    { country: "Suriname", code: "597", iso: "SR" },
    { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
    { country: "Swaziland", code: "268", iso: "SZ" },
    { country: "Sweden", code: "46", iso: "SE" },
    { country: "Switzerland", code: "41", iso: "CH" },
    { country: "Syria", code: "963", iso: "SY" },
    { country: "Taiwan", code: "886", iso: "TW" },
    { country: "Tajikistan", code: "992", iso: "TJ" },
    { country: "Tanzania", code: "255", iso: "TZ" },
    { country: "Thailand", code: "66", iso: "TH" },
    { country: "Togo", code: "228", iso: "TG" },
    { country: "Tokelau", code: "690", iso: "TK" },
    { country: "Tonga", code: "676", iso: "TO" },
    { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
    { country: "Tunisia", code: "216", iso: "TN" },
    { country: "Turkey", code: "90", iso: "TR" },
    { country: "Turkmenistan", code: "993", iso: "TM" },
    { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
    { country: "Tuvalu", code: "688", iso: "TV" },
    { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
    { country: "Uganda", code: "256", iso: "UG" },
    { country: "Ukraine", code: "380", iso: "UA" },
    { country: "United Arab Emirates", code: "971", iso: "AE" },
    { country: "United Kingdom", code: "44", iso: "GB" },
    { country: "United States", code: "1", iso: "US" },
    { country: "Uruguay", code: "598", iso: "UY" },
    { country: "Uzbekistan", code: "998", iso: "UZ" },
    { country: "Vanuatu", code: "678", iso: "VU" },
    { country: "Vatican", code: "379", iso: "VA" },
    { country: "Venezuela", code: "58", iso: "VE" },
    { country: "Vietnam", code: "84", iso: "VN" },
    { country: "Wallis and Futuna", code: "681", iso: "WF" },
    { country: "Western Sahara", code: "212", iso: "EH" },
    { country: "Yemen", code: "967", iso: "YE" },
    { country: "Zambia", code: "260", iso: "ZM" },
    { country: "Zimbabwe", code: "263", iso: "ZW" },
  ];
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customerData, setCustomerData] = useState({
    name: "",
    areaCode: "",
    phone: "",
    email: "",
    customerNote: "",
    customerType: 0,
    companyName: "",
    billingAddress: "",
    taxNo: "",
    taxHolder: "",
    mission: "",
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) {
      return;
    }
    setLoading(true);
    GetCustomers();
  }, [ddSelectedRestaurant?.id]);

  function GetCustomers() {
    axios.get(`clientAIO/customers/${ddSelectedRestaurant.id}`).then((x) => {
      setCustomers(x.data);
      setLoading(false);
    });
  }

  function PostCustomer() {
    axios
      .post("clientAIO/customer", {
        ...customerData,
        restaurantId: ddSelectedRestaurant.id,
        areaCode: selectedAreaCode.code,
      })
      .then((x) => {
        setLoading(false);
        setaddCustomerDlg(false);
        setCustomerData({
          name: "",
          areaCode: "",
          phone: "",
          email: "",
          customerNote: "",
          customerType: 0,
          companyName: "",
          billingAddress: "",
          taxNo: "",
          taxHolder: "",
          mission: "",
        });
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Müşteri başarıyla eklendi",
          life: 3000,
        });
        GetCustomers();
      })
      .catch((x) => {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Müşteri zaten mevcut!",
          life: 3000,
        });
        setLoading(false);
      });
  }
  function GetCustomerDetails(x) {
    setLoading(true);
    axios.get(`clientAIO/reservations/customer/${x.id}`).then((x) => {
      setLoading(false);
      setCustomerReservations(x.data);
      setCustomerReservationsDlg(true);
    });
  }

  function PutCustomer() {
    axios
      .put("clientAIO/customer", selectedCustomer)
      .then((x) => {
        setLoading(false);
        setEditCustomerDlg(false);
        setSelectedCustomer({});
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Müşteri başarıyla güncellendi",
          life: 3000,
        });
        GetCustomers();
      })
      .catch((x) => {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Müşteri zaten mevcut!",
          life: 3000,
        });
        setLoading(false);
      });
  }

  function AddCustomerDlgTemp() {
    return (
      <Dialog
        visible={addCustomerDlg}
        className="col-12 lg:col-8 md:col-10 sm:col-11 m-0 p-0"
        header={t("yeniMusteriEkle")}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onHide={() => setaddCustomerDlg(false)}
      >
        <>
          <div className="flex">
            <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="name"
                      value={customerData.name}
                      onChange={(e) => {
                        const nameValue = e.target.value;

                        // E-posta adresi 50 karakterden uzun olmamalı ve @ karakterini içermeli
                        if (nameValue.length <= 50) {
                          setCustomerData({
                            ...customerData,
                            name: nameValue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="name">{t("musAdSoyad")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="mail"
                      value={customerData.email}
                      onChange={(e) => {
                        const emailValue = e.target.value;

                        // E-posta adresi 75 karakterden uzun olmamalı ve @ karakterini içermeli
                        if (emailValue.length <= 75) {
                          setCustomerData({
                            ...customerData,
                            email: emailValue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="mail">{t("mail")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <FloatLabel>
                    <Dropdown
                      id="areaCode"
                      value={selectedAreaCode}
                      style={{ borderRadius: 3 }}
                      onChange={(e) => setSelectedAreaCode(e.value)}
                      valueTemplate={(x) => x.country + " +" + x.code}
                      itemTemplate={(x) => x.country + " +" + x.code}
                      options={areaCodes}
                      optionLabel="country"
                      placeholder="Select a Areacode"
                    />
                    <label for="areaCode">{t("alanKodu")}</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText
                      id="telNo"
                      value={customerData.phone}
                      onChange={(e) => {
                        const phoneValue = e.target.value;
                        // Yalnızca rakamları al (istenirse farklı karakterlere de izin verilebilir)
                        const sanitizedPhone = phoneValue.replace(/\D/g, "");

                        // Eğer telefon numarası 10 haneden fazla ise, fazlalığı kes
                        if (sanitizedPhone.length <= 10) {
                          setCustomerData({
                            ...customerData,
                            phone: sanitizedPhone,
                          });
                        }
                      }}
                    />
                    <label htmlFor="telNo">{t("telefon")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="customerNote"
                      value={customerData.customerNote}
                      onChange={(e) => {
                        const noteValue = e.target.value;

                        // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                        if (noteValue.length <= 250) {
                          setCustomerData({
                            ...customerData,
                            customerNote: noteValue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="customerNote">{t("musteriNotu")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="companyName"
                      value={customerData.companyName}
                      onChange={(e) => {
                        const companynamevalue = e.target.value;

                        // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                        if (companynamevalue.length <= 50) {
                          setCustomerData({
                            ...customerData,
                            companyName: companynamevalue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="companyName">{t("sirketAdi")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLocationPin} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="billAddress"
                      value={customerData.billingAddress}
                      onChange={(e) => {
                        const bilingadresvalue = e.target.value;

                        // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                        if (bilingadresvalue.length <= 250) {
                          setCustomerData({
                            ...customerData,
                            billingAddress: bilingadresvalue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="billAddress">{t("faturaAdresi")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 500px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBank} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="taxNo"
                      value={customerData.taxNo}
                      onChange={(e) => {
                        const taxValue = e.target.value;

                        // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                        if (taxValue.length <= 25) {
                          setCustomerData({
                            ...customerData,
                            taxNo: taxValue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="taxNo">{t("vergiNo")}</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText
                      id="taxAdministration"
                      value={customerData.taxHolder}
                      onChange={(e) =>
                        setCustomerData({
                          ...customerData,
                          taxHolder: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="taxAdministration">
                      {t("vergiDairesi")}
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBriefcase} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="mission"
                      value={customerData.mission}
                      onChange={(e) => {
                        const missionValue = e.target.value;

                        // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                        if (missionValue.length <= 25) {
                          setCustomerData({
                            ...customerData,
                            mission: missionValue,
                          });
                        }
                      }}
                    />
                    <label htmlFor="mission"> {t("gorevi")}</label>
                  </FloatLabel>
                </div>
              </div>

              <div className="col-12 flex justify-content-center">
                <Button
                  loading={loading}
                  label={t("kaydet")}
                  severity="success"
                  icon={
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: "10px" }}
                    />
                  }
                  className="shadow-4"
                  style={{ fontSize: 18, fontWeight: 600 }}
                  onClick={() => PostCustomer() & setLoading(true)}
                />
              </div>
            </div>

            <div className="flex flex-column" style={{ fontWeight: 600 }}>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 1
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 1 })
                }
              >
                <FontAwesomeIcon
                  icon={faWallet}
                  width={50}
                  color={customerData.customerType === 1 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("bonkor")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 2
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 2 })
                }
              >
                <FontAwesomeIcon
                  icon={faCrown}
                  width={50}
                  color={customerData.customerType === 2 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("vip")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 3
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 3 })
                }
              >
                <FontAwesomeIcon
                  icon={faRepeat}
                  width={50}
                  color={customerData.customerType === 3 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("devamli")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 4
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 4 })
                }
              >
                <FontAwesomeIcon
                  icon={faWheelchair}
                  width={50}
                  color={customerData.customerType === 4 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("engelli")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 5
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 5 })
                }
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  width={50}
                  color={customerData.customerType === 5 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("kurumsal")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 6
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 6 })
                }
              >
                <FontAwesomeIcon
                  icon={faAddressCard}
                  width={50}
                  color={customerData.customerType === 6 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("tanidik")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 7
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 7 })
                }
              >
                <FontAwesomeIcon
                  icon={faStar}
                  width={50}
                  color={customerData.customerType === 7 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("unlu")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  customerData.customerType === 8
                    ? setCustomerData({ ...customerData, customerType: 0 })
                    : setCustomerData({ ...customerData, customerType: 8 })
                }
              >
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  width={50}
                  color={customerData.customerType === 8 ? "#F39237" : "black"}
                  size="2x"
                  className="m-2"
                />
                <label>{t("sorunlu")}</label>
              </div>
            </div>
          </div>
        </>
      </Dialog>
    );
  }

  function EditCustomerDlgTemp() {
    return (
      <Dialog
        visible={editCustomerDlg}
        className="col-12 lg:col-8 md:col-10 sm:col-11 m-0 p-0"
        header={t("musteriGuncelle")}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onHide={() => setEditCustomerDlg(false)}
      >
        <>
          <div className="flex">
            <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="name"
                      value={selectedCustomer.name}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          name: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="name">{t("musAdSoyad")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="mail"
                      value={selectedCustomer.email}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          email: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="mail">{t("mail")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <FloatLabel>
                    <Dropdown
                      id="areaCode"
                      value={selectedAreaCode}
                      style={{ borderRadius: 3 }}
                      onChange={(e) => {
                        setSelectedAreaCode(e.value);
                        setSelectedCustomer({
                          ...selectedCustomer,
                          areaCode: e.value.code,
                        });
                      }}
                      valueTemplate={(x) => x.country + " +" + x.code}
                      itemTemplate={(x) => x.country + " +" + x.code}
                      options={areaCodes}
                      optionLabel="country"
                      placeholder="Select a Areacode"
                    />
                    <label for="areaCode">{t("alanKodu")}</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText
                      id="telNo"
                      value={selectedCustomer.phone}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          phone: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="telNo">{t("telefon")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="customerNote"
                      value={selectedCustomer.customerNote}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          customerNote: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="customerNote">{t("musteriNotu")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="companyName"
                      value={selectedCustomer.companyName}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          companyName: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="companyName">{t("sirketAdi")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLocationPin} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="billAddress"
                      value={selectedCustomer.billingAddress}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          billingAddress: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="billAddress">{t("faturaAdresi")}</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 500px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBank} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="taxNo"
                      value={selectedCustomer.taxNo}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          taxNo: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="taxNo">{t("vergiNo")}</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText
                      id="taxAdministration"
                      value={selectedCustomer.taxHolder}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          taxHolder: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="taxAdministration">
                      {" "}
                      {t("vergiDairesi")}
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBriefcase} />
                  </span>
                  <FloatLabel>
                    <InputText
                      id="mission"
                      value={selectedCustomer.mission}
                      onChange={(e) =>
                        setSelectedCustomer({
                          ...selectedCustomer,
                          mission: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="mission">{t("gorevi")}</label>
                  </FloatLabel>
                </div>
              </div>

              <div className="col-12 flex justify-content-center">
                <Button
                  loading={loading}
                  label="Güncelle"
                  severity="success"
                  icon={
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: "10px" }}
                    />
                  }
                  className="shadow-4"
                  style={{ fontSize: 18, fontWeight: 600 }}
                  onClick={() => PutCustomer() & setLoading(true)}
                />
              </div>
            </div>

            <div className="flex flex-column" style={{ fontWeight: 600 }}>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 1
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 1,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faWallet}
                  width={50}
                  color={
                    selectedCustomer.customerType === 1 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("bonkor")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 2
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 2,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faCrown}
                  width={50}
                  color={
                    selectedCustomer.customerType === 2 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("vip")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 3
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 3,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faRepeat}
                  width={50}
                  color={
                    selectedCustomer.customerType === 3 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("devamli")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 4
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 4,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faWheelchair}
                  width={50}
                  color={
                    selectedCustomer.customerType === 4 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("engelli")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 5
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 5,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  width={50}
                  color={
                    selectedCustomer.customerType === 5 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("kurumsal")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 6
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 6,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faAddressCard}
                  width={50}
                  color={
                    selectedCustomer.customerType === 6 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("tanidik")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 7
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 7,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faStar}
                  width={50}
                  color={
                    selectedCustomer.customerType === 7 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("unlu")}</label>
              </div>
              <div
                className="flex cursor-pointer select-none"
                style={{ alignItems: "center" }}
                onClick={() =>
                  selectedCustomer.customerType === 8
                    ? setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 0,
                      })
                    : setSelectedCustomer({
                        ...selectedCustomer,
                        customerType: 8,
                      })
                }
              >
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  width={50}
                  color={
                    selectedCustomer.customerType === 8 ? "#F39237" : "black"
                  }
                  size="2x"
                  className="m-2"
                />
                <label>{t("sorunlu")}</label>
              </div>
            </div>
          </div>
        </>
      </Dialog>
    );
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div
        className="flex  flex-wrap gap-2"
        style={{
          justifyContent: windowWidth > 500 ? "space-between" : "center",
        }}
      >
        <Button
          loading={loading}
          label={t("yeniMusteriEkle")}
          severity="primary"
          icon={
            <FontAwesomeIcon icon={faAdd} style={{ marginRight: "10px" }} />
          }
          className="shadow-4"
          style={{ fontSize: 18, fontWeight: 600 }}
          onClick={() => setaddCustomerDlg(true)}
        />
        <InputText
          value={globalFilterValue}
          style={{ width: "300px" }}
          onChange={onGlobalFilterChange}
          placeholder={t("ara")}
        />
      </div>
    );
  };
  const header = renderHeader();
  return (
    <>
      <Toast ref={toast} />
      {AddCustomerDlgTemp()}
      {EditCustomerDlgTemp()}
      {windowWidth > 500 && (
        <DataTable
          value={customers}
          stripedRows
          paginator
          rows={30}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          contentStyle={{
            textAlign: "center",
            justifyContent: "center",
            alignSelf: "center",
          }}
          style={{ fontWeight: 600 }}
          emptyMessage={t("musteriBulunamadi")}
        >
          <Column
            field="name"
            header={t("musAdSoyad")}
            sortable
            alignHeader={"center"}
            align={"center"}
          />
          <Column
            field="phone"
            header={t("telefon")}
            body={(x) => x.areaCode + " - " + x.phone}
            sortable
            alignHeader={"center"}
            align={"center"}
          />
          <Column
            field="email"
            header={t("mail")}
            sortable
            alignHeader={"center"}
            align={"center"}
          />
          <Column
            body={(x) => (
              <>
                <Button
                  rounded
                  loading={loading}
                  severity="primary"
                  icon={<FontAwesomeIcon icon={faInfo} />}
                  className="shadow-4"
                  style={{ background: "orange", marginRight: "10px" }}
                  align={"center"}
                  onClick={() => {
                    setSelectedCustomer(x);
                    GetCustomerDetails(x);
                    setCustomerReservationsDlg(true);
                  }}
                />
                <Button
                  rounded
                  loading={loading}
                  severity="primary"
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  className="shadow-4"
                  style={{ background: "green" }}
                  align={"center"}
                  onClick={() => {
                    setSelectedCustomer(x);
                    setEditCustomerDlg(true);
                  }}
                />
              </>
            )}
          />
        </DataTable>
      )}
      {windowWidth <= 500 && (
        <DataTable
          value={customers}
          stripedRows
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          contentStyle={{
            textAlign: "center",
            justifyContent: "center",
            alignSelf: "center",
          }}
          style={{ fontWeight: 600 }}
          emptyMessage={t("musteriBulunamadi")}
          globalFilterFields={["name", "phone", "email"]}
        >
          <Column
            field="name"
            header={t("musAdSoyad")}
            sortable
            alignHeader={"center"}
            align={"center"}
            body={(x) => (
              <div className="text-center">
                <div className="font-bold">{x.name}</div>

                <div className="mt-2 font-semibold">
                  {x.areaCode} - {x.phone}
                </div>
                <div className="mt-2 font-semibold">{x.email}</div>
                <>
                  <Button
                    rounded
                    loading={loading}
                    severity="primary"
                    icon={<FontAwesomeIcon icon={faInfo} />}
                    className="shadow-4"
                    style={{
                      background: "orange",
                      marginRight: "10px",
                      marginTop: "30px",
                    }}
                    align={"center"}
                    onClick={() => {
                      setSelectedCustomer(x);
                      GetCustomerDetails(x);
                      setCustomerReservationsDlg(true);
                    }}
                  />
                  <Button
                    rounded
                    loading={loading}
                    severity="primary"
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    className="shadow-4"
                    style={{ background: "green" }}
                    align={"center"}
                    onClick={() => {
                      setSelectedCustomer(x);
                      setEditCustomerDlg(true);
                    }}
                  />
                </>
              </div>
            )}
          />
        </DataTable>
      )}

      <Dialog
        className="col-12 lg:col-10 xl:col-10 p-0 m-0"
        visible={customerReservationsDlg}
        header={t("musteriDetaylari")}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ededed",
        }}
        headerStyle={{ backgroundColor: "#ededed" }}
        onHide={() => {
          setCustomerReservationsDlg(false);
        }}
      >
        {windowWidth > 500 && (
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="flex flex-column" style={{ height: "100%" }}>
              <div
                style={{ fontSize: "40px", fontWeight: 600, color: "orange" }}
              >
                {selectedCustomer?.name}
              </div>
              <div style={{ fontSize: "20px", fontWeight: 500 }}>
                {selectedCustomer?.email}
              </div>

              <div style={{ fontSize: "20px", fontWeight: 400 }}>
                {selectedCustomer?.areaCode} {selectedCustomer?.phone}{" "}
              </div>
            </div>
            <DataTable
              value={customerReservations}
              rows={30}
              dataKey="id"
              style={{ width: "75%" }}
              loading={loading}
              emptyMessage={t("musteriBulunamadi")}
            >
              <Column
                field="date"
                style={{ textAlign: "center" }}
                alignHeader={"center"}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                  borderTopLeftRadius: 25,
                  borderBottomLeftRadius: 5,
                }}
                body={(rowData) => {
                  const formattedDate = moment(rowData.date).format(
                    "DD.MM.YYYY"
                  );
                  const formattedTime = moment(rowData.time, "HH:mm").format(
                    "HH:mm"
                  );
                  const formattedCDate = moment(rowData.createdDate).format(
                    "DD.MM.YYYY - HH:mm"
                  );
                  return (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ fontSize: "16px", fontWeight: 800 }}>
                        {formattedDate} - {formattedTime}
                      </div>
                      <div style={{ fontSize: "17px", fontWeight: 500 }}>
                        {" "}
                        {t("olusturulma")} {formattedCDate}
                      </div>
                    </div>
                  );
                }}
                header={t("rezTarihi")}
              />
              <Column
                field="guestCount"
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: 800,
                  color: "orange",
                }}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                }}
                alignHeader={"center"}
                header={t("kisi")}
              />
              <Column
                field="customerNote"
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: 500,
                }}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                }}
                alignHeader={"center"}
                header={t("musteriNotu")}
              />
              <Column
                field="notes"
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: 500,
                }}
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 400,
                }}
                alignHeader={"center"}
                header={t("restoranNotu")}
              />
            </DataTable>
          </div>
        )}
        {windowWidth <= 500 && (
          <div
            style={{
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="flex flex-column"
              style={{
                height: "100%",
                alignItems: "center",
                paddingBottom: "30px",
              }}
            >
              <div
                style={{ fontSize: "40px", fontWeight: 600, color: "orange" }}
              >
                {selectedCustomer?.name}
              </div>
              <div style={{ fontSize: "20px", fontWeight: 500 }}>
                {selectedCustomer?.email}
              </div>

              <div style={{ fontSize: "20px", fontWeight: 400 }}>
                {selectedCustomer?.areaCode} {selectedCustomer?.phone}{" "}
              </div>
            </div>
            <DataTable
              value={customerReservations}
              rows={30}
              dataKey="id"
              paginator
              style={{ width: "100%" }}
              stripedRows
              loading={loading}
              emptyMessage={t("musteriBulunamadi")}
            >
              <Column
                field="date"
                headerStyle={{
                  backgroundColor: "#222d33",
                  color: "white",
                  fontWeight: 600,
                }}
                alignHeader={"center"}
                body={(rowData) => {
                  const formattedDate = moment(rowData.date).format(
                    "DD.MM.YYYY"
                  );
                  const formattedTime = moment(rowData.time, "HH:mm").format(
                    "HH:mm"
                  );
                  const formattedCDate = moment(rowData.createdDate).format(
                    "DD.MM.YYYY - HH:mm"
                  );
                  return (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ fontSize: "16px", fontWeight: 800 }}>
                        {formattedDate} - {formattedTime}
                      </div>
                      <div style={{ fontSize: "17px", fontWeight: 500 }}>
                        {" "}
                        {t("olusturulma")} {formattedCDate}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          fontWeight: 800,
                          color: "orange",
                        }}
                      >
                        {rowData.guestCount + " "}
                        {t("kisi")}
                      </div>
                      <div>{rowData.customerNote}</div>
                      <div>{rowData.notes}</div>
                    </div>
                  );
                }}
              />
            </DataTable>
          </div>
        )}
      </Dialog>
    </>
  );
}

export default Customers;
