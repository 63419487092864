import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./i18n.js";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "primereact/resources/themes/mdc-light-indigo/theme.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";

import Login from "./pages/Login";
import SidebarPro from "./components/SidebarPro";
import SiderbarProAdmin from "./components/SiderbarProAdmin";
import Restaurants from "./pages/Restaurant";
import Reservations from "./pages/Reservations";
import AddReservation from "./pages/AddReservation";
import Companies from "./pages/Companies";
import HomeAdmin from "./pages/HomeAdmin";
import Users from "./pages/Users";
import TimeManagement from "./pages/TimeManagement";

import RestaurantSettings from "./pages/RestaurantSettings";

import Restaurant from "./pages/Restaurant";
import Customers from "./pages/Customers";
import IFrameReservation from "./pages/IFrameReservation";
import ReservationList from "./pages/ReservationList";
import ReservationCancel from "./pages/ReservationCancel";
import ReservationReports from "./pages/ReservationReports.js";

//axios.defaults.baseURL = "https://localhost:7100/api/";
axios.defaults.baseURL = "https://api.reserone.com/api/";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/management",
    element: <SiderbarProAdmin />,
    children: [
      {
        path: "/management",
        element: <HomeAdmin />,
      },
      {
        path: "/management/companies",
        element: <Companies />,
      },
      {
        path: "/management/restaurants",
        element: <Restaurants />,
      },
      {
        path: "/management/users",
        element: <Users />,
      },
      {
        path: "/management/restaurants",
        element: <Restaurant />,
      },
    ],
  },
  {
    path: "/client",
    element: <SidebarPro />,
    children: [
      {
        path: "/client/addreservations",
        element: <AddReservation />,
      },
      {
        path: "/client/reservations",
        element: <Reservations />,
      },
      {
        path: "/client/timemanagement",
        element: <TimeManagement />,
      },
      {
        path: "/client/restaurantsettings",
        element: <RestaurantSettings />,
      },
      {
        path: "/client/customers",
        element: <Customers />,
      },
      {
        path: "/client/reservationReports",
        element: <ReservationReports />,
      },
      {
        path: "/client",
        element: <ReservationList />,
      },
    ],
  },
  {
    path: "/reservation/:id",
    element: <IFrameReservation />,
  },
  {
    path: "/cancel/:token",
    element: <ReservationCancel />,
  },
]);

const App = () => {
  return (
    <Provider store={store}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
