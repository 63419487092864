import axios from "axios";
import React, { useEffect, useState } from "react";

function HomeAdmin() {
  const [report, setReport] = useState({});

  useEffect(() => {
    axios.get("adminAIO/home").then((x) => setReport(x.data));
  }, []);

  return (
    <div className="w-full h-full" style={{ background: "#f5f5f5" }}>
      <div className="grid m-0">
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-4 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-600 font-medium mb-3">
                  Aktif Restoran Sayısı
                </span>
                <div className="text-900 font-medium text-xl">
                  {report?.restaurantCount}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-4 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-600 font-medium mb-3">
                  Aylık Tamamlanan Rezervasyon
                </span>
                <div className="text-900 font-medium text-xl">
                  {report?.thisMonthReservationCount}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-4 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-600 font-medium mb-3">
                  Toplam Tamamlanan Rezervasyon
                </span>
                <div className="text-900 font-medium text-xl">
                  {report?.reservationCount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAdmin;
