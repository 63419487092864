import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { FloatLabel } from "primereact/floatlabel";

import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

import { userInfoCRUD } from "../redux/counter";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";

function Login() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ email: "", password: "" });

  function PostLogin() {
    const hashedPassword = CryptoJS.SHA256(userData.password).toString(
      CryptoJS.enc.Hex
    );

    axios
      .post("login", {
        email: userData.email,
        password: hashedPassword,
      })
      .then((x) => {
        /* const decoded = jwtDecode(x.data.token);
        const roleClaim =
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
        const role = decoded[roleClaim];*/

        dispatch(
          userInfoCRUD({
            token: x.data.token,
            email: x.data.email,
            lastname: x.data.lastname,
            name: x.data.name,
            userId: x.data.userId,
            userLevel: x.data.userLevel,
          })
        );
        localStorage.setItem("token", x.data.token);

        if (x.data.userLevel === 1) {
          navigate("/management");
        }
        if (x.data.userLevel === 2) {
          navigate("/client");
        }
      })
      .catch((x) => {
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: "Giriş bilgileri hatalı!",
          life: 3000,
        });
        setLoading(false);
      });
  }

  return (
    <>
      <Toast ref={toast} />
      <div
        className="flex justify-content-center align-items-center flex-1 h-full"
        style={{ background: "#f9f9f9" }}
      >
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">RESERONE</div>
          </div>

          <div className="flex flex-column gap-3">
            <FloatLabel>
              <InputText
                id="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
                type="text"
                className="w-full"
              />
              <label htmlFor="email">Mail Adresi</label>
            </FloatLabel>

            <FloatLabel>
              <InputText
                id="password"
                type="password"
                value={userData.password}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
                className="w-full"
              />
              <label htmlFor="password">Şifre</label>
            </FloatLabel>

            <Button
              label="Giriş Yap"
              onClick={() => PostLogin() & setLoading(true)}
              loading={loading}
              className="w-full"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
