import React, { useEffect, useState, useRef } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from "primereact/toast";
import CryptoJS from "crypto-js";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { addLocale } from "primereact/api";
import {
  faBars,
  faChevronDown,
  faGear,
  faList,
  faUser,
  faPhone,
  faEnvelope,
  faCalendar,
  faUsers,
  faPencil,
  faCheck,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock,
  faSquareCheck,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import {
  userInfoCRUD,
  ddRestaurantsCRUD,
  ddSelectedRestaurantCRUD,
} from "../redux/counter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import { Calendar } from "primereact/calendar";
import { Menu as PrimeMenu } from "primereact/menu";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { RadioButton } from "primereact/radiobutton";

function SidebarPro() {
  const toast = useRef(null);
  const toastNewRes = useRef(null);

  const { t, i18n } = useTranslation();
  const logoutMenu = useRef(null);
  const [loading, setLoading] = useState(false);
  const { userInfo, ddRestaurants, ddSelectedRestaurant } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarActive, setSidebarActive] = useState(true);

  const [addReservationDlg, setAddReservationDlg] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const [restaurant, setRestaurant] = useState({});
  const [selectedMeal, setSelectedMeal] = useState({});
  const [activeHours, setActiveHours] = useState([]);
  const [reservationData, setReservationData] = useState({
    date: new Date(),
    time: "",
    notes: "",
    guestCount: 1,
    name: "",
    phone: "",
    customerNote: "",
    email: "",
    lang: "tr",
  });
  const [selectedAreaCode, setSelectedAreaCode] = useState({
    country: "Turkey",
    code: "90",
    iso: "TR",
  });

  const [waitingAuth, setWaitingAuth] = useState(true);

  const [changePwDlg, setChangePwDlg] = useState(false);
  const [changePwData, setChangePwData] = useState({ oldPw: "", newPw: "" });
  const [errorOldPw, setErrorOldPw] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentTab, setCurrentTab] = useState(0);

  const collapsed = windowWidth < 500 ? true : false;

  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const personCountOptions = Array.from({ length: 100 }, (v, i) => ({
    label: `${i + 1} Kişi`,
    value: i + 1,
  }));

  const areaCodes = [
    { country: "Afghanistan", code: "93", iso: "AF" },
    { country: "Albania", code: "355", iso: "AL" },
    { country: "Algeria", code: "213", iso: "DZ" },
    { country: "American Samoa", code: "1-684", iso: "AS" },
    { country: "Andorra", code: "376", iso: "AD" },
    { country: "Angola", code: "244", iso: "AO" },
    { country: "Anguilla", code: "1-264", iso: "AI" },
    { country: "Antarctica", code: "672", iso: "AQ" },
    { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
    { country: "Argentina", code: "54", iso: "AR" },
    { country: "Armenia", code: "374", iso: "AM" },
    { country: "Aruba", code: "297", iso: "AW" },
    { country: "Australia", code: "61", iso: "AU" },
    { country: "Austria", code: "43", iso: "AT" },
    { country: "Azerbaijan", code: "994", iso: "AZ" },
    { country: "Bahamas", code: "1-242", iso: "BS" },
    { country: "Bahrain", code: "973", iso: "BH" },
    { country: "Bangladesh", code: "880", iso: "BD" },
    { country: "Barbados", code: "1-246", iso: "BB" },
    { country: "Belarus", code: "375", iso: "BY" },
    { country: "Belgium", code: "32", iso: "BE" },
    { country: "Belize", code: "501", iso: "BZ" },
    { country: "Benin", code: "229", iso: "BJ" },
    { country: "Bermuda", code: "1-441", iso: "BM" },
    { country: "Bhutan", code: "975", iso: "BT" },
    { country: "Bolivia", code: "591", iso: "BO" },
    { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
    { country: "Botswana", code: "267", iso: "BW" },
    { country: "Brazil", code: "55", iso: "BR" },
    { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
    { country: "British Virgin Islands", code: "1-284", iso: "VG" },
    { country: "Brunei", code: "673", iso: "BN" },
    { country: "Bulgaria", code: "359", iso: "BG" },
    { country: "Burkina Faso", code: "226", iso: "BF" },
    { country: "Burundi", code: "257", iso: "BI" },
    { country: "Cambodia", code: "855", iso: "KH" },
    { country: "Cameroon", code: "237", iso: "CM" },
    { country: "Canada", code: "1", iso: "CA" },
    { country: "Cape Verde", code: "238", iso: "CV" },
    { country: "Cayman Islands", code: "1-345", iso: "KY" },
    { country: "Central African Republic", code: "236", iso: "CF" },
    { country: "Chad", code: "235", iso: "TD" },
    { country: "Chile", code: "56", iso: "CL" },
    { country: "China", code: "86", iso: "CN" },
    { country: "Christmas Island", code: "61", iso: "CX" },
    { country: "Cocos Islands", code: "61", iso: "CC" },
    { country: "Colombia", code: "57", iso: "CO" },
    { country: "Comoros", code: "269", iso: "KM" },
    { country: "Cook Islands", code: "682", iso: "CK" },
    { country: "Costa Rica", code: "506", iso: "CR" },
    { country: "Croatia", code: "385", iso: "HR" },
    { country: "Cuba", code: "53", iso: "CU" },
    { country: "Curacao", code: "599", iso: "CW" },
    { country: "Cyprus", code: "357", iso: "CY" },
    { country: "Czech Republic", code: "420", iso: "CZ" },
    { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
    { country: "Denmark", code: "45", iso: "DK" },
    { country: "Djibouti", code: "253", iso: "DJ" },
    { country: "Dominica", code: "1-767", iso: "DM" },
    { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
    { country: "East Timor", code: "670", iso: "TL" },
    { country: "Ecuador", code: "593", iso: "EC" },
    { country: "Egypt", code: "20", iso: "EG" },
    { country: "El Salvador", code: "503", iso: "SV" },
    { country: "Equatorial Guinea", code: "240", iso: "GQ" },
    { country: "Eritrea", code: "291", iso: "ER" },
    { country: "Estonia", code: "372", iso: "EE" },
    { country: "Ethiopia", code: "251", iso: "ET" },
    { country: "Falkland Islands", code: "500", iso: "FK" },
    { country: "Faroe Islands", code: "298", iso: "FO" },
    { country: "Fiji", code: "679", iso: "FJ" },
    { country: "Finland", code: "358", iso: "FI" },
    { country: "France", code: "33", iso: "FR" },
    { country: "French Polynesia", code: "689", iso: "PF" },
    { country: "Gabon", code: "241", iso: "GA" },
    { country: "Gambia", code: "220", iso: "GM" },
    { country: "Georgia", code: "995", iso: "GE" },
    { country: "Germany", code: "49", iso: "DE" },
    { country: "Ghana", code: "233", iso: "GH" },
    { country: "Gibraltar", code: "350", iso: "GI" },
    { country: "Greece", code: "30", iso: "GR" },
    { country: "Greenland", code: "299", iso: "GL" },
    { country: "Grenada", code: "1-473", iso: "GD" },
    { country: "Guam", code: "1-671", iso: "GU" },
    { country: "Guatemala", code: "502", iso: "GT" },
    { country: "Guernsey", code: "44-1481", iso: "GG" },
    { country: "Guinea", code: "224", iso: "GN" },
    { country: "Guinea-Bissau", code: "245", iso: "GW" },
    { country: "Guyana", code: "592", iso: "GY" },
    { country: "Haiti", code: "509", iso: "HT" },
    { country: "Honduras", code: "504", iso: "HN" },
    { country: "Hong Kong", code: "852", iso: "HK" },
    { country: "Hungary", code: "36", iso: "HU" },
    { country: "Iceland", code: "354", iso: "IS" },
    { country: "India", code: "91", iso: "IN" },
    { country: "Indonesia", code: "62", iso: "ID" },
    { country: "Iran", code: "98", iso: "IR" },
    { country: "Iraq", code: "964", iso: "IQ" },
    { country: "Ireland", code: "353", iso: "IE" },
    { country: "Isle of Man", code: "44-1624", iso: "IM" },
    { country: "Israel", code: "972", iso: "IL" },
    { country: "Italy", code: "39", iso: "IT" },
    { country: "Ivory Coast", code: "225", iso: "CI" },
    { country: "Jamaica", code: "1-876", iso: "JM" },
    { country: "Japan", code: "81", iso: "JP" },
    { country: "Jersey", code: "44-1534", iso: "JE" },
    { country: "Jordan", code: "962", iso: "JO" },
    { country: "Kazakhstan", code: "7", iso: "KZ" },
    { country: "Kenya", code: "254", iso: "KE" },
    { country: "Kiribati", code: "686", iso: "KI" },
    { country: "Kosovo", code: "383", iso: "XK" },
    { country: "Kuwait", code: "965", iso: "KW" },
    { country: "Kyrgyzstan", code: "996", iso: "KG" },
    { country: "Laos", code: "856", iso: "LA" },
    { country: "Latvia", code: "371", iso: "LV" },
    { country: "Lebanon", code: "961", iso: "LB" },
    { country: "Lesotho", code: "266", iso: "LS" },
    { country: "Liberia", code: "231", iso: "LR" },
    { country: "Libya", code: "218", iso: "LY" },
    { country: "Liechtenstein", code: "423", iso: "LI" },
    { country: "Lithuania", code: "370", iso: "LT" },
    { country: "Luxembourg", code: "352", iso: "LU" },
    { country: "Macao", code: "853", iso: "MO" },
    { country: "Macedonia", code: "389", iso: "MK" },
    { country: "Madagascar", code: "261", iso: "MG" },
    { country: "Malawi", code: "265", iso: "MW" },
    { country: "Malaysia", code: "60", iso: "MY" },
    { country: "Maldives", code: "960", iso: "MV" },
    { country: "Mali", code: "223", iso: "ML" },
    { country: "Malta", code: "356", iso: "MT" },
    { country: "Marshall Islands", code: "692", iso: "MH" },
    { country: "Mauritania", code: "222", iso: "MR" },
    { country: "Mauritius", code: "230", iso: "MU" },
    { country: "Mayotte", code: "262", iso: "YT" },
    { country: "Mexico", code: "52", iso: "MX" },
    { country: "Micronesia", code: "691", iso: "FM" },
    { country: "Moldova", code: "373", iso: "MD" },
    { country: "Monaco", code: "377", iso: "MC" },
    { country: "Mongolia", code: "976", iso: "MN" },
    { country: "Montenegro", code: "382", iso: "ME" },
    { country: "Montserrat", code: "1-664", iso: "MS" },
    { country: "Morocco", code: "212", iso: "MA" },
    { country: "Mozambique", code: "258", iso: "MZ" },
    { country: "Myanmar", code: "95", iso: "MM" },
    { country: "Namibia", code: "264", iso: "NA" },
    { country: "Nauru", code: "674", iso: "NR" },
    { country: "Nepal", code: "977", iso: "NP" },
    { country: "Netherlands", code: "31", iso: "NL" },
    { country: "Netherlands Antilles", code: "599", iso: "AN" },
    { country: "New Caledonia", code: "687", iso: "NC" },
    { country: "New Zealand", code: "64", iso: "NZ" },
    { country: "Nicaragua", code: "505", iso: "NI" },
    { country: "Niger", code: "227", iso: "NE" },
    { country: "Nigeria", code: "234", iso: "NG" },
    { country: "Niue", code: "683", iso: "NU" },
    { country: "North Korea", code: "850", iso: "KP" },
    { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
    { country: "Norway", code: "47", iso: "NO" },
    { country: "Oman", code: "968", iso: "OM" },
    { country: "Pakistan", code: "92", iso: "PK" },
    { country: "Palau", code: "680", iso: "PW" },
    { country: "Palestine", code: "970", iso: "PS" },
    { country: "Panama", code: "507", iso: "PA" },
    { country: "Papua New Guinea", code: "675", iso: "PG" },
    { country: "Paraguay", code: "595", iso: "PY" },
    { country: "Peru", code: "51", iso: "PE" },
    { country: "Philippines", code: "63", iso: "PH" },
    { country: "Pitcairn", code: "64", iso: "PN" },
    { country: "Poland", code: "48", iso: "PL" },
    { country: "Portugal", code: "351", iso: "PT" },
    { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
    { country: "Qatar", code: "974", iso: "QA" },
    { country: "Republic of the Congo", code: "242", iso: "CG" },
    { country: "Reunion", code: "262", iso: "RE" },
    { country: "Romania", code: "40", iso: "RO" },
    { country: "Russia", code: "7", iso: "RU" },
    { country: "Rwanda", code: "250", iso: "RW" },
    { country: "Saint Barthelemy", code: "590", iso: "BL" },
    { country: "Saint Helena", code: "290", iso: "SH" },
    { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
    { country: "Saint Lucia", code: "1-758", iso: "LC" },
    { country: "Saint Martin", code: "590", iso: "MF" },
    { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
    { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
    { country: "Samoa", code: "685", iso: "WS" },
    { country: "San Marino", code: "378", iso: "SM" },
    { country: "Sao Tome and Principe", code: "239", iso: "ST" },
    { country: "Saudi Arabia", code: "966", iso: "SA" },
    { country: "Senegal", code: "221", iso: "SN" },
    { country: "Serbia", code: "381", iso: "RS" },
    { country: "Seychelles", code: "248", iso: "SC" },
    { country: "Sierra Leone", code: "232", iso: "SL" },
    { country: "Singapore", code: "65", iso: "SG" },
    { country: "Sint Maarten", code: "1-721", iso: "SX" },
    { country: "Slovakia", code: "421", iso: "SK" },
    { country: "Slovenia", code: "386", iso: "SI" },
    { country: "Solomon Islands", code: "677", iso: "SB" },
    { country: "Somalia", code: "252", iso: "SO" },
    { country: "South Africa", code: "27", iso: "ZA" },
    { country: "South Korea", code: "82", iso: "KR" },
    { country: "South Sudan", code: "211", iso: "SS" },
    { country: "Spain", code: "34", iso: "ES" },
    { country: "Sri Lanka", code: "94", iso: "LK" },
    { country: "Sudan", code: "249", iso: "SD" },
    { country: "Suriname", code: "597", iso: "SR" },
    { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
    { country: "Swaziland", code: "268", iso: "SZ" },
    { country: "Sweden", code: "46", iso: "SE" },
    { country: "Switzerland", code: "41", iso: "CH" },
    { country: "Syria", code: "963", iso: "SY" },
    { country: "Taiwan", code: "886", iso: "TW" },
    { country: "Tajikistan", code: "992", iso: "TJ" },
    { country: "Tanzania", code: "255", iso: "TZ" },
    { country: "Thailand", code: "66", iso: "TH" },
    { country: "Togo", code: "228", iso: "TG" },
    { country: "Tokelau", code: "690", iso: "TK" },
    { country: "Tonga", code: "676", iso: "TO" },
    { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
    { country: "Tunisia", code: "216", iso: "TN" },
    { country: "Turkey", code: "90", iso: "TR" },
    { country: "Turkmenistan", code: "993", iso: "TM" },
    { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
    { country: "Tuvalu", code: "688", iso: "TV" },
    { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
    { country: "Uganda", code: "256", iso: "UG" },
    { country: "Ukraine", code: "380", iso: "UA" },
    { country: "United Arab Emirates", code: "971", iso: "AE" },
    { country: "United Kingdom", code: "44", iso: "GB" },
    { country: "United States", code: "1", iso: "US" },
    { country: "Uruguay", code: "598", iso: "UY" },
    { country: "Uzbekistan", code: "998", iso: "UZ" },
    { country: "Vanuatu", code: "678", iso: "VU" },
    { country: "Vatican", code: "379", iso: "VA" },
    { country: "Venezuela", code: "58", iso: "VE" },
    { country: "Vietnam", code: "84", iso: "VN" },
    { country: "Wallis and Futuna", code: "681", iso: "WF" },
    { country: "Western Sahara", code: "212", iso: "EH" },
    { country: "Yemen", code: "967", iso: "YE" },
    { country: "Zambia", code: "260", iso: "ZM" },
    { country: "Zimbabwe", code: "263", iso: "ZW" },
  ];

  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) return;
    const connection = new HubConnectionBuilder()
      .withUrl("https://api.reserone.com/msghub")
      //.withUrl("https://localhost:7100/msghub")
      .withAutomaticReconnect()
      .build();

    let conId = ddSelectedRestaurant?.id + "";

    connection
      .start()
      .then(() => {
        connection
          .invoke("RegisterTransaction", conId)
          .then(() => {})
          .catch((err) => console.error(err));

        connection.on("ReceiveMessage", (message) => {
          if (message.split("~")[0] === "1") {
            let resStrSplit = message.split("~")[2].split("|");
            toastNewRes.current.show({
              severity: "info",
              style: { marginLeft: 50, background: "mediumspringgreen" },
              life: 60000,
              content: () => (
                <div
                  className="flex flex-column align-items-left"
                  style={{ flex: "1" }}
                >
                  <div className="flex align-items-center gap-2">
                    <span className="font-bold text-900">Yeni Rezervasyon</span>
                  </div>
                  <div className="font-medium text-md text-900">
                    {moment(resStrSplit[2], "DD.MM.YYYY").format("DD.MM.YYYY") +
                      " - " +
                      resStrSplit[3]}
                  </div>
                  <div className="font-medium text-md text-900">
                    {resStrSplit[1]}
                  </div>
                  <div className="font-medium text-md text-900">
                    {resStrSplit[4]}
                  </div>
                  <div className="font-medium text-sm text-700">
                    {resStrSplit[0] === "2" ? t("manuel") : t("cevrimici")}
                  </div>
                </div>
              ),
            });
          }
        });
      })
      .catch((err) => console.error("Bağlantı hatası:", err));

    return () => {
      connection.stop();
    };
  }, [ddSelectedRestaurant?.id]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const restStorage = localStorage.getItem("restaurant");

    if (token !== null && token !== undefined) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const decoded = jwtDecode(token);
      const idClaim =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/serialnumber";

      axios
        .post("login/tokencheck", {
          id: decoded[idClaim],
        })
        .then((x) => {
          localStorage.setItem("token", x.data.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${x.data.token}`;

          dispatch(
            userInfoCRUD({
              token: x.data.token,
              email: x.data.email,
              lastname: x.data.lastname,
              name: x.data.name,
              userId: x.data.userId,
              userLevel: x.data.userLevel,
            })
          );
          dispatch(ddRestaurantsCRUD(x.data.rests));
          if (ddSelectedRestaurant?.id === undefined) {
            if (restStorage !== undefined && restStorage !== null) {
              dispatch(
                ddSelectedRestaurantCRUD(
                  x.data.rests.filter((v) => v.id === parseInt(restStorage))[0]
                )
              );
            } else {
              dispatch(ddSelectedRestaurantCRUD(x.data.rests[0]));
            }
          }
          setWaitingAuth(false);
        })
        .catch((x) => {
          localStorage.removeItem("token");
          localStorage.removeItem("restaurant");
          navigate("/");
        });
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (ddSelectedRestaurant?.id !== undefined) {
      localStorage.setItem("restaurant", ddSelectedRestaurant.id);
      axios.get(`clientAIO/menulist/${ddSelectedRestaurant?.id}`).then((x) => {
        setRestaurant(x.data);
        setSelectedMeal(x.data.mealTimes[0]);
      });
    }
  }, [ddSelectedRestaurant?.id]);

  useEffect(() => {
    if (selectedMeal?.id !== undefined) {
      const timeSlots = [];

      let currentTime = new Date(`1970-01-01T${selectedMeal.startTime}`);
      const end = new Date(`1970-01-01T${selectedMeal.endTime}`);

      while (currentTime <= end) {
        const hours = currentTime.getHours().toString().padStart(2, "0");
        const minutes = currentTime.getMinutes().toString().padStart(2, "0");
        timeSlots.push({
          label: `${hours}:${minutes}`,
          value: `${hours}:${minutes}`,
        });

        currentTime.setMinutes(
          currentTime.getMinutes() + restaurant.reservationRange
        );
      }
      setActiveHours(timeSlots);
    }
  }, [selectedMeal?.id]);

  function PostReservation() {
    setLoading(true);
    axios
      .post("clientAIO/reservation", {
        date: moment(reservationData.date).format("yy-MM-DD"),
        time: reservationData.time + ":00",
        restaurantId: ddSelectedRestaurant.id,
        employeeId: userInfo.userId,
        mealTimeId: selectedMeal.id,
        guestCount: reservationData.guestCount,
        notes: reservationData.notes,
        customerNote: reservationData.customerNote,
        language: reservationData.lang,
        customer: {
          name: reservationData.name,
          areaCode: selectedAreaCode.code,
          phone: reservationData.phone,
          email: reservationData.email,
        },
      })
      .then((x) => {
        setLoading(false);
        setAddReservationDlg(false);
        setReservationData({
          date: new Date(),
          time: "",
          notes: "",
          guestCount: 1,
          name: "",
          areaCode: "",
          phone: "",
          customerNote: "",
          email: "",
          lang: "tr",
        });
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Rezervasyon başarıyla eklendi",
          life: 3000,
        });
      });
  }

  function Header() {
    return (
      <div
        className="flex flex-wrap w-full font-bold text-lg align-items-center justify-content-around py-3 px-2 gap-3"
        style={{ background: "#222d33", minHeight: "70px", color: "white" }}
      >
        <div
          className="flex items-center align-items-center justify-content-between"
          style={{ flex: "1 1 auto" }}
        >
          <FontAwesomeIcon
            className="ml-2"
            icon={faBars}
            fontSize="30px"
            onClick={() => setSidebarActive(!sidebarActive)}
          />
          <img
            className="cursor-pointer"
            src={`${process.env.PUBLIC_URL}/logofullwhite.png`}
            height="50px"
            alt="logo"
          />
          <Dropdown
            value={i18n.language}
            variant="filled"
            onChange={(e) => i18n.changeLanguage(e.value)}
            options={[
              { value: "tr", label: "Türkçe" },
              { value: "en", label: "English" },
            ]}
            optionLabel="label"
            style={{
              height: "35px",
              alignItems: "center",
              minWidth: 120,
            }}
          />
        </div>

        <div className="flex flex-wrap align-items-center gap-1">
          <Dropdown
            value={ddSelectedRestaurant}
            onChange={(e) => dispatch(ddSelectedRestaurantCRUD(e.value))}
            options={ddRestaurants}
            optionLabel="name"
            className="w-full md:w-14rem"
            variant="filled"
            style={{
              height: "35px",
              alignItems: "center",
            }}
          />

          <PrimeMenu
            popup
            ref={logoutMenu}
            style={{ fontWeight: "600" }}
            model={[
              {
                label: t("secenekler"),
                items: [
                  {
                    label: t("sifreDegistir"),
                    command: () => {
                      setChangePwDlg(true);
                    },
                  },
                  {
                    label: t("cikis"),
                    command: () => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("restaurant");
                      navigate("/");
                      dispatch(ddRestaurantsCRUD([]));
                      dispatch(ddSelectedRestaurantCRUD({}));
                    },
                  },
                ],
              },
            ]}
          />
          <Button
            className="pl-0"
            onClick={(event) => logoutMenu.current.toggle(event)}
            aria-controls="popup_menu_left"
            aria-haspopup
            style={{ backgroundColor: "rgb(34, 45, 51)" }}
          >
            {userInfo?.email}
            <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
          </Button>
        </div>
      </div>
    );
  }

  const SearchCustomer = (event) => {
    setTimeout(() => {
      axios
        .post("clientAIO/searchcustomer", {
          restaurantId: ddSelectedRestaurant.id,
          name: event.query,
        })
        .then((x) => {
          setFilteredCustomers(x.data);
        });
    }, 250);
  };

  const itemTemplate = (item) => {
    return (
      <div className="flex align-items-center">
        <div>
          {item.name} - {item.areaCode + item.phone}
        </div>
      </div>
    );
  };

  function camelize(text) {
    const words = text.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i] && words[i].length > 0) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    }

    return words.join(" ");
  }

  function AddResertvationDlgTemp() {
    return (
      <Dialog
        className="col-12 lg:col-6 xl-col-6 p-0 m-0"
        visible={addReservationDlg}
        header={t("rezEkle")}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        onHide={() => {
          setAddReservationDlg(false);
          setReservationData({
            date: new Date(),
            time: "",
            notes: "",
            guestCount: 1,
            name: "",
            areaCode: "",
            phone: "",
            customerNote: "",
            email: "",
            lang: "tr",
          });
          setSelectedAreaCode({
            country: "Turkey",
            code: "90",
            iso: "TR",
          });
        }}
      >
        <div
          className="grid p-0 col-12"
          style={{
            //gap: ".5rem",
            marginTop: 10,
          }}
        >
          <div className="col-12 py-0 my-0 gap-3" style={{ display: "flex" }}>
            <div className="flex align-items-center gap-2">
              <RadioButton
                inputId="langtr"
                onChange={(e) =>
                  setReservationData({ ...reservationData, lang: "tr" })
                }
                checked={reservationData.lang === "tr"}
              />
              <label htmlFor="langtr" className="flex align-items-center">
                <img
                  htmlFor="ingredient1"
                  src={`${process.env.PUBLIC_URL}/tr.png`}
                  width={25}
                  alt="tr"
                />
              </label>
            </div>
            <div className="flex align-items-center gap-2">
              <RadioButton
                inputId="langen"
                onChange={(e) =>
                  setReservationData({ ...reservationData, lang: "en" })
                }
                checked={reservationData.lang === "en"}
              />
              <label htmlFor="langen" className="flex align-items-center">
                <img
                  htmlFor="ingredient1"
                  src={`${process.env.PUBLIC_URL}/en.png`}
                  width={25}
                  alt="tr"
                />
              </label>
            </div>
          </div>

          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faUser} />
              </span>
              <FloatLabel>
                <AutoComplete
                  id="name"
                  field="name"
                  value={reservationData.name}
                  suggestions={filteredCustomers}
                  completeMethod={SearchCustomer}
                  onChange={(e) => {
                    // Eğer girilen metin 50 karakterden fazlaysa, daha fazla karakter eklenmesini engelle
                    if (e.value.length <= 50) {
                      setReservationData({
                        ...reservationData,
                        name: camelize(e.value),
                      });
                    }
                  }}
                  onSelect={(e) =>
                    setReservationData({
                      ...reservationData,
                      name: e.value.name,
                      areaCode: e.value.areaCode,
                      phone: e.value.phone,
                      email: e.value.email,
                      customerNote: e.value.customerNote,
                    })
                  }
                  itemTemplate={itemTemplate}
                  panelFooterTemplate={
                    <div
                      className="text-center mb-1"
                      style={{ fontWeight: 500, fontSize: 14 }}
                    >
                      {t("kayitliMusteriler")}
                    </div>
                  }
                />
                <label for="name">{t("musAdSoyad")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faPhone} />
              </span>
              <FloatLabel>
                <Dropdown
                  filter
                  id="areaCode"
                  value={selectedAreaCode}
                  style={{ borderRadius: 3 }}
                  onChange={(e) => setSelectedAreaCode(e.value)}
                  valueTemplate={(x) => x.country + " +" + x.code}
                  itemTemplate={(x) => x.country + " +" + x.code}
                  options={areaCodes}
                  optionLabel="code"
                  placeholder="Select a Areacode"
                  scrollHeight="400px"
                />
                <label for="areaCode">{t("alanKodu")}</label>
              </FloatLabel>
            </div>
            <div className="p-inputgroup flex-1">
              <FloatLabel>
                <InputText
                  id="phone"
                  value={reservationData.phone}
                  onChange={(e) => {
                    let input = e.target.value;
                    // Sadece sayıları kabul et
                    const isNumeric = /^\d*$/.test(input);
                    // İlk karakter sıfır olmamalı ve sadece sayılar girilmeli ve uzunluk 15 haneyi geçmemeli
                    if (isNumeric && input.length <= 15) {
                      if (input.length === 0 || true) {
                        setReservationData({
                          ...reservationData,
                          phone: input,
                        });
                      }
                    }
                  }}
                  /*onChange={(e) => {
                    const phoneValue = e.target.value;
                    // Yalnızca rakamları al (istenirse farklı karakterlere de izin verilebilir)
                    const sanitizedPhone = phoneValue.replace(/\D/g, "");

                    // Eğer telefon numarası 10 haneden fazla ise, fazlalığı kes
                    if (sanitizedPhone.length <= 10) {
                      setReservationData({
                        ...reservationData,
                        phone: sanitizedPhone,
                      });
                    }
                  }}*/
                />
                <label for="phone">{t("telefon")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <FloatLabel>
                <InputText
                  id="mail"
                  value={reservationData.email}
                  onChange={(e) => {
                    const emailValue = e.target.value;

                    // E-posta adresi 75 karakterden uzun olmamalı ve @ karakterini içermeli
                    if (emailValue.length <= 75) {
                      setReservationData({
                        ...reservationData,
                        email: emailValue,
                      });
                    }
                  }}
                />
                <label for="mail">{t("mail")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                {t("ogun")}
              </span>
              <div className="flex align-items-center">
                {restaurant.mealTimes?.map((x) => (
                  <div
                    className="flex align-items-center px-3 select-none cursor-pointer"
                    style={{
                      border: "1px solid grey",
                      height: "100%",
                      background:
                        selectedMeal.id === x.id ? "#F39237" : "#EDE7D9",
                      fontWeight: 700,
                    }}
                    onClick={() => setSelectedMeal(x)}
                  >
                    {i18n.language === "en" ? x.nameEn : x.name}
                  </div>
                ))}
                <div className="right-0 absolute pr-5 font-semibold text-sm">
                  (
                  {selectedMeal?.startTime?.slice(0, 5) +
                    "-" +
                    selectedMeal?.endTime?.slice(0, 5)}
                  )
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              <Calendar
                placeholder="Tarih"
                style={{ textAlignLast: "center" }}
                locale={i18n.language}
                value={reservationData.date}
                dateFormat="dd.mm.yy"
                readOnlyInput
                showButtonBar
                onChange={(e) =>
                  setReservationData({ ...reservationData, date: e.value })
                }
              />
            </div>
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faClock} />
              </span>
              <Dropdown
                placeholder={t("saat")}
                value={reservationData.time}
                options={activeHours}
                onChange={(e) =>
                  setReservationData({
                    ...reservationData,
                    time: e.value,
                  })
                }
                scrollHeight="370px"
              />
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faUsers} />
              </span>
              <Dropdown
                placeholder={t("kisiSayisi")}
                options={personCountOptions}
                value={reservationData.guestCount}
                onChange={(e) =>
                  setReservationData({
                    ...reservationData,
                    guestCount: e.value,
                  })
                }
                scrollHeight="300px"
              />
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
              <FloatLabel>
                <InputTextarea
                  rows={2} // 2 satır başlangıçta gösterilecek
                  autoResize={true} // Kullanıcı yazdıkça boyutu artacak
                  id="customerNote"
                  value={reservationData.customerNote}
                  onChange={(e) => {
                    const noteValue = e.target.value;

                    // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                    if (noteValue.length <= 250) {
                      setReservationData({
                        ...reservationData,
                        customerNote: noteValue,
                      });
                    }
                  }}
                />
                <label for="customerNote">{t("musteriNotu")}</label>
              </FloatLabel>
            </div>
          </div>

          <div className="col-12">
            <div className="p-inputgroup flex-1">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faPencil} />
              </span>
              <FloatLabel>
                <InputTextarea
                  rows={2} // 2 satır başlangıçta gösterilecek
                  autoResize={true} // Kullanıcı yazdıkça boyutu artacak
                  id="reservationNote"
                  value={reservationData.notes}
                  onChange={(e) => {
                    const noteValue = e.target.value;

                    // Eğer not 250 karakterden uzunsa, daha fazla karakter eklenmesine izin verme
                    if (noteValue.length <= 250) {
                      setReservationData({
                        ...reservationData,
                        notes: noteValue,
                      });
                    }
                  }}
                />
                <label for="reservationNote">{t("restoranNotu")}</label>
              </FloatLabel>
            </div>
          </div>
          <div className="col-12 flex" style={{ justifyContent: "center" }}>
            <Button
              loading={loading}
              label={t("kaydet")}
              severity="success"
              disabled={
                (reservationData.time.length > 4) &
                (reservationData.name.length > 3) &
                (reservationData.phone.length >= 6)
                  ? ""
                  : true
              }
              icon={
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ marginRight: "10px" }}
                />
              }
              className="shadow-4 my-2 px-4"
              style={{ fontSize: 20, fontWeight: 700 }}
              onClick={() => PostReservation()}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  function ChangePwTemp() {
    return (
      <Dialog
        visible={changePwDlg}
        modal
        onHide={() => setChangePwDlg(false)}
        style={{ borderRadius: 50 }}
        content={({ hide }) => (
          <div
            className="flex flex-column px-8 py-5 gap-4"
            style={{
              borderRadius: "12px",
              backgroundImage:
                "radial-gradient(circle at left top, var(--primary-400), var(--primary-700))",
            }}
          >
            <div className="inline-flex flex-column gap-2">
              <label
                htmlFor="username"
                className="text-primary-50 font-semibold"
              >
                Eski Şifre
              </label>
              <InputText
                autoComplete="one-time-code"
                id="oldPassword"
                label="oldPassword"
                type="password"
                className="bg-white-alpha-20 border-none p-3 text-primary-50"
                value={changePwData.oldPw}
                onChange={(e) =>
                  setChangePwData({ ...changePwData, oldPw: e.target.value }) &
                  setErrorOldPw(false)
                }
              ></InputText>
            </div>
            <div className="inline-flex flex-column gap-2">
              <label
                htmlFor="username"
                className="text-primary-50 font-semibold"
              >
                Yeni Şifre
              </label>
              <InputText
                autoComplete="one-time-code"
                id="password"
                label="Password"
                className="bg-white-alpha-20 border-none p-3 text-primary-50"
                type="password"
                value={changePwData.newPw}
                onChange={(e) =>
                  setChangePwData({ ...changePwData, newPw: e.target.value })
                }
              ></InputText>
            </div>
            {errorOldPw && (
              <div className="font-bold" style={{ color: "red" }}>
                *Eski şifreyi hatalı girdiniz!
              </div>
            )}
            <div className="flex align-items-center gap-2">
              <Button
                label="İptal"
                onClick={(e) =>
                  hide(e) & setChangePwData({ oldPw: "", newPw: "" })
                }
                text
                className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
              ></Button>
              <Button
                label="Değiştir"
                loading={loading}
                onClick={(e) => {
                  setLoading(true);
                  const hashedPassword = CryptoJS.SHA256(
                    changePwData.oldPw
                  ).toString(CryptoJS.enc.Hex);
                  const hashedNewPassword = CryptoJS.SHA256(
                    changePwData.newPw
                  ).toString(CryptoJS.enc.Hex);
                  axios
                    .post("clientAIO/changepw", {
                      id: userInfo.userId,
                      password: hashedPassword,
                      name: hashedNewPassword,
                    })
                    .then(() => {
                      hide(e);
                      setLoading(false);
                      setErrorOldPw(false);
                      setChangePwData({ oldPw: "", newPw: "" });
                      toast.current.show({
                        severity: "success",
                        summary: "Başarılı",
                        detail: "Şifreniz başarıyla değiştirildi.",
                        life: 3000,
                      });
                    })
                    .catch(() => {
                      setLoading(false);
                      setErrorOldPw(true);
                    });
                }}
                text
                className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
              ></Button>
            </div>
          </div>
        )}
      ></Dialog>
    );
  }

  return (
    <>
      <Toast ref={toast} />
      <Toast ref={toastNewRes} position="bottom-right" />

      {AddResertvationDlgTemp()}
      {ChangePwTemp()}
      <div
        style={{
          display: "flex",
          height: "100%",
          direction: "ltr",
        }}
      >
        <Sidebar
          collapsed={collapsed}
          backgroundColor="#222d33"
          style={{
            overflowY: "auto",
            border: 0,
            display: sidebarActive ? "initial" : "none",
          }}
        >
          <Menu
            menuItemStyles={{
              button: {
                color: "white",
                fontWeight: "bold",
                transition: "0.25s",
                height: "auto",
                minHeight: 50,
                ":hover": { color: "#F39237" },
              },
            }}
          >
            <MenuItem onClick={() => setAddReservationDlg(true)}>
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("rezEkle")}
              </div>
            </MenuItem>
            <MenuItem
              component={<Link to="/client" />}
              onClick={() => setCurrentTab(0)}
              style={{
                color: currentTab === 0 && "#F39237",
                backgroundColor: currentTab === 0 && "white",
              }}
            >
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faList}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("rezListesi")}
              </div>
            </MenuItem>
            <MenuItem
              component={<Link to="/client/reservations" />}
              onClick={() => setCurrentTab(1)}
              style={{
                color: currentTab === 1 && "#F39237",
                backgroundColor: currentTab === 1 && "white",
              }}
            >
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("tumRezervasyonlar")}
              </div>
            </MenuItem>
            <MenuItem
              component={<Link to="/client/customers" />}
              onClick={() => setCurrentTab(2)}
              style={{
                color: currentTab === 2 && "#F39237",
                backgroundColor: currentTab === 2 && "white",
              }}
            >
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("musteriler")}
              </div>
            </MenuItem>
            <MenuItem
              component={<Link to="/client/timemanagement" />}
              onClick={() => setCurrentTab(3)}
              style={{
                color: currentTab === 3 && "#F39237",
                backgroundColor: currentTab === 3 && "white",
              }}
            >
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("zamanYonetimi")}
              </div>
            </MenuItem>
            <MenuItem
              component={<Link to="/client/reservationreports" />}
              onClick={() => setCurrentTab(4)}
              style={{
                color: currentTab === 4 && "#F39237",
                backgroundColor: currentTab === 4 && "white",
              }}
            >
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faChartLine}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("rezervasyonRaporlari")}
              </div>
            </MenuItem>
            <MenuItem
              component={<Link to="/client/restaurantsettings" />}
              onClick={() => setCurrentTab(5)}
              style={{
                color: currentTab === 5 && "#F39237",
                backgroundColor: currentTab === 5 && "white",
              }}
            >
              <div
                className="my-2 py-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faGear}
                  fontSize={!collapsed ? "50px" : "35px"}
                />
                {!collapsed && t("ayarlar")}
              </div>
            </MenuItem>
            {userInfo.userLevel === 1 && (
              <MenuItem component={<Link to="/management" />}>
                Admin Ekranı
              </MenuItem>
            )}
          </Menu>
        </Sidebar>
        <div
          style={{
            width: "100%",
            overflowY: "scroll",
            backgroundColor: "#f9f9f9",
          }}
        >
          {Header()}
          {!waitingAuth && <Outlet />}
        </div>
      </div>
    </>
  );
}

export default SidebarPro;
