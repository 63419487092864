import React, { useEffect, useState, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import { addLocale } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faChevronLeft,
  faChevronRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import UpdateReservationDlg from "../components/UpdateReservationDlg";
import "../styles/ReservationList.css";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";

function ReservationList() {
  const { t, i18n } = useTranslation();
  const op = useRef(null);
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const { ddSelectedRestaurant } = useSelector((state) => state.counter);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservations, setReservations] = useState([]);
  const [fullReservations, setFullReservations] = useState([]);

  const [updateDlg, setUpdateDlg] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const [loadingFast, setLoadingFast] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([0, 1, 2, 3, 6]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });
  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) return;
    const connection = new HubConnectionBuilder()
      .withUrl("https://api.reserone.com/msghub")
      //.withUrl("https://localhost:7100/msghub")
      .withAutomaticReconnect()
      .build();

    let conId = ddSelectedRestaurant?.id + ""; // conversation id customer ekranları için veya restoran için restid;
    connection
      .start()
      .then(() => {
        connection
          .invoke("RegisterTransaction", conId)
          .then(() => {})
          .catch((err) => console.error(err));

        connection.on("ReceiveMessage", (message) => {
          //1~  - yeni rezervasyon
          //2~  - rezervasyon güncellemesi
          if (
            moment(message.split("~")[1], "DD.MM.YYYY").format("DD.MM.yy") ===
            moment(selectedDate).format("DD.MM.yy")
          ) {
            GetReservations();
          }
        });
      })
      .catch((err) => console.error("Bağlantı hatası:", err));

    return () => {
      connection.stop();
    };
  }, [ddSelectedRestaurant?.id, selectedDate]);

  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) return;

    GetReservations();
  }, [ddSelectedRestaurant?.id, selectedDate]);

  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) return;

    axios.get(`clientAIO/menulist/${ddSelectedRestaurant?.id}`).then((x) => {
      setRestaurant(x.data);
    });
  }, [ddSelectedRestaurant?.id]);

  function GetReservations() {
    setLoading(true);
    axios
      .get(
        `clientAIO/reservations/daily/${ddSelectedRestaurant?.id}/${moment(
          selectedDate
        ).format("yy-MM-DD")}`
      )
      .then((x) => {
        setLoading(false);
        setReservations(x.data);
        setFullReservations(x.data);
        setReservations(
          x.data.filter((a) => selectedStatus.some((b) => b === a.status))
        );
      });
  }

  const ChangeStatusConf = (rowData, status) => {
    const accept = (rowData, status) => {
      setLoadingFast(true);
      axios
        .put(
          `clientAIO/reservation/status/${rowData.restaurantId}/${rowData.id}/${status}`
        )
        .then(() => setLoadingFast(false));
    };

    const reject = () => {};

    confirmDialog({
      group: "templating",
      header: t("onaylama"),
      acceptLabel: t("evet"),
      rejectLabel: t("hayir"),
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 font-semibold">
          <div>{t("durumGunOnay")}</div>
          <div style={{ textTransform: "capitalize" }}>
            {rowData.customer.name}
          </div>
          <div className="flex gap-1">
            {StatusTemp(rowData.status)}
            {">>>"}
            {StatusTemp(status)}
          </div>
        </div>
      ),
      accept: () => accept(rowData, status),
      reject,
    });
  };

  function StatusTemp(status) {
    let bgColor = "";
    let color = "";
    let txt = "";

    switch (status) {
      case 0:
        bgColor = "white";
        color = "black";
        txt = t("konfirmeDegil");
        break;
      case 1:
        bgColor = "#fed7b2";
        color = "#faac61";
        txt = t("gelmedi");
        break;
      case 2:
        bgColor = "#a0bfbf";
        color = "#3e9393";
        txt = t("konfirme");
        break;
      case 3:
        bgColor = "#b2dab7";
        color = "#39b54a";
        txt = t("geldi");
        break;
      case 4:
        bgColor = "#f8bebe";
        color = "#e34c4c";
        txt = t("iptal");
        break;
      case 5:
        bgColor = "#a6c0a9";
        color = "#388643";
        txt = t("tamamlandi");
        break;
      case 6:
        bgColor = "#de9ee6";
        color = "#d46ee0";
        txt = t("bekliyor");
        break;
      case 7:
        bgColor = "white";
        color = "black";
        txt = t("iadeEdildi");
        break;
      case 8:
        bgColor = "pink";
        color = "darkred";
        txt = t("kiptali");
        break;

      default:
        break;
    }

    return (
      <div className="flex align-items-center justify-content-center">
        <div
          className="px-3 py-1 text-center"
          style={{
            backgroundColor: bgColor,
            color: color,
            textTransform: "uppercase",
            fontWeight: "800",
            borderRadius: 5,
            width: "fit-content",
            fontSize: 14,
          }}
        >
          {txt}
        </div>
      </div>
    );
  }

  const CloseUpdateDlg = (x) => {
    if (x > 0) {
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Rezervasyon başarıyla güncellendi",
        life: 3000,
      });
    }

    setUpdateDlg(false);
  };

  const renderHeader = () => {
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters["global"].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    return (
      <div className="flex md:justify-content-between justify-content-center align-items-center flex-wrap gap-2">
        <div
          className="shadow-4 p-3 flex flex-column gap-1"
          style={{
            borderRadius: 5,
            backgroundColor: "#f8f8f8",
            color: "black",
          }}
        >
          <div className="notranslate">
            {t("rezSayisi")}: <span>{reservations.length}</span>
          </div>
          <div className="notranslate">
            {t("kisiSayisi")}:{" "}
            {reservations.reduce((total, guest) => {
              return total + guest.guestCount;
            }, 0)}
          </div>
        </div>

        <div className="flex flex-column justify-content-center align-items-center">
          <div className="flex align-items-center">
            <div
              className="mr-2 cursor-pointer"
              onClick={() => {
                const newDate = new Date(selectedDate);
                newDate.setDate(newDate.getDate() - 1);
                setSelectedDate(newDate);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} fontSize={30} />
            </div>
            <Calendar
              readOnlyInput
              locale={i18n.language}
              variant="filled"
              inputStyle={{
                textAlign: "center",
                border: "none",
                background: "white",
                fontWeight: 700,
                fontSize: 18,
                width: "150px",
              }}
              value={selectedDate}
              dateFormat="dd.mm.yy"
              showButtonBar
              clearButtonClassName="hidden"
              onChange={(e) => setSelectedDate(e.value)}
            />
            <div
              className="ml-2 cursor-pointer"
              onClick={() => {
                const newDate = new Date(selectedDate);
                newDate.setDate(newDate.getDate() + 1);
                setSelectedDate(newDate);
              }}
            >
              <FontAwesomeIcon icon={faArrowRightLong} fontSize={30} />
            </div>
          </div>
          {false && (
            <MultiSelect
              display="chip"
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.value);
                setReservations(
                  fullReservations.filter((a) =>
                    e.value.some((b) => b === a.status)
                  )
                );
              }}
              options={[
                { name: t("konfirmeDegil"), value: 0 },
                { name: t("gelmedi"), value: 1 },
                { name: t("konfirme"), value: 2 },
                { name: t("geldi"), value: 3 },
                { name: t("iptal"), value: 4 },
                { name: t("tamamlandi"), value: 5 },
                { name: t("bekliyor"), value: 6 },
                { name: t("iadeEdildi"), value: 7 },
                { name: t("kiptali"), value: 8 },
              ]}
              optionLabel="name"
              placeholder="Select Status"
              maxSelectedLabels={2}
              className="w-full mt-2"
            />
          )}
        </div>
        <div>
          <FontAwesomeIcon
            className="mr-2 cursor-pointer"
            icon={faFilter}
            fontSize={25}
            onClick={(e) => op.current.toggle(e)}
          />
          <OverlayPanel ref={op} appendTo={document.body}>
            <div
              className="flex align-items-center my-3 py-1 select-none"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <Checkbox
                inputId="allstatus"
                onChange={() => {
                  if (selectedStatus.length === 9) {
                    setSelectedStatus([]);
                    setReservations([]);
                  } else {
                    setSelectedStatus([0, 1, 2, 3, 4, 5, 6, 7, 8]);
                    setReservations(fullReservations);
                  }
                }}
                checked={selectedStatus.length === 9}
              />
              <label
                htmlFor="allstatus"
                className="ml-2 cursor-pointer font-semibold"
              >
                {t("hepsi")}
              </label>
            </div>

            {[
              { name: t("konfirmeDegil"), value: 0 },
              { name: t("gelmedi"), value: 1 },
              { name: t("konfirme"), value: 2 },
              { name: t("geldi"), value: 3 },
              { name: t("iptal"), value: 4 },
              { name: t("tamamlandi"), value: 5 },
              { name: t("bekliyor"), value: 6 },
              { name: t("iadeEdildi"), value: 7 },
              { name: t("kiptali"), value: 8 },
            ].map((x) => (
              <div
                className="flex align-items-center my-3 py-1 select-none"
                style={{ borderBottom: "1px solid gainsboro" }}
              >
                <Checkbox
                  inputId={x.value}
                  name={x.name}
                  value={x.name}
                  onChange={() => {
                    if (selectedStatus.some((b) => b === x.value)) {
                      let newFilterStatuses = selectedStatus.filter(
                        (v) => v !== x.value
                      );

                      setSelectedStatus(newFilterStatuses);
                      setReservations(
                        fullReservations.filter((a) =>
                          newFilterStatuses.some((b) => b === a.status)
                        )
                      );
                    } else {
                      let newFilterStatuses = [...selectedStatus, x.value];
                      setSelectedStatus(newFilterStatuses);
                      setReservations(
                        fullReservations.filter((a) =>
                          newFilterStatuses.some((b) => b === a.status)
                        )
                      );
                    }
                  }}
                  checked={selectedStatus.includes(x.value)}
                />
                <label
                  htmlFor={x.value}
                  className="ml-2 cursor-pointer font-semibold"
                >
                  {x.name}
                </label>
              </div>
            ))}
          </OverlayPanel>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={t("ara")}
            style={{ width: 300 }}
          />
        </div>
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <Toast ref={toast} />
      {updateDlg && (
        <UpdateReservationDlg
          slcRest={restaurant}
          slcRes={selectedReservation}
          CloseUpdateDlg={CloseUpdateDlg}
        />
      )}
      <ConfirmDialog group="templating" />
      {windowWidth > 500 && (
        <DataTable
          value={reservations}
          paginator
          rows={30}
          dataKey="id"
          filters={filters}
          loading={loading}
          onRowClick={(x) => {
            setSelectedReservation(x.data);
            setUpdateDlg(true);
          }}
          header={header}
          emptyMessage={t("rezBulunamadi")}
          stripedRows
        >
          <Column
            field="time"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            alignHeader={"center"}
            body={(x) => (
              <div className="text-center">
                <div className="font-semibold">
                  {moment(x.date).format("DD.MM.YYYY")}
                </div>
                <div className="font-semibold">{x.time.slice(0, 5)}</div>
                <div className="mt-3 text-sm font-semibold">
                  {i18n.language === "en" ? x.mealTimeNameEn : x.mealTimeName}
                </div>
                <div className="text-sm font-semibold">
                  {t("olusturulma")}:{" "}
                  {moment(x.createdDate).format("DD.MM.YYYY HH:mm")}
                </div>
              </div>
            )}
            sortable
            header={t("rezTarihi")}
          />
          <Column
            field="status"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            body={(a) => StatusTemp(a.status)}
            alignHeader={"center"}
            header={t("durum")}
            sortable
          />
          <Column
            field="customer.name"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            body={(x) => (
              <div className="text-center">
                <div
                  className="font-bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {x.customer.name}
                </div>
                {x.type === 2 && (
                  <div className="font-semibold text-sm">({t("manuel")})</div>
                )}
                {x.type === 3 && (
                  <div className="font-semibold text-sm">
                    ({t("cevrimici")})
                  </div>
                )}
                {x.language === "en" ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/en.png`}
                    width={20}
                    alt="tr"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/tr.png`}
                    width={20}
                    alt="tr"
                  />
                )}
              </div>
            )}
            alignHeader={"center"}
            header={t("musteri")}
            sortable
          />
          <Column
            field="reservationToken"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            className="font-semibold"
            alignHeader={"center"}
            align={"center"}
            header={t("rezKodu")}
            sortable
          />
          <Column
            field="guestCount"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            className="font-bold"
            alignHeader={"center"}
            align={"center"}
            header={t("kisi")}
            sortable
          />
          <Column
            field="customerNote"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
            className="font-semibold text-sm"
            alignHeader={"center"}
            align={"center"}
            header={t("musteriNotu")}
            sortable
          />
          <Column
            field="notes"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            style={{ maxWidth: "200px", wordWrap: "break-word" }}
            className="font-semibold text-sm"
            alignHeader={"center"}
            align={"center"}
            header={t("restoranNotu")}
            sortable
          />
          <Column
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            style={{ maxWidth: "350px", minWidth: "350px" }}
            body={(x) => (
              <div className="flex flex-wrap">
                <div className="col-4 p-1">
                  <Button
                    loading={loadingFast}
                    disabled={x.status === 3}
                    className="w-full h-full"
                    label={t("geldi")}
                    style={{ backgroundColor: "#39b54a" }}
                    onClick={() => ChangeStatusConf(x, 3)}
                  />
                </div>
                <div className="col-4 p-1">
                  <Button
                    loading={loadingFast}
                    disabled={x.status === 1}
                    className="w-full h-full"
                    label={t("gelmedi")}
                    style={{ backgroundColor: "#faac61" }}
                    onClick={() => ChangeStatusConf(x, 1)}
                  />
                </div>
                <div className="col-4 p-1">
                  <Button
                    loading={loadingFast}
                    disabled={x.status === 5}
                    className="w-full h-full text-sm"
                    label={t("tamamlandi")}
                    style={{ backgroundColor: "#388643" }}
                    onClick={() => ChangeStatusConf(x, 5)}
                  />
                </div>
                <div className="col-4 p-1">
                  <Button
                    loading={loadingFast}
                    disabled={x.status === 2}
                    className="w-full h-full"
                    label={t("konfirme")}
                    style={{ backgroundColor: "#3e9393" }}
                    onClick={() => ChangeStatusConf(x, 2)}
                  />
                </div>
                <div className="col-4 p-1">
                  <Button
                    loading={loadingFast}
                    disabled={x.status === 4}
                    className="w-full h-full"
                    label={t("iptal")}
                    style={{ backgroundColor: "#e34c4c" }}
                    onClick={() => ChangeStatusConf(x, 4)}
                  />
                </div>
                <div className="col-4 p-1">
                  <Button
                    loading={loadingFast}
                    disabled={x.status === 6}
                    className="w-full h-full"
                    label={t("bekliyor")}
                    style={{ backgroundColor: "#de9ee6" }}
                    onClick={() => ChangeStatusConf(x, 6)}
                  />
                </div>
              </div>
            )}
          />
        </DataTable>
      )}
      {windowWidth <= 500 && (
        <DataTable
          value={reservations}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          loading={loading}
          onRowClick={(x) => {
            setSelectedReservation(x.data);
            setUpdateDlg(true);
          }}
          header={header}
          emptyMessage={t("rezBulunamadi")}
          stripedRows
          globalFilterFields={[
            "customer.name",
            "customer.phone",
            "time",
            "reservationToken",
            "notes",
            "customerNote",
          ]}
        >
          <Column
            field="time"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            style={{ wordWrap: "break-word", maxWidth: "100px" }}
            alignHeader={"center"}
            body={(x) => (
              <div className="text-center">
                <div className="font-semibold">
                  {moment(x.date).format("DD.MM.YYYY")}
                </div>
                <div className="font-semibold">{x.time.slice(0, 5)}</div>
                <div className="mt-3 text-sm font-semibold">
                  {i18n.language === "en" ? x.mealTimeNameEn : x.mealTimeName}
                </div>
                <div className="text-sm font-semibold mb-2">
                  {t("olusturulma")}:{" "}
                  {moment(x.createdDate).format("DD.MM.YYYY HH:mm")}
                </div>
                {StatusTemp(x.status)}
                <div
                  className="font-bold mt-2"
                  style={{ textTransform: "capitalize" }}
                >
                  {x.customer.name}
                </div>
                {x.type === 2 && (
                  <div className="font-semibold text-sm">({t("manuel")})</div>
                )}
                {x.type === 3 && (
                  <div className="font-semibold text-sm">
                    ({t("cevrimici")})
                  </div>
                )}
                {x.language === "en" ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/en.png`}
                    width={20}
                    alt="tr"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/tr.png`}
                    width={20}
                    alt="tr"
                  />
                )}
                <div className="font-semibold text-md mt-2">
                  {x.reservationToken}
                </div>
                <div className="font-semibold text-md mt-2">
                  {x.guestCount} {t("kisi")}
                </div>
                <div className="mt-3 text-sm font-semibold">
                  {x.customerNote}
                </div>
                <div className="mt-3 text-sm font-semibold">{x.notes}</div>
                <div className="flex flex-wrap">
                  <div className="col-4 p-1">
                    <Button
                      loading={loadingFast}
                      disabled={x.status === 3}
                      className="w-full h-full"
                      label={t("geldi")}
                      style={{ backgroundColor: "#39b54a" }}
                      onClick={() => ChangeStatusConf(x, 3)}
                    />
                  </div>
                  <div className="col-4 p-1">
                    <Button
                      loading={loadingFast}
                      disabled={x.status === 1}
                      className="w-full h-full"
                      label={t("gelmedi")}
                      style={{ backgroundColor: "#faac61" }}
                      onClick={() => ChangeStatusConf(x, 1)}
                    />
                  </div>
                  <div className="col-4 p-1">
                    <Button
                      loading={loadingFast}
                      disabled={x.status === 5}
                      className="w-full h-full text-xs"
                      label={t("tamamlandi")}
                      style={{ backgroundColor: "#388643" }}
                      onClick={() => ChangeStatusConf(x, 5)}
                    />
                  </div>
                  <div className="col-4 p-1">
                    <Button
                      loading={loadingFast}
                      disabled={x.status === 2}
                      className="w-full h-full"
                      label={t("konfirme")}
                      style={{ backgroundColor: "#3e9393" }}
                      onClick={() => ChangeStatusConf(x, 2)}
                    />
                  </div>
                  <div className="col-4 p-1">
                    <Button
                      loading={loadingFast}
                      disabled={x.status === 4}
                      className="w-full h-full"
                      label={t("iptal")}
                      style={{ backgroundColor: "#e34c4c" }}
                      onClick={() => ChangeStatusConf(x, 4)}
                    />
                  </div>
                  <div className="col-4 p-1">
                    <Button
                      loading={loadingFast}
                      disabled={x.status === 6}
                      className="w-full h-full"
                      label={t("bekliyor")}
                      style={{ backgroundColor: "#de9ee6" }}
                      onClick={() => ChangeStatusConf(x, 6)}
                    />
                  </div>
                </div>
              </div>
            )}
          />
        </DataTable>
      )}
    </>
  );
}

export default ReservationList;
