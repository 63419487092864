import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faLocationPin,
  faBank,
  faAdd,
  faPen,
  faCity,
  faCheck,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment/moment";

function Companies() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [addCompanyDlg, setaddCompanyDlg] = useState(false);
  const [editCompanyDlg, setEditCompanyDlg] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedCompany, setSelectedCompany] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [compData, setCompData] = useState({
    id: 0,
    name: "",
    description: "",
    areaCode: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    createdDate: "",
    taxNo: "",
    taxHolder: "",
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    GetCompaines();
  }, []);

  function GetCompaines() {
    axios.get("adminAIO/companies").then((x) => {
      setCompanies(x.data);
      setLoading(false);
    });
  }

  function PostCompany() {
    axios
      .post("adminAIO/company", {
        name: compData.name,
        description: compData.description,
        areaCode: compData.areaCode,
        phone: compData.phone,
        email: compData.email,
        address: compData.address,
        city: compData.city,
        taxNo: compData.taxNo,
        taxHolder: compData.taxHolder,
      })
      .then((x) => {
        setaddCompanyDlg(false);
        setLoading(false);
        GetCompaines();
        setCompData({
          id: 0,
          name: "",
          description: "",
          areaCode: "",
          phone: "",
          email: "",
          address: "",
          city: "",
          createdDate: "",
          taxNo: "",
          taxHolder: "",
        });
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Şirket başarıyla eklendi",
          life: 3000,
        });
      });
  }

  function PutCompany() {
    axios
      .put("adminAIO/company", {
        id: selectedCompany.id,
        name: selectedCompany.name,
        description: selectedCompany.description,
        areaCode: selectedCompany.areaCode,
        phone: selectedCompany.phone,
        email: selectedCompany.email,
        address: selectedCompany.address,
        city: selectedCompany.city,
        taxNo: selectedCompany.taxNo,
        taxHolder: selectedCompany.taxHolder,
      })
      .then((x) => {
        setEditCompanyDlg(false);
        setLoading(false);
        GetCompaines();
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Şirket başarıyla güncellendi",
          life: 3000,
        });
      });
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  function AddCompanyDlgTemp() {
    return (
      <Dialog
        header="Şirket Ekle"
        visible={addCompanyDlg}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ width: windowWidth <= 500 ? "100vw" : "50vw" }}
        onHide={() =>
          setaddCompanyDlg(false) &
          setCompData({
            id: 0,
            name: "",
            description: "",
            areaCode: "",
            phone: "",
            email: "",
            address: "",
            city: "",
            createdDate: "",
            taxNo: "",
            taxHolder: "",
          })
        }
      >
        <>
          <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyName"
                    value={compData.name}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 100 karaktere kadar izin ver
                      if (value.length <= 100) {
                        setCompData({ ...compData, name: value });
                      }
                    }}
                  />
                  <label htmlFor="companyName">Şirket Adı</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyDescription"
                    value={compData.description}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 250 karaktere kadar izin ver
                      if (value.length <= 250) {
                        setCompData({ ...compData, description: value });
                      }
                    }}
                  />
                  <label htmlFor="companyDescription">Açıklama</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyAreaCode"
                    value={compData.areaCode}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 5 karaktere kadar izin ver
                      if (value.length <= 5) {
                        setCompData({
                          ...compData,
                          areaCode: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyAreaCode">Alan Kodu</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="companyTelNo"
                    value={compData.phone}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Sadece iki haneli sayıya izin ver
                      if (/^\d{0,10}$/.test(value)) {
                        setCompData({ ...compData, phone: value });
                      }
                    }}
                  />
                  <label htmlFor="companyTelNo">Telefon</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyMail"
                    value={compData.email}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setCompData({ ...compData, email: value });
                      }
                    }}
                  />
                  <label htmlFor="companyMail">Mail</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLocationPin} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyAdress"
                    value={compData.address}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 150 karaktere kadar izin ver
                      if (value.length <= 150) {
                        setCompData({ ...compData, address: value });
                      }
                    }}
                  />
                  <label htmlFor="companyAdress">Şirket Adresi</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faCity} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyCity"
                    value={compData.city}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setCompData({ ...compData, city: value });
                      }
                    }}
                  />
                  <label htmlFor="companyCity">Şehir</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup" style={{ flex: "0 0 180px" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faBank} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyTaxNo"
                    value={compData.taxNo}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setCompData({ ...compData, taxNo: value });
                      }
                    }}
                  />
                  <label htmlFor="companyTaxNo">Vergi No</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="companyTaxAdministration"
                    value={compData.taxHolder}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setCompData({ ...compData, taxHolder: value });
                      }
                    }}
                  />
                  <label htmlFor="companyTaxAdministration">
                    Vergi Dairesi
                  </label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-12 flex justify-content-center">
              <Button
                loading={loading}
                label="Ekle"
                severity="success"
                icon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "10px" }}
                  />
                }
                className="shadow-4"
                style={{ fontSize: 18, fontWeight: 600 }}
                onClick={() => PostCompany() & setLoading(true)}
              />
            </div>
          </div>
        </>
      </Dialog>
    );
  }

  function EditCompanyDlgTemp() {
    return (
      <Dialog
        header="Şirket Güncelle"
        visible={editCompanyDlg}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ width: windowWidth <= 500 ? "100vw" : "50vw" }}
        onHide={() => setEditCompanyDlg(false) & setSelectedCompany({})}
      >
        <>
          <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyName"
                    value={selectedCompany.name}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 100 karaktere kadar izin ver
                      if (value.length <= 100) {
                        setSelectedCompany({ ...selectedCompany, name: value });
                      }
                    }}
                  />
                  <label htmlFor="companyName">Şirket Adı</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyDescription"
                    value={selectedCompany.description}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 250 karaktere kadar izin ver
                      if (value.length <= 250) {
                        setSelectedCompany({
                          ...selectedCompany,
                          description: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyDescription">Açıklama</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyAreaCode"
                    value={selectedCompany.areaCode}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 5 karaktere kadar izin ver
                      if (value.length <= 5) {
                        setSelectedCompany({
                          ...selectedCompany,
                          areaCode: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyAreaCode">Alan Kodu</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="companyTelNo"
                    value={selectedCompany.phone}
                    onChange={(x) => {
                      const value = x.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        setSelectedCompany({
                          ...selectedCompany,
                          phone: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyTelNo">Telefon</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyMail"
                    value={selectedCompany.email}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setSelectedCompany({
                          ...selectedCompany,
                          email: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyMail">Mail</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLocationPin} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyAdress"
                    value={selectedCompany.address}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 150 karaktere kadar izin ver
                      if (value.length <= 150) {
                        selectedCompany({ ...selectedCompany, address: value });
                      }
                    }}
                  />
                  <label htmlFor="companyAdress">Şirket Adresi</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faCity} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyCity"
                    value={selectedCompany.city}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        selectedCompany({ ...selectedCompany, city: value });
                      }
                    }}
                  />
                  <label htmlFor="companyCity">Şehir</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup" style={{ flex: "0 0 180px" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faBank} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyTaxNo"
                    value={selectedCompany.taxNo}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setSelectedCompany({
                          ...selectedCompany,
                          taxNo: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyTaxNo">Vergi No</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="companyTaxAdministration"
                    value={selectedCompany.taxHolder}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setSelectedCompany({
                          ...selectedCompany,
                          taxHolder: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyTaxAdministration">
                    Vergi Dairesi
                  </label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-12 flex justify-content-center">
              <Button
                loading={loading}
                label="Güncelle"
                severity="success"
                icon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "10px" }}
                  />
                }
                className="shadow-4"
                style={{ fontSize: 18, fontWeight: 600 }}
                onClick={() => PutCompany() & setLoading(true)}
              />
            </div>
          </div>
        </>
      </Dialog>
    );
  }

  const renderHeader = () => {
    return (
      <div
        className="flex  flex-wrap gap-2"
        style={{
          justifyContent: windowWidth > 500 ? "space-between" : "center",
        }}
      >
        <Button
          loading={loading}
          label="Yeni Şirket Ekle"
          severity="primary"
          icon={
            <FontAwesomeIcon icon={faAdd} style={{ marginRight: "10px" }} />
          }
          className="shadow-4"
          style={{ fontSize: 18, fontWeight: 600 }}
          onClick={() => setaddCompanyDlg(true)}
        />
        <InputText
          style={{ width: "300px" }}
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Ara.."
        />
      </div>
    );
  };
  const header = renderHeader();

  return (
    <>
      <Toast ref={toast} />
      {AddCompanyDlgTemp()}
      {EditCompanyDlgTemp()}
      <div className="flex flex-row shadow-4 ">
        <div
          className="px-3 py-2 "
          style={{
            borderBottom: "5px solid #F39237",
            fontWeight: "bold",
            fontSize: 22,
            minWidth: "fit-content",
          }}
        >
          Şirket Listesi
        </div>
        <div
          style={{
            borderBottomWidth: 2,
            borderBottom: "5px solid #222d33",
            width: "100%",
          }}
        ></div>
      </div>
      {windowWidth > 500 && (
        <DataTable
          value={companies}
          paginator
          rows={30}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage="Şirket bulunamadı."
        >
          <Column field="name" header="Şirket" sortable />
          <Column field="description" header="Açıklama" sortable />
          <Column field="phone" header="Telefon" sortable />
          <Column field="email" header="Mail" sortable />
          <Column field="city" header="Şehir" sortable />
          <Column field="address" header="Adres" sortable />
          <Column
            field="createdDate"
            header="Oluşturulma Tarihi"
            sortable
            body={(x) => moment(x.createdDate).format("HH:mm DD.MM.YYYY")}
          />
          <Column field="taxNo" header="Vergi Numarası" sortable />
          <Column field="taxHolder" header="Vergi Dairesi" sortable />
          <Column
            body={(x) => (
              <Button
                rounded
                loading={loading}
                severity="primary"
                icon={<FontAwesomeIcon icon={faPencil} />}
                className="shadow-4"
                style={{ background: "green" }}
                onClick={() => {
                  setSelectedCompany(x);
                  setEditCompanyDlg(true);
                }}
              />
            )}
          />
        </DataTable>
      )}
      {windowWidth <= 500 && (
        <DataTable
          value={companies}
          paginator
          rows={30}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage="Şirket bulunamadı."
          globalFilterFields={[
            "name",
            "description",
            "phone",
            "email",
            "city",
            "address",
          ]}
        >
          <Column
            body={(x) => (
              <div>
                <div className="flex justify-content-center font-bold">
                  {x.name}
                </div>
                <div className="flex justify-content-center">
                  {x.description}
                </div>
                <div className="flex justify-content-center font-semibold">
                  {x.phone} {x.email}
                </div>
                <div className="flex justify-content-center">
                  {x.city} {x.addres}
                </div>
                <div className="flex justify-content-center">
                  {moment(x.createdDate).format("DD.MM.YYYY - HH:mm")}
                </div>
                <div className="flex justify-content-center">
                  {x.taxNo} {x.taxHolder}
                </div>
                <div style={{ textAlign: "center", paddingTop: "10px" }}>
                  <Button
                    rounded
                    loading={loading}
                    severity="primary"
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    className="shadow-4"
                    style={{ background: "green" }}
                    onClick={() => {
                      setSelectedCompany(x);
                      setEditCompanyDlg(true);
                    }}
                  />
                </div>
              </div>
            )}
          />
        </DataTable>
      )}
    </>
  );
}

export default Companies;

/*
<Dialog
          visible={addUserDlg}
          contentStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{ width: "50vw" }}
          onHide={() => setaddUserDlg(false)}
        >
          <>
            <div
              className="px-3 py-2"
              style={{
                borderBottom: "5px solid #f89d31",
                fontWeight: "bold",
                fontSize: 22,
                minWidth: "fit-content",
              }}
            >
              Kullanıcı Ekle
            </div>

            <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <Dropdown placeholder="Şirket Seç" />
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <FloatLabel>
                    <InputText id="userName" />
                    <label htmlFor="name">Kullanıcı Adı</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText id="userLastName" />
                    <label htmlFor="lastName">Kullanıcı Soyadı</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  <FloatLabel>
                    <InputText id="companyAreaCode" />
                    <label htmlFor="companyAreaCode">Alan Kodu</label>
                  </FloatLabel>
                </div>
                <div className="p-inputgroup flex-1">
                  <FloatLabel>
                    <InputText id="companyTelNo" />
                    <label htmlFor="companyTelNo">Telefon</label>
                  </FloatLabel>
                </div>
              </div>

              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div
                  className="p-inputgroup"
                  style={{ flex: "0 0 150px", marginRight: "5px" }}
                >
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <div
                    className="p-inputgroup"
                    style={{
                      width: "240px",
                      border: "1px solid #9e9e9e",
                      alignItems: "center",

                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <RadioButton
                      inputId="checkuserid"
                      name="manager"
                      value="ismanager"
                      onChange={(e) => setcheckmanager(e.value)}
                      checked={checkmanager === "true"}
                      className="ml-4"
                    />
                    <label htmlhtmlFor="checkmanager" className="ml-1 mr-5">
                      Admin
                    </label>
                    <RadioButton
                      inputId="checkuserid"
                      name="user"
                      value="isuser"
                      onChange={(e) => setcheckuser(e.value)}
                      checked={checkuser === "true"}
                    />
                    <label htmlhtmlFor="checkuser" className="ml-2">
                      Kullanici
                    </label>
                  </div>
                </div>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  <FloatLabel>
                    <InputText id="companyMail" />
                    <label htmlFor="companyMail">Mail</label>
                  </FloatLabel>
                </div>
              </div>

              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <FloatLabel>
                    <InputText id="companyUsername" />
                    <label htmlFor="companyUsername">Kullanıcı Username</label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12">
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <FloatLabel>
                    <InputText id="companyUserPassword" />
                    <label htmlFor="companyUserPassword">
                      Kullanıcı Şifresi
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="col-12"></div>

              <div className="col-12 flex justify-content-center">
                <Button
                  label="Kaydet"
                  severity="success"
                  icon="pi pi-check"
                  className="shadow-4 my-2 px-4"
                  style={{ fontSize: 20, fontWeight: 700 }}
                />
              </div>
            </div>
          </>
        </Dialog>

                <Button
          loading={loading}
          label="Kullanıcı Ekle"
          severity="success"
          icon={
            <FontAwesomeIcon icon={faAdd} style={{ marginRight: "10px" }} />
          }
          className="shadow-4 my-2 px-4"
          style={{ fontSize: 20, fontWeight: 700 }}
          onClick={() => setaddUserDlg(true)}
        />
*/
