import React, { useState, useEffect } from "react";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioButton } from "primereact/radiobutton";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";

import {
  faUser,
  faClock,
  faLock,
  faCalendarDays,
  faCalendarXmark,
  faMoneyBill,
  faTags,
  faPen,
  faCheck,
  faAdd,
  faCross,
  faRemove,
  faDeleteLeft,
  faTrash,
  faInfo,
  faEdit,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { useRef } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

function RestaurantSettings() {
  const { t, i18n } = useTranslation();

  const toast = useRef(null);
  const { ddSelectedRestaurant } = useSelector((state) => state.counter);
  const [loadingFast, setLoadingFast] = useState(false);
  const [addTagDlg, setAddTagDlg] = useState(false);
  const [editTagDlg, setEditTagDlg] = useState(false);
  const [guestMsjDlg, setGuestMsjDlg] = useState(false);
  const [mealData, setMealData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [restData, setRestData] = useState({});
  const [tagsData, setTagsData] = useState({});
  const [selectedTag, setSelectedTag] = useState({});
  const [tagName, setTagName] = useState("");
  const [tagNameEn, setTagNameEn] = useState("");
  const [editableRows, setEditableRows] = useState({});
  const [data, setData] = useState(mealData);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [originalData, setOriginalData] = useState({});
  const daysOfWeek = [
    { name: "Pazartesi", value: 1 },
    { name: "Salı", value: 2 },
    { name: "Çarşamba", value: 3 },
    { name: "Perşembe", value: 4 },
    { name: "Cuma", value: 5 },
    { name: "Cumartesi", value: 6 },
    { name: "Pazar", value: 7 },
  ];
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) {
      return;
    }
    GetTags();
    GetData();
  }, [ddSelectedRestaurant?.id]);

  function GetData() {
    axios.get("clientAIO/restaurant/" + ddSelectedRestaurant.id).then((x) => {
      setMealData(x.data.mealTimes);
      setRestData(x.data);

      setLoading(false);
    });
  }
  function SendTag() {
    axios
      .post("clientAIO/tag", {
        restaurantId: ddSelectedRestaurant.id,
        name: tagName,
        nameEn: tagNameEn,
        isPassive: false,
      })
      .then((x) => {
        GetTags();
        setTagName("");
        setTagNameEn("");
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Yeni Tag Başarıyla Eklendi.",
          life: 3000,
        });
      });
  }

  function GetTags() {
    axios.get("clientAIO/tags/" + ddSelectedRestaurant.id).then((x) => {
      setTagsData(x.data);
      setLoading(false);
    });
  }
  function DeleteTag(rowData) {
    axios.delete("clientAIO/tag/" + rowData.id).then((x) => {
      GetTags();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Tag Başarıyla Silindi.",
        life: 3000,
      });
    });
  }
  function EditTag() {
    axios.put("clientAIO/tag", selectedTag).then((x) => {
      setEditTagDlg(false);
      GetTags();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Tag Başarıyla Güncellendi.",
        life: 3000,
      });
    });
  }
  function PutRestSettings() {
    axios.put("clientAIO/restaurant", restData).then((x) => {
      GetData();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Restoran Ayarlari Güncellendi.",
        life: 3000,
      });
    });
  }
  function AddTagDlgTemplate() {
    return (
      <>
        {windowWidth > 500 && (
          <Dialog
            header={" Tag Ekle"}
            visible={addTagDlg}
            contentStyle={{ display: "flex", flexDirection: "column" }}
            style={{ width: "35vw", height: "75vh" }}
            onHide={() => setAddTagDlg(false)}
          >
            <div style={{ flex: 1, overflowY: "auto" }}>
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagName"
                        value={tagName}
                        onChange={(x) => setTagName(x.target.value)}
                      />
                      <label htmlFor="tagName">{t("turkceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagNameEn"
                        value={tagNameEn}
                        onChange={(x) => setTagNameEn(x.target.value)}
                      />
                      <label htmlFor="tagNameEn">{t("ingilizceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                  <div className="col-12 flex justify-content-center">
                    <Button
                      disabled={
                        tagName === "" || tagNameEn === "" ? true : false
                      }
                      loading={loading}
                      label={t("ekle")}
                      severity="success"
                      onClick={() => SendTag()}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      className="shadow-4"
                      style={{ fontSize: 18, fontWeight: 600 }}
                    />
                  </div>
                  <DataTable value={tagsData} dataKey="id">
                    <Column
                      field="name"
                      alignHeader={"center"}
                      align={"center"}
                      header={t("turkceTagAdi")}
                    ></Column>
                    <Column
                      field="nameEn"
                      alignHeader={"center"}
                      align={"center"}
                      header={t("ingilizceTagAdi")}
                    ></Column>
                    <Column body={editTagTemplate}></Column>
                    <Column body={deleteTagTemplate}></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </Dialog>
        )}
        {windowWidth <= 500 && (
          <Dialog
            header={" Tag Ekle"}
            visible={addTagDlg}
            contentStyle={{ display: "flex", flexDirection: "column" }}
            style={{ width: "100vw", height: "75vh" }}
            onHide={() => setAddTagDlg(false)}
          >
            <div style={{ flex: 1, overflowY: "auto" }}>
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagName"
                        value={tagName}
                        onChange={(x) => setTagName(x.target.value)}
                      />
                      <label htmlFor="tagName">{t("turkceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagNameEn"
                        value={tagNameEn}
                        onChange={(x) => setTagNameEn(x.target.value)}
                      />
                      <label htmlFor="tagNameEn">{t("ingilizceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                  <div className="col-12 flex justify-content-center">
                    <Button
                      loading={loading}
                      label={t("kaydet")}
                      severity="success"
                      onClick={() => SendTag()}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      className="shadow-4"
                      style={{ fontSize: 18, fontWeight: 600 }}
                    />
                  </div>
                  <DataTable value={tagsData} dataKey="id">
                    <Column
                      field="name"
                      alignHeader={"center"}
                      align={"center"}
                      header={t("turkceTagAdi")}
                    ></Column>
                    <Column
                      field="nameEn"
                      alignHeader={"center"}
                      align={"center"}
                      header={t("ingilizceTagAdi")}
                    ></Column>
                    <Column body={editTagTemplate}></Column>
                    <Column body={deleteTagTemplate}></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
  function EditTagDlgTemplate() {
    return (
      <>
        {windowWidth > 500 && (
          <Dialog
            header={"Tag Düzenle"}
            visible={editTagDlg}
            contentStyle={{ display: "flex", flexDirection: "column" }}
            style={{ width: "35vw" }}
            onHide={() => setEditTagDlg(false)}
          >
            <div style={{ flex: 1, overflowY: "auto" }}>
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagName"
                        value={selectedTag.name}
                        onChange={(x) =>
                          setSelectedTag({
                            ...selectedTag,
                            name: x.target.value,
                          })
                        }
                      />
                      <label htmlFor="tagName">{t("turkceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagName"
                        value={selectedTag.nameEn}
                        onChange={(x) =>
                          setSelectedTag({
                            ...selectedTag,
                            nameEn: x.target.value,
                          })
                        }
                      />
                      <label htmlFor="tagNameEn">{t("ingilizceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                  <div className="col-12 flex justify-content-center pb-0 mb-0">
                    <Button
                      loading={loading}
                      label={t("kaydet")}
                      severity="success"
                      onClick={() => EditTag()}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      className="shadow-4"
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
        {windowWidth <= 500 && (
          <Dialog
            header={"Tag Düzenle"}
            visible={editTagDlg}
            contentStyle={{ display: "flex", flexDirection: "column" }}
            style={{ width: "100vw" }}
            onHide={() => setEditTagDlg(false)}
          >
            <div style={{ flex: 1, overflowY: "auto" }}>
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagName"
                        value={selectedTag.name}
                        onChange={(x) =>
                          setSelectedTag({
                            ...selectedTag,
                            name: x.target.value,
                          })
                        }
                      />
                      <label htmlFor="tagName">{t("turkceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faTags} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="tagName"
                        value={selectedTag.nameEn}
                        onChange={(x) =>
                          setSelectedTag({
                            ...selectedTag,
                            nameEn: x.target.value,
                          })
                        }
                      />
                      <label htmlFor="tagNameEn">{t("ingilizceTagAdi")}</label>
                    </FloatLabel>
                  </div>
                  <div className="col-12 flex justify-content-center pb-0 mb-0">
                    <Button
                      loading={loading}
                      label={t("kaydet")}
                      severity="success"
                      onClick={() => EditTag()}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      className="shadow-4"
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
  function GuestMsjDlgTemplate() {
    return (
      <>
        {windowWidth > 500 && (
          <Dialog
            header={"Mesaj Düzenle"}
            visible={guestMsjDlg}
            contentStyle={{
              display: "flex",
              flexDirection: "column",
            }}
            style={{ width: "35vw" }}
            onHide={() => setGuestMsjDlg(false)}
          >
            <div>
              <div className="grid m-0 p-0">
                <div className="col-12" style={{ display: "flex" }}>
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faMessage} />
                    </span>
                    <FloatLabel>
                      <InputText
                        style={{ height: "43px" }}
                        value={restData.guestCountMsg}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Herhangi bir karaktere izin ver ve uzunluğu 250 karakterle sınırla
                          if (value.length <= 250) {
                            setRestData({ ...restData, guestCountMsg: value });
                          }
                        }}
                      ></InputText>
                      <label htmlFor="guestMsj">{t("guestMsj")}</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faMessage} />
                    </span>
                    <FloatLabel>
                      <InputText
                        style={{ height: "43px" }}
                        value={restData.guestCountMsgEn}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Herhangi bir karaktere izin ver ve uzunluğu 250 karakterle sınırla
                          if (value.length <= 250) {
                            setRestData({
                              ...restData,
                              guestCountMsgEn: value,
                            });
                          }
                        }}
                      ></InputText>
                      <label htmlFor="tagNameEn">{t("guestMsjEn")}</label>
                    </FloatLabel>
                  </div>
                  <div className="col-12 flex justify-content-center pb-0 mb-0">
                    <Button
                      loading={loading}
                      label={t("kaydet")}
                      severity="success"
                      onClick={() => {
                        setGuestMsjDlg(false);
                        PutRestSettings();
                      }}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      className="shadow-4"
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
        {windowWidth <= 500 && (
          <Dialog
            header={"Mesaj Düzenle"}
            visible={guestMsjDlg}
            contentStyle={{ display: "flex", flexDirection: "column" }}
            style={{ width: "100vw" }}
            onHide={() => setGuestMsjDlg(false)}
          >
            <div style={{ flex: 1 }}>
              <div className="grid m-0 p-0">
                <div className="col-12" style={{ display: "flex" }}>
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faMessage} />
                    </span>
                    <FloatLabel>
                      <InputText
                        style={{ height: "43px" }}
                        value={restData.guestCountMsg}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Herhangi bir karaktere izin ver ve uzunluğu 250 karakterle sınırla
                          if (value.length <= 250) {
                            setRestData({ ...restData, guestCountMsg: value });
                          }
                        }}
                      ></InputText>
                      <label htmlFor="guestMsj">{t("guestMsj")}</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faMessage} />
                    </span>
                    <FloatLabel>
                      <InputText
                        style={{ height: "43px" }}
                        value={restData.guestCountMsgEn}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Herhangi bir karaktere izin ver ve uzunluğu 250 karakterle sınırla
                          if (value.length <= 250) {
                            setRestData({
                              ...restData,
                              guestCountMsgEn: value,
                            });
                          }
                        }}
                      ></InputText>
                      <label htmlFor="tagNameEn">{t("guestMsjEn")}</label>
                    </FloatLabel>
                  </div>
                  <div className="col-12 flex justify-content-center pb-0 mb-0">
                    <Button
                      loading={loading}
                      label={t("kaydet")}
                      severity="success"
                      onClick={() => {
                        setGuestMsjDlg(false);
                        PutRestSettings();
                      }}
                      icon={
                        <FontAwesomeIcon
                          icon={faCheck}
                          style={{ marginRight: "10px" }}
                        />
                      }
                      className="shadow-4"
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginTop: "10px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };
  const timeEditor = (options) => {
    return (
      <Calendar
        id="calendar-timeonly"
        value={new Date(`1970-01-01T${options.value}`)}
        onChange={(e) =>
          options.editorCallback(moment(e.value).format("HH:mm:ss"))
        }
        timeOnly
        hourFormat="24"
        showTime
        readOnlyInput={true} // Kullanıcıların el ile yazmasını engeller, sadece takvim kullanılabilir.
      />
    );
  };

  const onRowEditComplete = (e) => {
    let _mealData = [...mealData];
    let { newData, index } = e;

    // newData'da aktif günleri manuel olarak güncelle
    newData.activeDays = parseActiveDays(newData.activeDays).sort().join("~");

    _mealData[index] = newData;

    setMealData(_mealData);
  };
  const startTimeTemplate = (rowData) => {
    const formattedTime = moment(rowData.startTime, "HH:mm").format("HH:mm");

    return <div style={{ width: "80px" }}>{formattedTime}</div>;
  };
  const endTimeTemplate = (rowData) => {
    const formattedTime = moment(rowData.endTime, "HH:mm").format("HH:mm ");
    return <div style={{ width: "80px" }}>{formattedTime}</div>;
  };
  const parseActiveDays = (activeDaysString) => {
    if (typeof activeDaysString === "string" && activeDaysString.length > 0) {
      return activeDaysString.split("~").map(Number);
    }
    return [];
  };

  const onRowEditInit = (event) => {
    const _editableRows = { ...editableRows };
    _editableRows[event.index] = true;
    setEditableRows(_editableRows);

    // Orijinal veriyi sakla
    const _originalData = { ...originalData };
    _originalData[event.index] = { ...mealData[event.index] };
    setOriginalData(_originalData);
  };

  const onRowEditSave = async (event) => {
    const _mealData = [...mealData];
    const { newData, index } = event;

    // activeDays'i doğru formatta ayarla
    newData.activeDays = parseActiveDays(newData.activeDays).sort().join("~");

    // Veriyi mealData dizisinde güncelle
    _mealData[index] = newData;

    try {
      // Güncellenen veriyi sunucuya gönder
      await axios
        .put("clientAIO/mealtime", {
          id: newData.id,
          restaurantId: newData.restaurantId,
          name: newData.name,
          activeDays: event.data.activeDays,
          startTime: newData.startTime,
          endTime: newData.endTime,
          infoMsg: newData.infoMsg,
          infoMsgEn: newData.infoMsgEn,
          nameEn: newData.nameEn,
        })
        .then((x) => {
          GetData();
        });

      // Veriyi state'de güncelle
      setMealData(_mealData);

      // Düzenleme modundan çık
      const _editableRows = { ...editableRows };
      delete _editableRows[event.index];
      setEditableRows(_editableRows);

      // Başarılı işlem geri bildirimi
      //console.log("Veri başarıyla güncellendi");
    } catch (error) {
      // Hata durumunda geri bildirimi
      console.error("Veri güncellenirken bir hata oluştu:", error);
    }
  };

  const onRowEditCancel = (event) => {
    const _editableRows = { ...editableRows };
    delete _editableRows[event.index];
    setEditableRows(_editableRows);

    // Orijinal veriyi geri yükle
    const _mealData = [...mealData];
    _mealData[event.index] = originalData[event.index];
    setMealData(_mealData);

    // Saklanan orijinal veriyi temizle
    const _originalData = { ...originalData };
    delete _originalData[event.index];
    setOriginalData(_originalData);
  };
  const activeDaysTemplate = (rowData, options) => {
    const activeDays = parseActiveDays(rowData.activeDays);
    const isEditable = editableRows[options.rowIndex];

    return (
      <div>
        {daysOfWeek.map((day) => (
          <Checkbox
            key={day.value}
            checked={activeDays.includes(day.value)}
            disabled={!isEditable}
            onChange={() => handleDayChange(rowData, day.value)}
            style={{ marginRight: "15px" }}
          />
        ))}
      </div>
    );
  };
  const handleDayChange = (rowData, dayValue) => {
    const activeDays = parseActiveDays(rowData.activeDays);
    const isActive = activeDays.includes(dayValue);

    if (isActive) {
      rowData.activeDays = activeDays
        .filter((day) => day !== dayValue)
        .join("~");
    } else {
      rowData.activeDays = [...activeDays, dayValue].sort().join("~");
    }

    const _data = [...data];
    setData(_data);
  };
  const customDaysHeader = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>{t("gunler")}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span className="pr-1">{t("PZT")}</span>
        <span className="pr-1">{t("SAL")}</span>
        <span className="pr-1">{t("CAR")}</span>
        <span className="pr-1">{t("PER")}</span>
        <span className="pr-1">{t("CUM")}</span>
        <span className="pr-1">{t("CTS")}</span>
        <span className="pr-1">{t("PZR")}</span>
      </div>
    </div>
  );
  const customAddHeader = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Button
        style={{ justifyContent: "center" }}
        icon={<FontAwesomeIcon icon={faAdd} style={{ marginRight: "10px" }} />}
        onClick={() => addNewLine()}
      >
        {t("ekle")}
      </Button>
    </div>
  );
  const addNewLine = () => {
    axios
      .post("clientAIO/mealtime", {
        restaurantId: ddSelectedRestaurant.id,
        name: "yeni",
        activeDays: "",
        startTime: "08:00:00",
        endTime: "21:00:00",
        infoMsg: "",
        nameEn: "new",
        isPassive: false,
      })
      .then((x) => GetData());
  };

  const deleteButtonTemplate = (rowData) => {
    return (
      <Button
        onClick={() => deleteMealConfirm(rowData)}
        icon={
          <FontAwesomeIcon icon={faRemove} style={{ marginRight: "10px" }} />
        }
        className="p-button-danger"
        label={t("sil")}
      />
    );
  };
  const deleteTagTemplate = (rowData) => {
    return (
      <Button
        onClick={() => DeleteTag(rowData)}
        className="p-button-danger "
        style={{ placeContent: "center" }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    );
  };
  const editTagTemplate = (rowData) => {
    return (
      <Button
        onClick={() => {
          setEditTagDlg(true);
          setSelectedTag(rowData);
        }}
        className="p-button-warning "
        style={{ placeContent: "center" }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };
  const deleteMealConfirm = (rowData) => {
    const accept = (rowData) => {
      setLoadingFast(true);
      axios.delete("clientAIO/mealtime/" + rowData.id, {}).then((x) => {
        GetData();
        toast.current.show({
          severity: "success",
          summary: t("basarili"),
          detail: t("ogunBasariylaSilindi"),
          life: 3000,
        });
      });
    };

    const reject = () => {};

    confirmDialog({
      group: "templating",
      header: t("onaylama"),
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 font-semibold">
          <div>{t("ogunSilmeOnaylama")}</div>
          <div style={{ textTransform: "capitalize" }}>{rowData.name}</div>
        </div>
      ),
      accept: () => accept(rowData),
      reject,
    });
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <style>
        {`
          .hidden {
            display: none !important;
          }
        `}
      </style>
      <div
        className="flex"
        style={{
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#f6f6f6",
        }}
      >
        {AddTagDlgTemplate()}
        {EditTagDlgTemplate()}
        {GuestMsjDlgTemplate()}
        <div
          className="p-3 m-3 flex shadow-4 sm:col-12 md:col-12 xl:col-8 lg:col-8"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "10px",
            background: "white",
          }}
        >
          <h3
            style={{
              marginBottom: "30px",
              borderBottom: "3px solid orange",
              fontSize: "22px",
              fontWeight: "900",
            }}
          >
            {" "}
            {t("restoranAyarlari")}
          </h3>
          <div
            className=" flex align-items-center pb-3 flex-wrap "
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("kacDkdeBirRezAlinacagi")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faClock} />
              </span>
              <InputText
                style={{ height: "43px" }}
                value={restData.reservationRange}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, reservationRange: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className=" flex align-items-center pb-3 flex-wrap "
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("enErkenKacSaatOncedenRez")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faClock} />
              </span>
              <InputText
                style={{ height: "43px" }}
                value={restData.earliestHour}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, earliestHour: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className=" flex align-items-center pb-3 flex-wrap"
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("ayniSaateEnFazlaKacRez")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faClock} />
              </span>
              <InputText
                style={{ height: "43px" }}
                value={restData.sameTimeResCount}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, sameTimeResCount: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className=" flex align-items-center pb-3 flex-wrap"
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("rezervasyonMinKisi")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon "
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faUser} />
              </span>
              <InputText
                style={{ height: "43px" }}
                value={restData.minPeople}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, minPeople: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className=" flex align-items-center pb-3 flex-wrap"
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("rezervasyonMaxKisi")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon "
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faUser} />
              </span>
              <InputText
                style={{ height: "43px", width: "165px" }}
                value={restData.maxPeople}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, maxPeople: value });
                  }
                }}
              ></InputText>
              <span
                className="p-inputgroup-addon "
                onClick={() => setGuestMsjDlg(true)}
                style={{
                  backgroundColor: "#3F51B5",
                  color: "white",
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </span>
            </div>
          </div>
          <div
            className=" flex align-items-center pb-3 flex-wrap"
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("maxKacGunSonraRez")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faCalendarDays} />
              </span>
              <InputText
                style={{ height: "43px" }}
                value={restData.latestDay}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, latestDay: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className=" flex align-items-center pb-3 flex-wrap"
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("rezEnGecKacGunOncedenIptal")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faCalendarXmark} />
              </span>
              <InputText
                style={{ height: "43px" }}
                value={restData.earliestCanceledDay}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,3}$/.test(value)) {
                    setRestData({ ...restData, earliestCanceledDay: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permIyzico === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("provizyonAktiflestirme")}
            </div>
            <div className="flex">
              <div className="col-12" style={{ display: "flex" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "200px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <div className="flex align-items-center pl-3">
                    <RadioButton
                      inputId="provActive1"
                      name="provActive"
                      value={true}
                      onChange={(e) =>
                        setRestData({ ...restData, provisionActive: e.value })
                      }
                      checked={restData.provisionActive}
                    />

                    <label htmlFor="provActive1" className="ml-2">
                      {t("acik")}
                    </label>
                  </div>
                  <div className="flex align-items-center pl-2">
                    <RadioButton
                      inputId="provActive2"
                      name="provActive"
                      value={false}
                      onChange={(e) =>
                        setRestData({ ...restData, provisionActive: e.value })
                      }
                      checked={!restData.provisionActive}
                    />
                    <label htmlFor="provActive2" className="ml-2">
                      {t("kapali")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permIyzico === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("provizyonKisiBasiFiyati")}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faMoneyBill} />
              </span>
              <InputText
                style={{ height: "43px" }}
                disabled={restData.provisionActive === false ? true : false}
                value={restData.provisionAmount}
                onChange={(x) => {
                  const value = x.target.value;
                  // Sadece rakamlara izin ver ve uzunluğu 3 karakterle sınırla
                  if (/^\d{0,4}$/.test(value)) {
                    setRestData({ ...restData, provisionAmount: value });
                  }
                }}
              ></InputText>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permOtp === true && restData.provisionActive === false
                ? ""
                : "hidden"
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("otpAktiflestirme")}
            </div>
            <div className="flex">
              <div className="col-12" style={{ display: "flex" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "200px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <div className="flex align-items-center pl-3">
                    <RadioButton
                      inputId="otpActive1"
                      name="otpActive"
                      value={true}
                      onChange={(e) =>
                        setRestData({ ...restData, sendOtp: e.value })
                      }
                      checked={restData.sendOtp}
                    />

                    <label htmlFor="otpActive1" className="ml-2">
                      {t("acik")}
                    </label>
                  </div>
                  <div className="flex align-items-center pl-2">
                    <RadioButton
                      inputId="otpActive2"
                      name="otpActive"
                      value={false}
                      onChange={(e) =>
                        setRestData({ ...restData, sendOtp: e.value })
                      }
                      checked={!restData.sendOtp}
                    />
                    <label htmlFor="otpActive2" className="ml-2">
                      {t("kapali")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permTags === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("tagAktiflestirme")}{" "}
            </div>
            <div className="flex">
              <div className="col-12" style={{ display: "flex" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "200px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <div className="flex align-items-center pl-3">
                    <RadioButton
                      inputId="tagsActive1"
                      name="tagsActive"
                      value={true}
                      onChange={(e) =>
                        setRestData({ ...restData, tagsOpen: e.value })
                      }
                      checked={restData.tagsOpen}
                    />
                    <label htmlFor="tagsActive1" className="ml-2">
                      {t("acik")}
                    </label>
                  </div>
                  <div className="flex align-items-center pl-2">
                    <RadioButton
                      inputId="tagsActive2"
                      name="tagsActive"
                      value={false}
                      onChange={(e) =>
                        setRestData({ ...restData, tagsOpen: e.value })
                      }
                      checked={!restData.tagsOpen}
                    />
                    <label htmlFor="tagsActive2" className="ml-2">
                      {t("kapali")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permTags === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("tagAyarlari")}{" "}
            </div>
            <div className="flex">
              <span
                className="p-inputgroup-addon"
                style={{
                  backgroundColor: "gainsboro",
                  color: "black",
                }}
              >
                <FontAwesomeIcon icon={faTags} />
              </span>
              <Button
                onClick={() => setAddTagDlg(true)}
                style={{
                  width: "210px",
                  justifyContent: "center",
                }}
              >
                {t("tagAyarlari")}
              </Button>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permSms === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("smsAktiflestirme")}
            </div>
            <div className="flex">
              <div className="col-12" style={{ display: "flex" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "200px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <div className="flex align-items-center pl-3">
                    <RadioButton
                      inputId="smsActive1"
                      name="smsActive"
                      value={true}
                      onChange={(e) =>
                        setRestData({ ...restData, sendSms: e.value })
                      }
                      checked={restData.sendSms}
                    />
                    <label htmlFor="smsActive1" className="ml-2">
                      {t("acik")}
                    </label>
                  </div>
                  <div className="flex align-items-center pl-2">
                    <RadioButton
                      inputId="smsActive2"
                      name="smsActive"
                      value={false}
                      onChange={(e) =>
                        setRestData({ ...restData, sendSms: e.value })
                      }
                      checked={!restData.sendSms}
                    />
                    <label htmlFor="smsActive2" className="ml-2">
                      {t("kapali")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permEmail === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("mailAktiflestirme")}{" "}
            </div>
            <div className="flex">
              <div className="col-12" style={{ display: "flex" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "200px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <div className="flex align-items-center pl-3">
                    <RadioButton
                      inputId="mailActive1"
                      name="mailActive"
                      value={true}
                      onChange={(e) =>
                        setRestData({ ...restData, sendEmail: e.value })
                      }
                      checked={restData.sendEmail}
                    />
                    <label htmlFor="mailActive1" className="ml-2">
                      {t("acik")}
                    </label>
                  </div>
                  <div className="flex align-items-center pl-2">
                    <RadioButton
                      inputId="mailActive2"
                      name="mailActive"
                      value={false}
                      onChange={(e) =>
                        setRestData({ ...restData, sendEmail: e.value })
                      }
                      checked={!restData.sendEmail}
                    />
                    <label htmlFor="mailActive2" className="ml-2">
                      {t("kapali")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex align-items-center pb-3 flex-wrap ${
              restData.permWhatsApp === false ? "hidden" : ""
            }`}
            style={{
              width: "100%",
              textAlign: "center",
              flexDirection: windowWidth > 500 ? "row" : "column",
              justifyContent: windowWidth > 500 ? "space-between" : "center",
            }}
          >
            <div
              style={{
                fontSize: `clamp(15px, ${windowWidth * 0.02}px, 20px)`,
                fontWeight: "bold",
                color: "#303030",
              }}
            >
              {t("wpAktiflestirme")}{" "}
            </div>
            <div className="flex">
              <div className="col-12" style={{ display: "flex" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "200px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <div className="flex align-items-center pl-3">
                    <RadioButton
                      inputId="wpActive1"
                      name="wpActive"
                      value={true}
                      onChange={(e) =>
                        setRestData({ ...restData, sendWhatsApp: e.value })
                      }
                      checked={restData.sendWhatsApp}
                    />
                    <label htmlFor="wpActive1" className="ml-2">
                      {t("acik")}
                    </label>
                  </div>
                  <div className="flex align-items-center pl-2">
                    <RadioButton
                      inputId="wpActive2"
                      name="wpActive"
                      value={false}
                      onChange={(e) =>
                        setRestData({ ...restData, sendWhatsApp: e.value })
                      }
                      checked={!restData.sendWhatsApp}
                    />
                    <label htmlFor="wpActive2" className="ml-2">
                      {t("kapali")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            style={{
              width: "100px",
              height: "55px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
            label={t("kaydet")}
            onClick={() => PutRestSettings()}
          />
        </div>
        <div
          className="p-3 m-3"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            borderTop: "5px solid #111d00",
          }}
        >
          <h3
            style={{
              marginBottom: "30px",
              borderBottom: "3px solid orange",
              fontSize: "22px",
              fontWeight: "900",
            }}
          >
            {t("ogunAyarlari")}
          </h3>

          <DataTable
            value={mealData}
            editMode="row"
            dataKey="id"
            onRowEditInit={onRowEditInit}
            onRowEditSave={onRowEditSave}
            onRowEditCancel={onRowEditCancel}
            onRowEditComplete={onRowEditComplete}
            style={{ width: "100%", borderRadius: 10 }}
            className="shadow-4"
          >
            <Column
              field="name"
              header={t("isim")}
              alignHeader={"center"}
              align={"center"}
              editor={(options) => textEditor(options)}
              style={{ width: "170px" }}
            ></Column>
            <Column
              field="nameEn"
              header={t("ingilizceIsim")}
              alignHeader={"center"}
              align={"center"}
              editor={(options) => textEditor(options)}
              style={{ width: "170px" }}
            ></Column>
            <Column
              field="activeDays"
              header={customDaysHeader}
              alignHeader={"center"}
              align={"center"}
              body={activeDaysTemplate}
              style={{ width: "250px" }}
            ></Column>
            <Column
              field="startTime"
              header={t("baslangicTarihi")}
              alignHeader={"center"}
              align={"center"}
              body={startTimeTemplate}
              style={{ width: "140px", minWidth: "140px" }}
              editor={(options) => timeEditor(options)}
            ></Column>
            <Column
              field="endTime"
              header={t("bitisTarihi")}
              alignHeader={"center"}
              align={"center"}
              body={endTimeTemplate}
              style={{ width: "140px", minWidth: "140px" }}
              editor={(options) => timeEditor(options)}
            ></Column>
            <Column
              alignHeader={"center"}
              align={"center"}
              field="infoMsg"
              style={{ width: "250px" }}
              header={t("mesaj")}
              editor={(options) => textEditor(options)}
            ></Column>
            <Column
              alignHeader={"center"}
              align={"center"}
              field="infoMsgEn"
              style={{ width: "250px" }}
              header={t("mesajEn")}
              editor={(options) => textEditor(options)}
            ></Column>
            <Column
              rowEditor={allowEdit}
              alignHeader={"center"}
              align={"center"}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
            <Column
              header={customAddHeader}
              body={deleteButtonTemplate}
              alignHeader={"center"}
              align={"center"}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}

export default RestaurantSettings;
