import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import {
  faUser,
  faPhone,
  faEnvelope,
  faLocationPin,
  faAdd,
  faPen,
  faCheck,
  faPencil,
  faLock,
  faKey,
  faXmark,
  faWebAwesome,
  faSitemap,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment/moment";

function Restaurants() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [addRestaurantDlg, setaddRestaurantDlg] = useState(false);
  const [editRestaurantDlg, setEditRestaurantDlg] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [companiesData, setCompaniesData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedRestaurant, setSelectedRestaurant] = useState({});
  const [tagClose, setTagClose] = useState("Açık");
  const [isClose, setIsClose] = useState("Açık");
  const [addTagActive, setAddTagActive] = useState("Açık");
  const [iyzicoActive, setIyzicoActive] = useState("Açık");
  const [addIyzicoActive, setAddIyzicoActive] = useState("Kapalı");
  const [smsActive, setSmsActive] = useState("Açık");
  const [otpActive, setOtpActive] = useState("Açık");
  const [addSmsActive, setAddSmsActive] = useState("Kapalı");
  const [addOtpActive, setAddOtpActive] = useState("Kapalı");
  const [mailActive, setMailActive] = useState("Açık");
  const [addMailActive, setAddMailActive] = useState("Kapalı");
  const [whatsappActive, setWhatsappActive] = useState("Açık");
  const [addWhatsappActive, setAddWhatsappActive] = useState("Kapalı");
  const [restaurantData, setRestaurantData] = useState({
    companyId: 0,
    name: "",
    description: "",
    areaCode: "",
    phone: "",
    email: "",
    address: "",
    addTagActive: false,
    iyzicoActive: false,
    iyzicoApiKey: "",
    iyzicoSecretKey: "",
    smsmActive: false,
    otpActive: false,
    gsmUserCode: "",
    gsmApiKey: "",
    gsmMsgHeader: "",
    gsmMsg: "",
    gsmMsgEn: "",
    mailActive: false,
    smtpHost: "",
    mailUserName: "",
    mailPw: "",
    mailPort: "",
    mailMsg: "",
    mailMsgEn: "",
    whatsappActive: false,
    wpInstanceId: "",
    wpToken: "",
    weburl: "",
    mapUrl: "",
    wpMsg: "",
    wpMsgEn: "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    GetRestaurants();
  }, []);

  function GetRestaurants() {
    axios.get("adminAIO/restaurants").then((x) => {
      setRestaurants(x.data);
      setLoading(false);
    });
  }
  useEffect(() => {
    GetCompaines();
  }, []);

  function GetCompaines() {
    axios.get("adminAIO/ddcompanies").then((x) => {
      setCompaniesData(x.data);
    });
  }

  function PostRestaurants() {
    axios
      .post("adminAIO/restaurant", {
        companyId: selectedCompany.id,
        name: restaurantData.name,
        description: restaurantData.description,
        address: restaurantData.address,
        areaCode: restaurantData.areaCode,
        phone: restaurantData.phone,
        email: restaurantData.email,
        sendingEmails: restaurantData.sendingEmails,
        permTags: addTagActive === "Açık" ? true : false,
        permIyzico: addIyzicoActive === "Açık" ? true : false,
        iyzicoApiKey: restaurantData.iyzicoApiKey,
        iyzicoSecretKey: restaurantData.iyzicoSecretKey,
        permSms: addSmsActive === "Açık" ? true : false,
        permOTP: addOtpActive === "Açık" ? true : false,
        gsmUserCode: restaurantData.gsmUserCode,
        gsmUserPassword: restaurantData.gsmApiKey,
        gsmMsgHeader: restaurantData.gsmMsgHeader,
        gsmMsg: restaurantData.gsmMsg,
        gsmMsgEn: restaurantData.gsmMsgEn,
        permEmail: addMailActive === "Açık" ? true : false,
        emailSmtpHost: restaurantData.smtpHost,
        emailUsername: restaurantData.mailUserName,
        emailPassword: restaurantData.mailPw,
        emailPort: restaurantData.mailPort,
        emailMsg: restaurantData.mailMsg,
        emailMsgEn: restaurantData.mailMsgEn,
        permWhatsApp: addWhatsappActive === "Açık" ? true : false,
        whatsAppInstanceId: restaurantData.wpInstanceId,
        whatsAppToken: restaurantData.wpToken,
        whatsAppMsg: restaurantData.wpMsg,
        whatsAppMsgEn: restaurantData.wpMsgEn,
        image:
          selectedImage === undefined || selectedImage === null
            ? ""
            : selectedImage,
        logo:
          selectedLogo === undefined || selectedLogo === null
            ? ""
            : selectedLogo,
        weburl: restaurantData.weburl,
        mapUrl: restaurantData.mapUrl,
      })
      .then((x) => {
        setaddRestaurantDlg(false);
        setSelectedImage(null);
        setSelectedCompany({});
        setSelectedLogo(null);
        setLoading(false);
        GetRestaurants();
        setRestaurantData({
          companyId: 0,
          name: "",
          description: "",
          areaCode: "",
          phone: "",
          email: "",
          mapUrl: "",
          weburl: "",
          sendingEmails: "",
          address: "",
          addTagActive: false,
          iyzicoActive: false,
          iyzicoApiKey: "",
          iyzicoSecretKey: "",
          smsmActive: false,
          otpActive: false,
          gsmUserCode: "",
          gsmApiKey: "",
          gsmMsgHeader: "",
          gsmMsg: "",
          gsmMsgEn: "",
          mailActive: false,
          smtpHost: "",
          mailUserName: "",
          mailPw: "",
          mailPort: "",
          mailMsg: "",
          mailMsgEn: "",
          whatsappActive: false,
          wpInstanceId: "",
          wpToken: "",
          wpMsg: "",
          wpMsgEn: "",
        });
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Restoran başarıyla eklendi",
          life: 3000,
        });
      });
  }

  function PutRestaurants() {
    axios
      .put("adminAIO/restaurant", {
        id: selectedRestaurant.id,
        name: selectedRestaurant.name,
        description: selectedRestaurant.description,
        areaCode: selectedRestaurant.areaCode,
        phone: selectedRestaurant.phone,
        email: selectedRestaurant.email,
        sendingEmails: selectedRestaurant.sendingEmails,
        address: selectedRestaurant.address,
        permTags: tagClose === "Açık" ? true : false,
        isClosed: isClose === "Açık" ? false : true,
        permIyzico: iyzicoActive === "Açık" ? true : false,
        iyzicoApiKey: selectedRestaurant.iyzicoApiKey,
        iyzicoSecretKey: selectedRestaurant.iyzicoSecretKey,
        permSms: smsActive === "Açık" ? true : false,
        permOtp: otpActive === "Açık" ? true : false,
        gsmUserCode: selectedRestaurant.gsmUserCode,
        gsmUserPassword: selectedRestaurant.gsmUserPassword,
        gsmMsgHeader: selectedRestaurant.gsmMsgHeader,
        gsmMsg: selectedRestaurant.gsmMsg,
        gsmMsgEn: selectedRestaurant.gsmMsgEn,
        permEmail: mailActive === "Açık" ? true : false,
        emailSmtpHost: selectedRestaurant.emailSmtpHost,
        emailUsername: selectedRestaurant.emailUsername,
        emailPassword: selectedRestaurant.emailPassword,
        emailPort: selectedRestaurant.emailPort,
        emailMsg: selectedRestaurant.emailMsg,
        emailMsgEn: selectedRestaurant.emailMsgEn,
        permWhatsApp: whatsappActive === "Açık" ? true : false,
        whatsAppInstanceId: selectedRestaurant.whatsAppInstanceId,
        whatsAppToken: selectedRestaurant.whatsAppToken,
        whatsAppMsg: selectedRestaurant.whatsAppMsg,
        whatsAppMsgEn: selectedRestaurant.whatsAppMsgEn,
        image:
          selectedImage === undefined || selectedImage === null
            ? ""
            : selectedImage,
        logo:
          selectedLogo === undefined || selectedLogo === null
            ? ""
            : selectedLogo,
        weburl: selectedRestaurant.weburl,
        mapUrl: selectedRestaurant.mapUrl,
      })
      .then((x) => {
        setEditRestaurantDlg(false);
        setSelectedImage(null);
        setSelectedLogo(null);
        setLoading(false);
        GetRestaurants();
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Restoran başarıyla güncellendi",
          life: 3000,
        });
      });
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  function AddRestaurantDlgTemp() {
    return (
      <>
        <Dialog
          header={" Restoran Ekle"}
          visible={addRestaurantDlg}
          contentStyle={{ display: "flex", flexDirection: "column" }}
          style={{
            width: windowWidth <= 500 ? "100vw" : "50vw",
            minHeight: "100vh",
          }}
          onHide={() =>
            setaddRestaurantDlg(false) &
            setSelectedCompany({}) &
            setRestaurantData({
              companyId: 0,
              name: "",
              description: "",
              areaCode: "",
              phone: "",
              email: "",
              sendingEmails: "",
              address: "",
              weburl: "",
              mapUrl: "",
              tagClose: false,
              iyzicoActive: false,
              iyzicoApiKey: "",
              iyzicoSecretKey: "",
              smsmActive: false,
              otpActive: false,
              gsmUserCode: "",
              gsmApiKey: "",
              gsmMsgHeader: "",
              gsmMsg: "",
              gsmMsgEn: "",
              mailActive: false,
              smtpHost: "",
              mailUserName: "",
              mailPw: "",
              mailPort: "",
              mailMsg: "",
              mailMsgEn: "",
              whatsappActive: false,
              wpInstanceId: "",
              wpToken: "",
              wpMsg: "",
              wpMsgEn: "",
            }) &
            setSelectedImage(null) &
            setSelectedLogo(null)
          }
        >
          <div style={{ flex: 1, overflowY: "auto" }}>
            <TabView style={{ height: "auto" }}>
              <TabPanel header="Kimlik" value="null">
                <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <Dropdown
                        placeholder="Şirket Seç"
                        options={companiesData}
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.value)}
                        optionLabel="name"
                      />
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantName"
                          value={restaurantData.name}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                name: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantName">Restoran Adı</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantDescription"
                          value={restaurantData.description}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                description: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantDescription">Açıklama</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantAreaCode"
                          value={restaurantData.areaCode}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 5 karaktere kadar izin ver
                            if (value.length <= 5) {
                              setRestaurantData({
                                ...restaurantData,
                                areaCode: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantAreaCode">Alan Kodu</label>
                      </FloatLabel>
                    </div>
                    <div className="p-inputgroup flex-1">
                      <FloatLabel>
                        <InputText
                          id="restaurantTelNo"
                          value={restaurantData.phone}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Sadece iki haneli sayıya izin ver
                            if (/^\d{0,10}$/.test(value)) {
                              setRestaurantData({
                                ...restaurantData,
                                phone: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantTelNo">Telefon</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantMail"
                          value={restaurantData.email}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 75 karaktere kadar izin ver
                            if (value.length <= 75) {
                              setRestaurantData({
                                ...restaurantData,
                                email: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantMail">Mail</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantMail"
                          value={restaurantData.sendingEmails}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 250 karaktere kadar izin ver
                            if (value.length <= 250) {
                              setRestaurantData({
                                ...restaurantData,
                                sendingEmails: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantMail">Diğer Mailler</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faLocationPin} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantAdress"
                          value={restaurantData.address}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 100 karaktere kadar izin ver
                            if (value.length <= 100) {
                              setRestaurantData({
                                ...restaurantData,
                                address: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantAdress">
                          Restoran Adresi
                        </label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mapurl"
                          value={restaurantData.mapUrl}
                          onChange={(x) => {
                            setRestaurantData({
                              ...restaurantData,
                              weburl: x.target.value,
                            });
                          }}
                        />
                        <label htmlFor="mapurl">Harita Linki</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="restaurantweburl"
                          value={restaurantData.weburl}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 100 karaktere kadar izin ver
                            if (value.length <= 100) {
                              setRestaurantData({
                                ...restaurantData,
                                weburl: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="restaurantweburl">Restoran Linki</label>
                      </FloatLabel>
                    </div>
                  </div>

                  <div className="col-12" style={{ display: "flex" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="addSetTag1"
                          name="addSetActive"
                          value="Açık"
                          onChange={(e) => setAddTagActive(e.value)}
                          checked={addTagActive === "Açık"}
                        />
                        <label htmlFor="addSetTag1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="addSetTag2"
                          name="addSetActive"
                          value="Kapalı"
                          onChange={(e) => setAddTagActive(e.value)}
                          checked={addTagActive === "Kapalı"}
                        />
                        <label htmlFor="addSetTag2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      Tag kullanabilir mi?
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1 align-items-center gap-2">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        Rezervasyon Arkaplanı
                      </span>
                      <input
                        type="file"
                        accept="image/png"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          const reader = new FileReader();

                          reader.onloadend = () => {
                            setSelectedImage(reader.result.split(",")[1]);
                          };
                          reader.readAsDataURL(file);
                        }}
                      />
                    </div>
                    <div className="p-inputgroup flex-1 align-items-center gap-2 mt-2">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        Logo
                      </span>
                      <input
                        type="file"
                        accept="image/png"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          const reader = new FileReader();

                          reader.onloadend = () => {
                            setSelectedLogo(reader.result.split(",")[1]);
                          };
                          reader.readAsDataURL(file);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Iyzico">
                <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                  <div className="col-12" style={{ display: "flex" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="addSetIyzico1"
                          name="addIyzicoAktif"
                          value="Açık"
                          onChange={(e) => setAddIyzicoActive(e.value)}
                          checked={addIyzicoActive === "Açık"}
                        />
                        <label htmlFor="addSetIyzico1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="addSetIyzico2"
                          name="addIyzicoAktif"
                          value="Kapalı"
                          onChange={(e) => setAddIyzicoActive(e.value)}
                          checked={addIyzicoActive === "Kapalı"}
                        />
                        <label htmlFor="addSetIyzico2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      Provizyon kullanabilir mi?
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="iyzicoApiKey"
                          value={restaurantData.iyzicoApiKey}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 100 karaktere kadar izin ver
                            if (value.length <= 100) {
                              setRestaurantData({
                                ...restaurantData,
                                iyzicoApiKey: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="iyzicoApiKey">İyzico ApiKey</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="iyzicoSecretKey"
                          value={restaurantData.iyzicoSecretKey}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 100 karaktere kadar izin ver
                            if (value.length <= 100) {
                              setRestaurantData({
                                ...restaurantData,
                                iyzicoSecretKey: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="iyzicoSecretKey">
                          İyzico SecretKey
                        </label>
                      </FloatLabel>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Sms">
                <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                  <div className="col-12" style={{ display: "flex" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="addSmsAktif1"
                          name="addSmsAktif"
                          value="Açık"
                          onChange={(e) => setAddSmsActive(e.value)}
                          checked={addSmsActive === "Açık"}
                        />
                        <label htmlFor="addSmsAktif1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="addSmsAktif2"
                          name="addSmsAktif"
                          value="Kapalı"
                          onChange={(e) => setAddSmsActive(e.value)}
                          checked={addSmsActive === "Kapalı"}
                        />
                        <label htmlFor="addSmsAktif2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      SMS kullanabilir mi?
                    </div>
                  </div>
                  <div className="col-12" style={{ display: "flex" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="otpAktif1"
                          name="otpAktif"
                          value="Açık"
                          onChange={(e) => setAddOtpActive(e.value)}
                          checked={addOtpActive === "Açık"}
                        />
                        <label htmlFor="otpAktif1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="otpAktif2"
                          name="otpAktif"
                          value="Kapalı"
                          onChange={(e) => setAddOtpActive(e.value)}
                          checked={addOtpActive === "Kapalı"}
                        />
                        <label htmlFor="otpAktif2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      OTP kullanabilir mi?
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="smsNo"
                          value={restaurantData.gsmUserCode}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                gsmUserCode: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="smsNo">Sms Numara</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="smsPw"
                          value={restaurantData.gsmApiKey} //problem emir isim farkliligi var burada gsmapikey olmali
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                gsmApiKey: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="smsPw">Sms Api Key</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="smsHeader"
                          value={restaurantData.gsmMsgHeader}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                gsmMsgHeader: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="smsHeader">Sms Başlık</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="smsTrMsg"
                          value={restaurantData.gsmMsg}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 200 karaktere kadar izin ver
                            if (value.length <= 200) {
                              setRestaurantData({
                                ...restaurantData,
                                gsmMsg: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="smsTrMsg">Sms Türkçe Mesaj</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="smsEnMsg"
                          value={restaurantData.gsmMsgEn}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 200 karaktere kadar izin ver
                            if (value.length <= 200) {
                              setRestaurantData({
                                ...restaurantData,
                                gsmMsgEn: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="smsEnMsg">Sms İngilizce Mesaj</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12 font-semibold">
                    {"Tüm mesajlar için kullanılabilir anahtarlar: {customer} , {restaurant} , {date} , {time} , {guestcount} , {code} " +
                      window.location.origin +
                      "{cancelurl}"}
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Mail">
                <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                  <div className="col-12" style={{ display: "flex" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="addMailAktif1"
                          name="addMailAktif"
                          value="Açık"
                          onChange={(e) => setAddMailActive(e.value)}
                          checked={addMailActive === "Açık"}
                        />
                        <label htmlFor="addMailAktif1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="addMailAktif2"
                          name="addMailAktif"
                          value="Kapalı"
                          onChange={(e) => setAddMailActive(e.value)}
                          checked={addMailActive === "Kapalı"}
                        />
                        <label htmlFor="addSetMail2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      Mail kullanabilir mi?
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mailHost"
                          value={restaurantData.smtpHost}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                smtpHost: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="mailHost">SMTP Host</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mailUserName"
                          value={restaurantData.mailUserName}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                mailUserName: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="mailUserName">Mail Username</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mailPw"
                          value={restaurantData.mailPw}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                mailPw: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="mailPw">Mail Password</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mailPort"
                          value={restaurantData.mailPort}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                mailPort: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="mailPort">Mail Port</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mailTR"
                          value={restaurantData.mailMsg}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                mailMsg: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="smsEnMsg">Türkçe Mail</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="mailEN"
                          value={restaurantData.mailMsgEn}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 50 karaktere kadar izin ver
                            if (value.length <= 50) {
                              setRestaurantData({
                                ...restaurantData,
                                mailMsgEn: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="mailEN">İngilizce Mail</label>
                      </FloatLabel>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Whatsapp">
                <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                  <div className="col-12" style={{ display: "flex" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="addSetWhatsapp1"
                          name="whatsappActive"
                          value="Açık"
                          onChange={(e) => setAddWhatsappActive(e.value)}
                          checked={addWhatsappActive === "Açık"}
                        />
                        <label htmlFor="addSetWhatsapp1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="addSetWhatsapp2"
                          name="whatsappActive"
                          value="Kapalı"
                          onChange={(e) => setAddWhatsappActive(e.value)}
                          checked={addWhatsappActive === "Kapalı"}
                        />
                        <label htmlFor="addSetWhatsapp2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      Whatsapp kullanabilir mi?
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="wpInstanceId"
                          value={restaurantData.wpInstanceId}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 150 karaktere kadar izin ver
                            if (value.length <= 150) {
                              setRestaurantData({
                                ...restaurantData,
                                wpInstanceId: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="wpInstanceId">
                          Whatsapp Instance ID
                        </label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="wpToken"
                          value={restaurantData.wpToken}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 150 karaktere kadar izin ver
                            if (value.length <= 150) {
                              setRestaurantData({
                                ...restaurantData,
                                wpToken: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="wpToken">Whatsapp Token</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="wpTR"
                          value={restaurantData.wpMsg}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 250 karaktere kadar izin ver
                            if (value.length <= 250) {
                              setRestaurantData({
                                ...restaurantData,
                                wpMsg: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="wpTR">Whatsapp Türkçe Mesaj</label>
                      </FloatLabel>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="p-inputgroup flex-1">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "gainsboro",
                          color: "black",
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </span>
                      <FloatLabel>
                        <InputText
                          id="wpEN"
                          value={restaurantData.wpMsgEn}
                          onChange={(x) => {
                            const value = x.target.value;
                            // Yalnızca 250 karaktere kadar izin ver
                            if (value.length <= 250) {
                              setRestaurantData({
                                ...restaurantData,
                                wpMsgEn: value,
                              });
                            }
                          }}
                        />
                        <label htmlFor="wpEN">Whatsapp İngilizce Mesaj</label>
                      </FloatLabel>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
          <div className="col-12 flex justify-content-center">
            <Button
              disabled={
                selectedCompany.id > 0 && restaurantData.name.length > 3
                  ? false
                  : true
              }
              loading={loading}
              label="Ekle"
              severity="success"
              icon={
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ marginRight: "10px" }}
                />
              }
              className="shadow-4"
              style={{ fontSize: 18, fontWeight: 600 }}
              onClick={() => PostRestaurants() & setLoading(true)}
            />
          </div>
        </Dialog>
      </>
    );
  }

  function EditRestaurantDlgTemp() {
    return (
      <Dialog
        header={selectedRestaurant.name + " Restoranını Güncelle"}
        visible={editRestaurantDlg}
        contentStyle={{ display: "flex", flexDirection: "column" }}
        style={{
          width: windowWidth <= 500 ? "100vw" : "50vw",
          minHeight: "100vh",
        }}
        onHide={() => setEditRestaurantDlg(false) & setSelectedRestaurant({})}
      >
        <div style={{ flex: 1, overflowY: "auto" }}>
          <TabView style={{ height: "auto" }}>
            <TabPanel header="Kimlik">
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantName"
                        value={selectedRestaurant.name}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              name: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantName">Restoran Adı</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantDescription"
                        value={selectedRestaurant.description}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              description: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantDescription">Açıklama</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantAreaCode"
                        value={selectedRestaurant.areaCode}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 5 karaktere kadar izin ver
                          if (value.length <= 5) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              areaCode: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantAreaCode">Alan Kodu</label>
                    </FloatLabel>
                  </div>
                  <div className="p-inputgroup flex-1">
                    <FloatLabel>
                      <InputText
                        id="restaurantTelNo"
                        value={selectedRestaurant.phone}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Sadece iki haneli sayıya izin ver
                          if (/^\d{0,10}$/.test(value)) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              phone: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantTelNo">Telefon</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantMail"
                        value={selectedRestaurant.email}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 75 karaktere kadar izin ver
                          if (value.length <= 75) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              email: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantMail">Mail</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantMail"
                        value={selectedRestaurant.sendingEmails}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 250 karaktere kadar izin ver
                          if (value.length <= 250) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              sendingEmails: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantMail">Diğer Mailler</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantAdress"
                        value={selectedRestaurant.address}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 100 karaktere kadar izin ver
                          if (value.length <= 100) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              address: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantAdress">Restoran Adresi</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restmapurl"
                        value={selectedRestaurant.mapUrl}
                        onChange={(x) => {
                          setSelectedRestaurant({
                            ...selectedRestaurant,
                            mapUrl: x.target.value,
                          });
                        }}
                      />
                      <label htmlFor="restmapurl">Harita Linki</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="restaurantWeburl"
                        value={selectedRestaurant.weburl}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 100 karaktere kadar izin ver
                          if (value.length <= 100) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              weburl: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="restaurantWeburl">Restoran Linki</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-6">
                  <div className="p-inputgroup flex-1 ">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="tagAcik1"
                          name="taglerAcikMi"
                          value="Açık"
                          onChange={(e) => setTagClose(e.value)}
                          checked={tagClose === "Açık"}
                        />
                        <label htmlFor="tagAcik1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="tagAcik2"
                          name="taglerAcikMi"
                          value="Kapalı"
                          onChange={(e) => setTagClose(e.value)}
                          checked={tagClose === "Kapalı"}
                        />
                        <label htmlFor="tagAcik2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      Tag kullanabilir mi?
                    </div>
                  </div>
                  <div
                    className="p-inputgroup flex-1 "
                    style={{ paddingTop: "15px" }}
                  >
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                    <div
                      className="p-inputgroup"
                      style={{
                        width: "170px",
                        border: "1px solid #9e9e9e",
                        alignItems: "center",

                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                      }}
                    >
                      <div className="flex align-items-center pl-3">
                        <RadioButton
                          inputId="resAcik1"
                          name="restoranAcikmi"
                          value="Açık"
                          onChange={(e) => setIsClose(e.value)}
                          checked={isClose === "Açık"}
                        />
                        <label htmlFor="resAcik1" className="ml-2">
                          Açık
                        </label>
                      </div>
                      <div className="flex align-items-center pl-2">
                        <RadioButton
                          inputId="resAcik2"
                          name="restoranAcikmi"
                          value="Kapalı"
                          onChange={(e) => setIsClose(e.value)}
                          checked={isClose === "Kapalı"}
                        />
                        <label htmlFor="resAcik2" className="ml-2">
                          Kapalı
                        </label>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                      Restoran aktif mi?
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1 align-items-center gap-2">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      Rezervasyon Arkaplanı
                    </span>
                    <input
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const reader = new FileReader();

                        reader.onloadend = () => {
                          setSelectedImage(reader.result.split(",")[1]);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    <img
                      style={{ maxWidth: 100 }}
                      alt="image"
                      src={`https://app.reserone.com/imgs/${selectedRestaurant.imageurl}`}
                    />
                  </div>
                  <div className="p-inputgroup flex-1 align-items-center gap-2 mt-2">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      Logo
                    </span>
                    <input
                      accept="image/png"
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const reader = new FileReader();

                        reader.onloadend = () => {
                          setSelectedLogo(reader.result.split(",")[1]);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                    <img
                      style={{ maxWidth: 100 }}
                      alt="logo"
                      src={`https://app.reserone.com/imgs/${selectedRestaurant.logourl}`}
                    />
                  </div>
                </div>
                <div className="col-12 px-3 font-semibold">{`<iframe src="${window.location.origin}/reservation/${selectedRestaurant.id}" 
                style="width: 100%; height: 100%; border: none;">
        </iframe>`}</div>
              </div>
            </TabPanel>
            <TabPanel header="Iyzico">
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <div
                    className="p-inputgroup"
                    style={{
                      width: "170px",
                      border: "1px solid #9e9e9e",
                      alignItems: "center",

                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <div className="flex align-items-center pl-3">
                      <RadioButton
                        inputId="setIyzico1"
                        name="iyzicoaktif"
                        value="Açık"
                        onChange={(e) => setIyzicoActive(e.value)}
                        checked={iyzicoActive === "Açık"}
                      />
                      <label htmlFor="setIyzico1" className="ml-2">
                        Açık
                      </label>
                    </div>
                    <div className="flex align-items-center pl-2">
                      <RadioButton
                        inputId="setIyzico2"
                        name="iyzicoaktif"
                        value="Kapalı"
                        onChange={(e) => setIyzicoActive(e.value)}
                        checked={iyzicoActive === "Kapalı"}
                      />
                      <label htmlFor="setIyzico2" className="ml-2">
                        Kapalı
                      </label>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                    Provizyon kullanabilir mi?
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="iyzicoApiKey"
                        value={selectedRestaurant.iyzicoApiKey}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 100 karaktere kadar izin ver
                          if (value.length <= 100) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              iyzicoApiKey: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="iyzicoApiKey">Iyzico ApiKey</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="iyzicoSecretKey"
                        value={selectedRestaurant.iyzicoSecretKey}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 100 karaktere kadar izin ver
                          if (value.length <= 100) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              iyzicoSecretKey: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="iyzicoSecretKey">Iyzico SecretKey</label>
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Sms">
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <div
                    className="p-inputgroup"
                    style={{
                      width: "170px",
                      border: "1px solid #9e9e9e",
                      alignItems: "center",

                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <div className="flex align-items-center pl-3">
                      <RadioButton
                        inputId="setSms1"
                        name="smsaktif"
                        value="Açık"
                        onChange={(e) => setSmsActive(e.value)}
                        checked={smsActive === "Açık"}
                      />
                      <label htmlFor="setSms1" className="ml-2">
                        Açık
                      </label>
                    </div>
                    <div className="flex align-items-center pl-2">
                      <RadioButton
                        inputId="setSms2"
                        name="smsaktif"
                        value="Kapalı"
                        onChange={(e) => setSmsActive(e.value)}
                        checked={smsActive === "Kapalı"}
                      />
                      <label htmlFor="setSms2" className="ml-2">
                        Kapalı
                      </label>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                    SMS kullanabilir mi?
                  </div>
                </div>
                <div className="col-12" style={{ display: "flex" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <div
                    className="p-inputgroup"
                    style={{
                      width: "170px",
                      border: "1px solid #9e9e9e",
                      alignItems: "center",

                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <div className="flex align-items-center pl-3">
                      <RadioButton
                        inputId="setotpAktif1"
                        name="setotpAktif"
                        value="Açık"
                        onChange={(e) => setOtpActive(e.value)}
                        checked={otpActive === "Açık"}
                      />
                      <label htmlFor="setotpAktif1" className="ml-2">
                        Açık
                      </label>
                    </div>
                    <div className="flex align-items-center pl-2">
                      <RadioButton
                        inputId="setotpAktif2"
                        name="setotpAktif"
                        value="Kapalı"
                        onChange={(e) => setOtpActive(e.value)}
                        checked={otpActive === "Kapalı"}
                      />
                      <label htmlFor="setotpAktif2" className="ml-2">
                        Kapalı
                      </label>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                    OTP kullanabilir mi?
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="smsNo"
                        value={selectedRestaurant.gsmUserCode}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              gsmUserCode: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="smsNo">Sms Numara</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="smsPw1"
                        value={selectedRestaurant.gsmUserPassword} //problem emir isim farkliligi var burada gsmapikey olmali
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              gsmUserPassword: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="smsPw1">Sms Api Key</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="smsHeader"
                        value={selectedRestaurant.gsmMsgHeader}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              gsmMsgHeader: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="smsHeader">Sms Başlık</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="smsTrMsg"
                        value={selectedRestaurant.gsmMsg}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 200 karaktere kadar izin ver
                          if (value.length <= 200) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              gsmMsg: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="smsTrMsg">Sms Türkçe Mesaj</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="smsEnMsg"
                        value={selectedRestaurant.gsmMsgEn}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 200 karaktere kadar izin ver
                          if (value.length <= 200) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              gsmMsgEn: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="smsEnMsg">Sms İngilizce Mesaj</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12 font-semibold">
                  {"Tüm mesajlar için kullanılabilir anahtarlar: {customer} , {restaurant} , {date} , {time} , {guestcount} , {code} " +
                    window.location.origin +
                    "{cancelurl}"}
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Mail">
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <div
                    className="p-inputgroup"
                    style={{
                      width: "170px",
                      border: "1px solid #9e9e9e",
                      alignItems: "center",

                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <div className="flex align-items-center pl-3">
                      <RadioButton
                        inputId="setMail1"
                        name="mailactive"
                        value="Açık"
                        onChange={(e) => setMailActive(e.value)}
                        checked={mailActive === "Açık"}
                      />
                      <label htmlFor="setMail1" className="ml-2">
                        Açık
                      </label>
                    </div>
                    <div className="flex align-items-center pl-2">
                      <RadioButton
                        inputId="setMail2"
                        name="mailactive"
                        value="Kapalı"
                        onChange={(e) => setMailActive(e.value)}
                        checked={mailActive === "Kapalı"}
                      />
                      <label htmlFor="setMail2" className="ml-2">
                        Kapalı
                      </label>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                    Mail kullanabilir mi?
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="mailHost"
                        value={selectedRestaurant.emailSmtpHost}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              emailSmtpHost: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="mailHost">SMTP Host</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="mailUserName"
                        value={selectedRestaurant.emailUsername}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              emailUsername: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="mailUserName">Mail Username</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="mailPw"
                        value={selectedRestaurant.emailPassword}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              emailPassword: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="mailPw">Mail Password</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="mailPort"
                        value={selectedRestaurant.emailPort}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              emailPort: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="mailPort">Mail Port</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="mailTR"
                        value={selectedRestaurant.emailMsg}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              emailMsg: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="smsEnMsg">Türkçe Mail</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="mailEN"
                        value={selectedRestaurant.emailMsgEn}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 50 karaktere kadar izin ver
                          if (value.length <= 50) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              emailMsgEn: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="mailEN">İngilizce Mail</label>
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Whatsapp">
              <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
                <div className="col-12" style={{ display: "flex" }}>
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faLock} />
                  </span>
                  <div
                    className="p-inputgroup"
                    style={{
                      width: "170px",
                      border: "1px solid #9e9e9e",
                      alignItems: "center",

                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <div className="flex align-items-center pl-3">
                      <RadioButton
                        inputId="setWhatsapp1"
                        name="whatsappactive"
                        value="Açık"
                        onChange={(e) => setWhatsappActive(e.value)}
                        checked={whatsappActive === "Açık"}
                      />
                      <label htmlFor="setWhatsapp1" className="ml-2">
                        Açık
                      </label>
                    </div>
                    <div className="flex align-items-center pl-2">
                      <RadioButton
                        inputId="setWhatsapp2"
                        name="whatsappactive"
                        value="Kapalı"
                        onChange={(e) => setWhatsappActive(e.value)}
                        checked={whatsappActive === "Kapalı"}
                      />
                      <label htmlFor="setWhatsapp2" className="ml-2">
                        Kapalı
                      </label>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center", paddingLeft: "10px" }}>
                    Whatsapp kullanabilir mi?
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="wpInstanceId"
                        value={selectedRestaurant.whatsAppInstanceId}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 150 karaktere kadar izin ver
                          if (value.length <= 150) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              whatsAppInstanceId: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="wpInstanceId">Whatsapp Instance ID</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="wpToken"
                        value={selectedRestaurant.whatsAppToken}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 150 karaktere kadar izin ver
                          if (value.length <= 150) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              whatsAppToken: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="wpToken">Whatsapp Token</label>
                    </FloatLabel>
                  </div>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="wpTR"
                        value={selectedRestaurant.whatsAppMsg}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 250 karaktere kadar izin ver
                          if (value.length <= 250) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              whatsAppMsg: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="wpTR">Whatsapp Türkçe Mesaj</label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup flex-1">
                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: "gainsboro",
                        color: "black",
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                    <FloatLabel>
                      <InputText
                        id="wpEN"
                        value={selectedRestaurant.whatsAppMsgEn}
                        onChange={(x) => {
                          const value = x.target.value;
                          // Yalnızca 250 karaktere kadar izin ver
                          if (value.length <= 250) {
                            setSelectedRestaurant({
                              ...selectedRestaurant,
                              whatsAppMsgEn: value,
                            });
                          }
                        }}
                      />
                      <label htmlFor="wpEN">whatsapp İngilizce Mesaj</label>
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
        <div className="col-12 flex" style={{ justifyContent: "center" }}>
          <Button
            loading={loading}
            label="Güncelle"
            severity="success"
            icon={
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: "10px" }} />
            }
            className="shadow-4"
            style={{ fontSize: 18, fontWeight: 600 }}
            onClick={() => PutRestaurants() & setLoading(true)}
          />
        </div>
      </Dialog>
    );
  }

  const renderHeader = () => {
    return (
      <div
        className="flex  flex-wrap gap-2"
        style={{
          justifyContent: windowWidth > 500 ? "space-between" : "center",
        }}
      >
        <Button
          loading={loading}
          label="Yeni Restoran Ekle"
          severity="primary"
          icon={
            <FontAwesomeIcon icon={faAdd} style={{ marginRight: "10px" }} />
          }
          className="shadow-4"
          style={{ fontSize: 18, fontWeight: 600 }}
          onClick={() => setaddRestaurantDlg(true)}
        />
        <InputText
          style={{ width: "300px" }}
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Ara.."
        />
      </div>
    );
  };
  const header = renderHeader();

  function CheckedIconTemp(checked) {
    if (checked) {
      return <FontAwesomeIcon color="green" icon={faCheck} />;
    }
    return <FontAwesomeIcon color="darkred" icon={faXmark} />;
  }

  return (
    <>
      <Toast ref={toast} />
      {AddRestaurantDlgTemp()}
      {EditRestaurantDlgTemp()}
      <div className="flex flex-row shadow-4">
        <div
          className="px-3 py-2"
          style={{
            borderBottom: "5px solid #F39237",
            fontWeight: "bold",
            fontSize: 22,
            minWidth: "fit-content",
          }}
        >
          Restoran Listesi
        </div>
        <div
          style={{
            borderBottomWidth: 2,
            borderBottom: "5px solid #222d33",
            width: "100%",
          }}
        ></div>
      </div>
      {windowWidth > 500 && (
        <DataTable
          value={restaurants}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage="Restoran bulunamadı."
        >
          <Column field="company.name" header="Şirket" sortable />
          <Column field="name" header="Restoran" sortable />
          <Column field="description" header="Açıklama" sortable />
          <Column field="address" header="Adres" sortable />
          <Column
            field="createdDate"
            header="Oluşturulma Tarihi"
            sortable
            body={(x) => moment(x.createdDate).format("HH:mm DD.MM.YYYY")}
          />
          <Column
            header="İyzico İzini"
            sortable
            style={{ textAlign: "center" }}
            field="permIyzico"
            body={(data) => CheckedIconTemp(data.permIyzico)}
          />
          <Column
            header="Sms İzini"
            sortable
            style={{ textAlign: "center" }}
            field="permSms"
            body={(data) => CheckedIconTemp(data.permSms)}
          />
          <Column
            header="Email İzini"
            sortable
            style={{ textAlign: "center" }}
            field="permEmail"
            body={(data) => CheckedIconTemp(data.permEmail)}
          />
          <Column
            header="Whatsapp İzini"
            sortable
            style={{ textAlign: "center" }}
            field="permWhatsApp"
            body={(data) => CheckedIconTemp(data.permWhatsApp)}
          />
          <Column
            field="permTags"
            header="Tag İzini"
            sortable
            style={{ textAlign: "center" }}
            body={(data) => CheckedIconTemp(data.permTags)}
          />
          <Column
            field="isClosed"
            header="Aktif Mi?"
            sortable
            style={{ textAlign: "center" }}
            body={(data) => CheckedIconTemp(!data.isClosed)}
          />
          <Column
            body={(x) => (
              <Button
                rounded
                loading={loading}
                severity="primary"
                icon={<FontAwesomeIcon icon={faPencil} />}
                className="shadow-4"
                style={{ background: "green" }}
                onClick={() => {
                  setSelectedRestaurant(x);
                  setTagClose(x.permTags ? "Açık" : "Kapalı");
                  setIsClose(!x.isClosed ? "Açık" : "Kapalı");
                  setIyzicoActive(x.permIyzico ? "Açık" : "Kapalı");
                  setSmsActive(x.permSms ? "Açık" : "Kapalı");
                  setMailActive(x.permEmail ? "Açık" : "Kapalı");
                  setWhatsappActive(x.permWhatsApp ? "Açık" : "Kapalı");
                  setOtpActive(x.permOtp ? "Açık" : "Kapalı");
                  setEditRestaurantDlg(true);
                }}
              />
            )}
          />
        </DataTable>
      )}
      {windowWidth <= 500 && (
        <DataTable
          value={restaurants}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage="Restoran bulunamadı."
        >
          <Column
            body={(x) => (
              <div style={{ textAlign: "center" }}>
                <div className="font-semibold"> {x.company.name} </div>
                <div className="font-bold"> {x.name} </div>
                <div> {x.description} </div>
                <div> {x.address} </div>
                <div> {moment(x.createdDate).format("HH:mm DD.MM.YYYY")}</div>
                <div>İyzico {CheckedIconTemp(x.permIyzico)}</div>
                <div>Sms {CheckedIconTemp(x.permSms)}</div>
                <div>Mail {CheckedIconTemp(x.permEmail)}</div>
                <div>Whatsapp {CheckedIconTemp(x.permWhatsApp)}</div>
                <div>Tags {CheckedIconTemp(x.permTags)}</div>
                <div>Aktif Mi? {CheckedIconTemp(!x.isClosed)}</div>
                <div>
                  <Button
                    rounded
                    loading={loading}
                    severity="primary"
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    className="shadow-4"
                    style={{ background: "green", marginTop: "10px" }}
                    onClick={() => {
                      setSelectedRestaurant(x);
                      setTagClose(x.permTags ? "Açık" : "Kapalı");
                      setIsClose(!x.isClosed ? "Açık" : "Kapalı");
                      setIyzicoActive(x.permIyzico ? "Açık" : "Kapalı");
                      setSmsActive(x.permSms ? "Açık" : "Kapalı");
                      setMailActive(x.permEmail ? "Açık" : "Kapalı");
                      setWhatsappActive(x.permWhatsApp ? "Açık" : "Kapalı");
                      setOtpActive(x.permOtp ? "Açık" : "Kapalı");
                      setEditRestaurantDlg(true);
                    }}
                  />
                </div>
              </div>
            )}
          />
        </DataTable>
      )}
    </>
  );
}

export default Restaurants;
