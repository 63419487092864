import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import trTranslation from "./languages/tr.json";
import enTranslation from "./languages/en.json";

i18n
  .use(initReactI18next) // react-i18next'e adaptasyon
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      tr: {
        translation: trTranslation,
      },
    },
    lng: "tr", // Başlangıç dili
    fallbackLng: "tr", // Yedek dil
    interpolation: {
      escapeValue: false, // XSS saldırılarına karşı önlem
    },
  });

export default i18n;
