import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPhone,
  faEnvelope,
  faLocationPin,
  faBank,
  faAdd,
  faPen,
  faCity,
  faCheck,
  faPencil,
  faBuilding,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment/moment";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import CryptoJS from "crypto-js";

function Users() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editUserDlg, setEditUserDlg] = useState(false);
  const [addUserDlg, setAddUserDlg] = useState(false);
  const [userType, setUserType] = useState("client");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [userData, setUserData] = useState({
    companyId: 0,
    accessRest: "",
    name: "",
    lastname: "",
    email: "",
    password: "",
    areaCode: "",
    phone: "",
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    GetCompaniesDD();
  }, []);

  function GetUsers() {
    axios.get("adminAIO/users").then((x) => {
      setUsers(x.data);
      setLoading(false);
    });
  }

  function GetCompaniesDD() {
    axios.get("adminAIO/ddcompanies").then((x) => {
      setCompanies(x.data);
      GetUsers();
    });
  }

  function PostUser() {
    const hashedPassword = CryptoJS.SHA256(userData.password).toString(
      CryptoJS.enc.Hex
    );

    const accRest =
      userType === "client"
        ? selectedRestaurants.map((item) => item.id).join("~")
        : "";
    axios
      .post("adminAIO/user", {
        accessRest: accRest,
        companyId: userType === "client" ? selectedCompany.id : 1,
        name: userData.name,
        lastname: userData.lastname,
        email: userData.email,
        password: hashedPassword,
        areaCode: userData.areaCode,
        phone: userData.phone,
        userLevel: userType === "client" ? 2 : 1,
      })
      .then(() => {
        setLoading(false);
        setAddUserDlg(false);
        GetUsers();
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Kullanıcı başarıyla eklendi",
          life: 3000,
        });
        setUserData({
          companyId: 0,
          accessRest: "",
          name: "",
          lastname: "",
          email: "",
          password: "",
          areaCode: "",
          phone: "",
        });
        setSelectedCompany({});
        setSelectedRestaurants([]);
      })
      .catch(
        (x) =>
          toast.current.show({
            severity: "warn",
            summary: "Uyarı",
            detail: "Mail adresi mevcut!",
            life: 3000,
          }) & setLoading(false)
      );
  }

  function PutUser() {
    const accRest =
      userType === "client"
        ? selectedRestaurants.map((item) => item?.id).join("~")
        : "";
    const hashedPassword =
      userData.password !== ""
        ? CryptoJS.SHA256(userData.password).toString(CryptoJS.enc.Hex)
        : "";
    axios
      .put("adminAIO/user", {
        id: userData.id,
        accessRest: accRest,
        companyId: userType === "client" ? selectedCompany.id : 1,
        name: userData.name,
        lastname: userData.lastname,
        email: userData.email,
        password: hashedPassword,
        areaCode: userData.areaCode,
        phone: userData.phone,
        userLevel: userType === "client" ? 2 : 1,
      })
      .then(() => {
        setLoading(false);
        setEditUserDlg(false);
        GetUsers();
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Kullanıcı başarıyla güncellendi",
          life: 3000,
        });
        setUserData({
          companyId: 0,
          accessRest: "",
          name: "",
          lastname: "",
          email: "",
          password: "",
          areaCode: "",
          phone: "",
        });
        setSelectedCompany({});
        setSelectedRestaurants([]);
      })
      .catch(
        (x) =>
          toast.current.show({
            severity: "warn",
            summary: "Uyarı",
            detail: "Mail adresi mevcut!",
            life: 3000,
          }) & setLoading(false)
      );
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div
        className="flex  flex-wrap gap-2"
        style={{
          justifyContent: windowWidth > 500 ? "space-between" : "center",
        }}
      >
        <Button
          loading={loading}
          label="Yeni Kullanıcı Ekle"
          severity="primary"
          icon={
            <FontAwesomeIcon icon={faAdd} style={{ marginRight: "10px" }} />
          }
          className="shadow-4"
          style={{ fontSize: 18, fontWeight: 600 }}
          onClick={() => setAddUserDlg(true)}
        />
        <InputText
          style={{ width: "300px" }}
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Ara.."
        />
      </div>
    );
  };
  const header = renderHeader();

  function AddUserDlgTemp() {
    return (
      <Dialog
        visible={addUserDlg}
        header="Kullanıcı Ekle"
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ width: windowWidth <= 500 ? "100vw" : "50vw" }}
        onHide={() => {
          setAddUserDlg(false);
          setUserData({
            companyId: 0,
            accessRest: "",
            name: "",
            lastname: "",
            email: "",
            password: "",
            areaCode: "",
            phone: "",
          });
          setSelectedCompany({});
          setSelectedRestaurants([]);
        }}
      >
        <>
          <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "240px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <RadioButton
                    inputId="admin"
                    name="admin"
                    value="admin"
                    onChange={(e) => setUserType(e.value)}
                    checked={userType === "admin"}
                    className="ml-4"
                  />
                  <label htmlFor="admin" className="ml-1 mr-5">
                    Admin
                  </label>
                  <RadioButton
                    inputId="client"
                    name="client"
                    value="client"
                    onChange={(e) => setUserType(e.value)}
                    checked={userType === "client"}
                  />
                  <label htmlFor="client" className="ml-2">
                    Kullanıcı
                  </label>
                </div>
              </div>
            </div>
            {userType === "client" && (
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <Dropdown
                    placeholder="Şirket Seç"
                    value={selectedCompany}
                    onChange={(e) =>
                      setSelectedCompany(e.value) & setSelectedRestaurants([])
                    }
                    options={companies}
                    optionLabel="name"
                  />
                </div>
              </div>
            )}
            {userType === "client" && selectedCompany?.id > 0 && (
              <div className="flex flex-column pl-3">
                {selectedCompany.restaurants.map((x) => (
                  <div
                    className="px-3 py-1 mb-1 shadow-4 cursor-pointer select-none"
                    style={{
                      backgroundColor: selectedRestaurants.some(
                        (item) => item?.id === x.id
                      )
                        ? "#90EE90"
                        : "gainsboro",
                      color: selectedRestaurants.some(
                        (item) => item?.id === x.id
                      )
                        ? "#006400"
                        : "grey",
                      borderRadius: 25,
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      const isHave = selectedRestaurants.some(
                        (item) => item.id === x.id
                      );
                      if (isHave) {
                        setSelectedRestaurants(
                          selectedRestaurants.filter((a) => a.id !== x.id)
                        );
                      } else {
                        setSelectedRestaurants([...selectedRestaurants, x]);
                      }
                    }}
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            )}
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <FloatLabel>
                  <InputText
                    id="name"
                    value={userData.name}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          name: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="name">Ad</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="lastName"
                    value={userData.lastname}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          lastname: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="lastName">Soyad</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyAreaCode"
                    value={userData.areaCode}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Sadece iki haneli sayıya izin ver
                      if (/^\d{0,2}$/.test(value)) {
                        setUserData({
                          ...userData,
                          areaCode: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyAreaCode">Alan Kodu</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="companyTelNo"
                    value={userData.phone}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Sadece iki haneli sayıya izin ver
                      if (/^\d{0,10}$/.test(value)) {
                        setUserData({
                          ...userData,
                          phone: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyTelNo">Telefon</label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyMail"
                    value={userData.email}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 60 karaktere kadar izin ver
                      if (value.length <= 60) {
                        setUserData({
                          ...userData,
                          email: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyMail">Mail</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyUserPassword"
                    value={userData.password}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          password: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyUserPassword">Şifre</label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-12 flex justify-content-center">
              <Button
                loading={loading}
                label="Ekle"
                disabled={
                  (userType === "client" &&
                    selectedCompany?.id > 0 &&
                    userData.password.length >= 3 &&
                    userData.name.length >= 3) ||
                  (userType === "admin" &&
                    userData.password.length >= 3 &&
                    userData.name.length >= 3)
                    ? false
                    : true
                }
                severity="success"
                icon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "10px" }}
                  />
                }
                className="shadow-4"
                style={{ fontSize: 18, fontWeight: 600 }}
                onClick={() => setLoading(true) & PostUser()}
              />
            </div>
          </div>
        </>
      </Dialog>
    );
  }

  function EditUserDlgTemp() {
    return (
      <Dialog
        visible={editUserDlg}
        header="Kullanıcı Düzenle"
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        style={{ width: windowWidth <= 500 ? "100vw" : "50vw" }}
        onHide={() => {
          setEditUserDlg(false);
          setUserData({
            companyId: 0,
            accessRest: "",
            name: "",
            lastname: "",
            email: "",
            password: "",
            areaCode: "",
            phone: "",
          });
          setSelectedCompany({});
          setSelectedRestaurants([]);
        }}
      >
        <>
          <div className="grid m-0 p-0" style={{ overflowY: "auto" }}>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <div
                  className="p-inputgroup"
                  style={{
                    width: "240px",
                    border: "1px solid #9e9e9e",
                    alignItems: "center",

                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <RadioButton
                    inputId="admin"
                    name="admin"
                    value="admin"
                    onChange={(e) => setUserType(e.value)}
                    checked={userType === "admin"}
                    className="ml-4"
                  />
                  <label htmlFor="admin" className="ml-1 mr-5">
                    Admin
                  </label>
                  <RadioButton
                    inputId="client"
                    name="client"
                    value="client"
                    onChange={(e) => setUserType(e.value)}
                    checked={userType === "client"}
                  />
                  <label htmlFor="client" className="ml-2">
                    Kullanıcı
                  </label>
                </div>
              </div>
            </div>
            {userType === "client" && (
              <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
                <div className="p-inputgroup flex-1">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "gainsboro",
                      color: "black",
                    }}
                  >
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  <Dropdown
                    placeholder="Şirket Seç"
                    value={selectedCompany}
                    onChange={(e) =>
                      setSelectedCompany(e.value) & setSelectedRestaurants([])
                    }
                    options={companies}
                    optionLabel="name"
                  />
                </div>
              </div>
            )}
            {userType === "client" && selectedCompany?.id > 0 && (
              <div className="flex flex-column pl-3">
                {selectedCompany.restaurants.map((x) => (
                  <div
                    className="px-3 py-1 mb-1 shadow-4 cursor-pointer select-none"
                    style={{
                      backgroundColor: selectedRestaurants.some(
                        (item) => item?.id === x.id
                      )
                        ? "#90EE90"
                        : "gainsboro",
                      color: selectedRestaurants.some(
                        (item) => item?.id === x.id
                      )
                        ? "#006400"
                        : "grey",
                      borderRadius: 25,
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      const isHave = selectedRestaurants.some(
                        (item) => item?.id === x.id
                      );
                      if (isHave) {
                        setSelectedRestaurants(
                          selectedRestaurants.filter((a) => a?.id !== x.id)
                        );
                      } else {
                        setSelectedRestaurants([...selectedRestaurants, x]);
                      }
                    }}
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            )}
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <FloatLabel>
                  <InputText
                    id="name"
                    value={userData.name}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          name: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="name">Ad</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="lastName"
                    value={userData.lastname}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          lastname: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="lastName">Soyad</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup" style={{ flex: "0 0 150px" }}>
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyAreaCode"
                    value={userData.areaCode}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Sadece iki haneli sayıya izin ver
                      if (/^\d{0,2}$/.test(value)) {
                        setUserData({
                          ...userData,
                          areaCode: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyAreaCode">Alan Kodu</label>
                </FloatLabel>
              </div>
              <div className="p-inputgroup flex-1">
                <FloatLabel>
                  <InputText
                    id="companyTelNo"
                    value={userData.phone}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Sadece iki haneli sayıya izin ver
                      if (/^\d{0,10}$/.test(value)) {
                        setUserData({
                          ...userData,
                          phone: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyTelNo">Telefon</label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-12" style={{ display: "flex", gap: "1rem" }}>
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyMail"
                    value={userData.email}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 60 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          email: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyMail">Mail</label>
                </FloatLabel>
              </div>
            </div>
            <div className="col-12">
              <div className="p-inputgroup flex-1">
                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: "gainsboro",
                    color: "black",
                  }}
                >
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <FloatLabel>
                  <InputText
                    id="companyUserPassword"
                    value={userData.password}
                    onChange={(x) => {
                      const value = x.target.value;
                      // Yalnızca 50 karaktere kadar izin ver
                      if (value.length <= 50) {
                        setUserData({
                          ...userData,
                          password: value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="companyUserPassword">
                    Şifre (boş bırakılırsa değiştirilmez)
                  </label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-12 flex justify-content-center">
              <Button
                loading={loading}
                label="Güncelle"
                severity="success"
                icon={
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "10px" }}
                  />
                }
                className="shadow-4"
                style={{ fontSize: 18, fontWeight: 600 }}
                onClick={() => setLoading(true) & PutUser()}
              />
            </div>
          </div>
        </>
      </Dialog>
    );
  }

  return (
    <>
      {AddUserDlgTemp()}
      {EditUserDlgTemp()}
      <Toast ref={toast} />
      <div className="flex flex-row shadow-4">
        <div
          className="px-3 py-2"
          style={{
            borderBottom: "5px solid #F39237",
            fontWeight: "bold",
            fontSize: 22,
            minWidth: "fit-content",
          }}
        >
          Kullanıcı Listesi
        </div>
        <div
          style={{
            borderBottomWidth: 2,
            borderBottom: "5px solid #222d33",
            width: "100%",
          }}
        ></div>
      </div>
      {windowWidth > 500 && (
        <DataTable
          value={users}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage="Şirket bulunamadı."
          globalFilterFields={[
            "name",
            "lastname",
            "company.name",
            "email",
            "phone",
            "userLevel",
            "createdDate",
          ]}
        >
          <Column field="company.name" header="Şirket" sortable />
          <Column
            header="Yetkilendirilmiş Restoranlar"
            body={(x) => {
              let restIds = x.accessRest.split("~");
              if (restIds.length === 0 || x.userLevel === 1) {
                return "";
              }
              let comp = companies.filter((a) => a.id === x.company.id)[0];
              return (
                <div>
                  {comp.restaurants.map((c) => {
                    if (restIds.some((itm) => parseInt(itm) === c.id)) {
                      return <div>{c.name}</div>;
                    }
                  })}
                </div>
              );
            }}
          />
          <Column
            field="name"
            header="Ad ve Soyad"
            sortable
            body={(x) => (
              <div>
                {x.name} {x.lastname}
              </div>
            )}
          />
          <Column field="email" header="Mail" sortable />
          <Column
            field="phone"
            header="Telefon"
            body={(x) => x.areaCode + x.phone}
            sortable
          />
          <Column
            field="userLevel"
            header="Seviye"
            body={(x) => (x.userLevel === 1 ? "Admin" : "Kullanıcı")}
            sortable
          />
          <Column
            field="createdDate"
            header="Oluşturulma Tarihi"
            sortable
            body={(x) => moment(x.createdDate).format("HH:mm DD.MM.YYYY")}
          />
          <Column
            body={(x) => (
              <Button
                rounded
                loading={loading}
                severity="primary"
                icon={<FontAwesomeIcon icon={faPencil} />}
                className="shadow-4"
                style={{ background: "green" }}
                onClick={() => {
                  setEditUserDlg(true);
                  setUserData({
                    ...userData,
                    name: x.name,
                    lastname: x.lastname,
                    email: x.email,
                    areaCode: x.areaCode,
                    phone: x.phone,
                    id: x.id,
                  });
                  setUserType(x.userLevel === 2 ? "client" : "admin");
                  const comp = companies.filter(
                    (a) => a.id === x.company.id
                  )[0];
                  setSelectedCompany(comp);

                  const accRests = x.accessRest.split("~");
                  if (accRests.length > 0) {
                    let accRestsList = [];
                    accRests.map((a) => {
                      accRestsList = [
                        ...accRestsList,
                        comp.restaurants.filter((b) => b.id === parseInt(a))[0],
                      ];
                    });
                    setSelectedRestaurants(accRestsList);
                  }
                }}
              />
            )}
          />
        </DataTable>
      )}
      {windowWidth <= 500 && (
        <DataTable
          value={users}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage="Şirket bulunamadı."
        >
          <Column
            body={(x) => {
              let restIds = x.accessRest.split("~");
              if (restIds.length === 0 || x.userLevel === 1) {
                return (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <div className="font-bold ">{x.company.name}</div>
                      <div className="font-bold">
                        {x.name} {x.lastname}
                      </div>
                      <div className="font-semibold">
                        {x.phone} {x.email}
                      </div>
                      <div className="font-bold">
                        {x.userLevel === 1 ? "Admin" : "Kullanıcı"}
                      </div>
                      <div>
                        {moment(x.createdDate).format("DD.MM.YYYY - HH:mm")}
                      </div>
                      <div>
                        <Button
                          rounded
                          loading={loading}
                          severity="primary"
                          icon={<FontAwesomeIcon icon={faPencil} />}
                          className="shadow-4"
                          style={{ background: "green", marginTop: "10px" }}
                          onClick={() => {
                            setEditUserDlg(true);
                            setUserData({
                              ...userData,
                              name: x.name,
                              lastname: x.lastname,
                              email: x.email,
                              areaCode: x.areaCode,
                              phone: x.phone,
                              id: x.id,
                            });
                            setUserType(x.userLevel === 2 ? "client" : "admin");
                            const comp = companies.filter(
                              (a) => a.id === x.company.id
                            )[0];
                            setSelectedCompany(comp);

                            const accRests = x.accessRest.split("~");
                            if (accRests.length > 0) {
                              let accRestsList = [];
                              accRests.map((a) => {
                                accRestsList = [
                                  ...accRestsList,
                                  comp.restaurants.filter(
                                    (b) => b.id === parseInt(a)
                                  )[0],
                                ];
                              });
                              setSelectedRestaurants(accRestsList);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              }
              let comp = companies.filter((a) => a.id === x.company.id)[0];
              return (
                <>
                  <div className="font-bold" style={{ textAlign: "center" }}>
                    {x.company.name}
                  </div>
                  <div>
                    {comp.restaurants.map((c) => {
                      if (restIds.some((itm) => parseInt(itm) === c.id)) {
                        return (
                          <div
                            className="flex font-semibold"
                            style={{ justifyContent: "center" }}
                          >
                            <div style={{ color: "green" }}>{c.name} </div>
                          </div>
                        );
                      }
                    })}
                    <div style={{ textAlign: "center" }}>
                      <div className="font-bold flex-column justify-content-center ">
                        {x.name} {x.lastname}
                      </div>
                      <div className="font-semibold">
                        {x.phone} {x.email}
                      </div>
                      <div className="font-bold">
                        {x.userLevel === 1 ? "Admin" : "Kullanıcı"}
                      </div>
                      <div>
                        {moment(x.createdDate).format("HH:mm DD.MM.YYYY")}
                      </div>
                      <div>
                        <Button
                          rounded
                          loading={loading}
                          severity="primary"
                          icon={<FontAwesomeIcon icon={faPencil} />}
                          className="shadow-4"
                          style={{ background: "green" }}
                          onClick={() => {
                            setEditUserDlg(true);
                            setUserData({
                              ...userData,
                              name: x.name,
                              lastname: x.lastname,
                              email: x.email,
                              areaCode: x.areaCode,
                              phone: x.phone,
                              id: x.id,
                            });
                            setUserType(x.userLevel === 2 ? "client" : "admin");
                            const comp = companies.filter(
                              (a) => a.id === x.company.id
                            )[0];
                            setSelectedCompany(comp);

                            const accRests = x.accessRest.split("~");
                            if (accRests.length > 0) {
                              let accRestsList = [];
                              accRests.map((a) => {
                                accRestsList = [
                                  ...accRestsList,
                                  comp.restaurants.filter(
                                    (b) => b.id === parseInt(a)
                                  )[0],
                                ];
                              });
                              setSelectedRestaurants(accRestsList);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </DataTable>
      )}
    </>
  );
}

export default Users;
