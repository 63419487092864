import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    userInfo: {
      token: "",
      email: "",
      lastname: "",
      name: "",
      userId: 0,
      userlevel: 0,
    },
  },
  ddRestaurants: [],
  ddSelectedRestaurant: {},
  reducers: {
    userInfoCRUD: (state, action) => {
      state.userInfo = action.payload;
    },
    ddRestaurantsCRUD: (state, action) => {
      state.ddRestaurants = action.payload;
    },
    ddSelectedRestaurantCRUD: (state, action) => {
      state.ddSelectedRestaurant = action.payload;
    },
  },
});

export const { userInfoCRUD, ddRestaurantsCRUD, ddSelectedRestaurantCRUD } =
  counterSlice.actions;
export default counterSlice.reducer;
