import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";

import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faChevronLeft,
  faChevronRight,
  faPerson,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function ReservationReports() {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const { ddSelectedRestaurant } = useSelector((state) => state.counter);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservations, setReservations] = useState([]);
  const [fullReservations, setFullReservations] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [updateDlg, setUpdateDlg] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState({});
  const [restaurant, setRestaurant] = useState({});
  const [loadingFast, setLoadingFast] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    return;
    if (ddSelectedRestaurant?.id === undefined) return;
    const connection = new HubConnectionBuilder()
      .withUrl("https://localhost:7100/msghub")
      .withAutomaticReconnect()
      .build();

    let conId = ddSelectedRestaurant?.id + ""; // conversation id customer ekranları için veya restoran için restid

    connection
      .start()
      .then(() => {
        connection
          .invoke("RegisterTransaction", conId)
          .then(() => {})
          .catch((err) => console.error(err));

        connection.on("ReceiveMessage", (message) => {
          //1~3  - yeni rezervasyon ~ resId
          //2~5  - rezervasyon güncellemesi ~ resId
          GetReservations();
        });
      })
      .catch((err) => console.error("Bağlantı hatası:", err));

    return () => {
      connection.stop();
    };
  }, [ddSelectedRestaurant?.id, selectedDate]);
  addLocale("tr", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["P", "P", "S", "Ç", "P", "C", "C"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });
  addLocale("en", {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    today: "Today",
    clear: "Clear",
  });
  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) return;

    GetReservations();
  }, [ddSelectedRestaurant?.id, selectedDate]);

  useEffect(() => {
    if (ddSelectedRestaurant?.id === undefined) return;

    axios.get(`clientAIO/menulist/${ddSelectedRestaurant?.id}`).then((x) => {
      setRestaurant(x.data);
      //setSelectedMeal(x.data.mealTimes[0]);
    });
  }, [ddSelectedRestaurant?.id]);

  function GetReservations() {
    setLoading(true);
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth() + 1;
    axios
      .get(
        `clientAIO/reservations/monthly/${ddSelectedRestaurant?.id}/${selectedYear}/${selectedMonth}`
      )
      .then((x) => {
        // Response verilerini işle
        setLoading(false);
        setReservations(x.data);
        setFullReservations(x.data);
      });
  }

  function StatusTemp(status) {
    let bgColor = "";
    let color = "";
    let txt = "";

    switch (status) {
      case 0:
        bgColor = "white";
        color = "black";
        txt = t("konfirmeDegil");
        break;
      case 1:
        bgColor = "#fed7b2";
        color = "#faac61";
        txt = t("gelmedi");
        break;
      case 2:
        bgColor = "#a0bfbf";
        color = "#3e9393";
        txt = t("konfirme");
        break;
      case 3:
        bgColor = "#b2dab7";
        color = "#39b54a";
        txt = t("geldi");
        break;
      case 4:
        bgColor = "#f8bebe";
        color = "#e34c4c";
        txt = t("iptal");
        break;
      case 5:
        bgColor = "#a6c0a9";
        color = "#388643";
        txt = t("tamamlandi");
        break;
      case 6:
        bgColor = "#de9ee6";
        color = "#d46ee0";
        txt = t("bekliyor");
        break;
      case 7:
        bgColor = "white";
        color = "black";
        txt = t("iadeEdildi");
        break;
      case 8:
        bgColor = "pink";
        color = "darkred";
        txt = t("kiptali");
        break;

      default:
        break;
    }

    return (
      <div className="flex align-items-center justify-content-center">
        <div
          className="px-3 py-1 text-center"
          style={{
            backgroundColor: bgColor,
            color: color,
            textTransform: "uppercase",
            fontWeight: "800",
            borderRadius: 5,
            width: "fit-content",
            fontSize: 14,
          }}
        >
          {txt}
        </div>
      </div>
    );
  }

  const renderHeader = () => {
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters["global"].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const pendingReservationsCount = reservations.filter(
      (r) => r.status === 6
    ).length;
    const pendingGuestsCount = reservations
      .filter((r) => r.status === 6)
      .reduce((acc, curr) => acc + curr.guestCount, 0);

    const cancelledReservationsCount = reservations.filter(
      (r) => r.status === 4 && 8
    ).length;
    const cancelledGuestsCount = reservations
      .filter((r) => r.status === 4 && 8)
      .reduce((acc, curr) => acc + curr.guestCount, 0);

    const completedReservationsCount = reservations.filter(
      (r) => r.status === 5
    ).length;
    const completedGuestsCount = reservations
      .filter((r) => r.status === 5)
      .reduce((acc, curr) => acc + curr.guestCount, 0);

    const noShowReservationsCount = reservations.filter(
      (r) => r.status === 1
    ).length;
    const noShowGuestsCount = reservations
      .filter((r) => r.status === 1)
      .reduce((acc, curr) => acc + curr.guestCount, 0);

    const totalReservationsCount = reservations.length;
    const totalGuestsCount = reservations.reduce((total, guest) => {
      return total + guest.guestCount;
    }, 0);
    return (
      <>
        {windowWidth > 500 && (
          <div className="flex justify-content-between align-items-center">
            <div>
              <div
                className="shadow-4 p-3 flex flex-column gap-1"
                style={{
                  borderRadius: 5,
                  backgroundColor: "#f8f8f8",
                  color: "black",
                }}
              >
                <div className="notranslate">
                  {t("manuel") + ": "}
                  {reservations.filter((r) => r.type === 2).length}
                </div>
                <div className="notranslate">
                  {t("cevrimici") + ": "}
                  {reservations.filter((r) => r.type === 3).length}
                </div>
              </div>
            </div>
            <div className="flex align-items-center">
              <div
                className="mr-2 cursor-pointer"
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setMonth(newDate.getMonth() - 1); // Bir sonraki ayı ayarla
                  setSelectedDate(newDate);
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} fontSize={30} />
              </div>
              <Calendar
                readOnlyInput
                locale={i18n.language}
                variant="filled"
                inputStyle={{ textAlign: "center", width: "min-content" }}
                value={selectedDate}
                view="month" // Ay bazında seçim
                dateFormat="MM yy" // Ay ve Yıl formatı
                onChange={(e) => setSelectedDate(e.value)}
                showButtonBar
                clearButtonClassName="hidden"
              />
              <div
                className="ml-2 cursor-pointer"
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setMonth(newDate.getMonth() + 1); // Bir sonraki ayı ayarla
                  setSelectedDate(newDate);
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} fontSize={30} />
              </div>
            </div>

            <InputText
              value={globalFilterValue}
              style={{ width: "300px" }}
              onChange={onGlobalFilterChange}
              placeholder={t("ara")}
            />
          </div>
        )}
        {windowWidth <= 500 && (
          <div className="flex flex-column justify-content-between align-items-center gap-2">
            <div>
              <div className="notranslate">
                {t("manuel") + ":"}{" "}
                {reservations
                  .filter((r) => r.type === 2)
                  .reduce((acc, curr) => acc + curr.guestCount, 0)}
              </div>
              <div className="notranslate">
                {t("cevrimici") + ":"}{" "}
                {reservations
                  .filter((r) => r.type === 3)
                  .reduce((acc, curr) => acc + curr.guestCount, 0)}
              </div>
            </div>
            <div className="flex align-items-center">
              <div
                className="mr-2 cursor-pointer"
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setMonth(newDate.getMonth() - 1); // Bir sonraki ayı ayarla
                  setSelectedDate(newDate);
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} fontSize={30} />
              </div>
              <Calendar
                readOnlyInput
                variant="filled"
                locale={i18n.language}
                inputStyle={{ textAlign: "center", width: "min-content" }}
                value={selectedDate}
                view="month" // Ay bazında seçim
                dateFormat="MM yy" // Ay ve Yıl formatı
                onChange={(e) => setSelectedDate(e.value)}
                showButtonBar
                clearButtonClassName="hidden"
              />
              <div
                className="ml-2 cursor-pointer"
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setMonth(newDate.getMonth() + 1); // Bir sonraki ayı ayarla
                  setSelectedDate(newDate);
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} fontSize={30} />
              </div>
            </div>

            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder={t("ara")}
            />
          </div>
        )}

        <div style={{ paddingTop: "30px", overflowWrap: "anywhere" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",

              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="2"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",

                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: `clamp(10px, ${windowWidth * 0.02}px, 13px)`,
                    textAlign: "center",
                  }}
                >
                  {t("bekleyen")}
                </th>
                <th
                  colSpan="2"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: `clamp(10px, ${windowWidth * 0.02}px, 13px)`,
                    textAlign: "center",
                  }}
                >
                  {t("iptalEdilmis")}
                </th>
                <th
                  colSpan="2"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: `clamp(10px, ${windowWidth * 0.02}px, 13px)`,
                    textAlign: "center",
                  }}
                >
                  {t("tamamlanmis")}
                </th>
                <th
                  colSpan="2"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: `clamp(10px, ${windowWidth * 0.02}px, 13px)`,
                    textAlign: "center",
                  }}
                >
                  {t("gelmeyen")}
                </th>
                <th
                  colSpan="2"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: `clamp(10px, ${windowWidth * 0.02}px, 13px)`,
                    textAlign: "center",
                  }}
                >
                  {t("toplam")}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    backgroundColor: "#474f54",

                    color: "white",
                    fontSize: `clamp(10px, ${windowWidth * 0.02}px, 13px)`,
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faRectangleList} />
                  ) : (
                    t("adet")
                  )}
                </th>
                <th
                  style={{
                    backgroundColor: "#474f54",

                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faPerson} />
                  ) : (
                    t("kisi")
                  )}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faRectangleList} />
                  ) : (
                    t("adet")
                  )}
                </th>
                <th
                  style={{
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faPerson} />
                  ) : (
                    t("kisi")
                  )}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faRectangleList} />
                  ) : (
                    t("adet")
                  )}
                </th>
                <th
                  style={{
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faPerson} />
                  ) : (
                    t("kisi")
                  )}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faRectangleList} />
                  ) : (
                    t("adet")
                  )}
                </th>
                <th
                  style={{
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faPerson} />
                  ) : (
                    t("kisi")
                  )}
                </th>
                <th
                  style={{
                    borderLeft: "1px solid black",
                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faRectangleList} />
                  ) : (
                    t("adet")
                  )}
                </th>
                <th
                  style={{
                    borderRight: "1px solid black",

                    backgroundColor: "#474f54",
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {windowWidth <= 500 ? (
                    <FontAwesomeIcon icon={faPerson} />
                  ) : (
                    t("kisi")
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {pendingReservationsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {pendingGuestsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {cancelledReservationsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {cancelledGuestsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {completedReservationsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {completedGuestsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {noShowReservationsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {noShowGuestsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {totalReservationsCount}
                </td>
                <td
                  className="notranslate"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  {totalGuestsCount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };
  const header = renderHeader();

  return (
    <>
      {windowWidth > 500 && (
        <DataTable
          value={globalFilterValue === "" ? reservations : fullReservations}
          paginator
          rows={30}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage={t("rezBulunamadi")}
          stripedRows
        >
          <Column
            field="time"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            alignHeader={"center"}
            body={(x) => (
              <div className="text-center">
                <div className="font-semibold">
                  {moment(x.date).format("DD.MM.YYYY")} - {x.time.slice(0, 5)}
                </div>
                <div className=" text-sm font-semibold">
                  {" "}
                  {i18n.language === "en" ? x.mealTimeNameEn : x.mealTimeName}
                </div>
                <div className="text-sm font-semibold">
                  {t("olusturulma") + ":"}{" "}
                  {moment(x.createdDate).format("DD.MM.YYYY - HH:mm")}
                </div>
              </div>
            )}
            sortable
            header={t("rezTarihi")}
          />
          <Column
            field="status"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            body={(a) => StatusTemp(a.status)}
            alignHeader={"center"}
            header={t("durum")}
            sortable
          />
          <Column
            field="customer.name"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            body={(x) => (
              <div className="text-center">
                <div
                  className="font-bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {x.customer.name}
                </div>
                {x.type === 2 && (
                  <div className="font-semibold text-sm">{t("manuel")}</div>
                )}
                {x.type === 3 && (
                  <div className="font-semibold text-sm">{t("cevrimici")}</div>
                )}
              </div>
            )}
            alignHeader={"center"}
            header={t("musteri")}
            sortable
          />
          <Column
            field="reservationToken"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            className="font-semibold"
            alignHeader={"center"}
            align={"center"}
            header={t("rezKodu")}
            sortable
          />
          <Column
            field="guestCount"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            className="font-bold"
            alignHeader={"center"}
            align={"center"}
            header={t("kisi")}
            sortable
          />
          <Column
            field="customerNote"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            style={{ maxWidth: "200px" }}
            className="font-semibold text-sm"
            alignHeader={"center"}
            align={"center"}
            header={t("musteriNotu")}
            sortable
          />
          <Column
            field="notes"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            style={{ maxWidth: "200px" }}
            className="font-semibold text-sm"
            alignHeader={"center"}
            align={"center"}
            header={t("restoranNotu")}
            sortable
          />
        </DataTable>
      )}
      {windowWidth <= 500 && (
        <DataTable
          value={globalFilterValue === "" ? reservations : fullReservations}
          paginator
          rows={30}
          dataKey="id"
          filters={filters}
          loading={loading}
          header={header}
          emptyMessage={t("rezBulunamadi")}
          stripedRows
          globalFilterFields={[
            "customer.name",
            "customer.phone",
            "time",
            "reservationToken",
            "notes",
            "customerNote",
          ]}
        >
          <Column
            field="time"
            headerStyle={{
              backgroundColor: "#222d33",
              color: "white",
              fontWeight: 600,
            }}
            body={(x) => (
              <div className="text-center">
                <div className="font-semibold">
                  {moment(x.date).format("DD.MM.YYYY")} - {x.time.slice(0, 5)}
                </div>
                <div className=" text-sm font-semibold">
                  {" "}
                  {i18n.language === "en" ? x.mealTimeNameEn : x.mealTimeName}
                </div>
                <div className="text-sm font-semibold">
                  {t("olusturulma") + ":"}{" "}
                  {moment(x.createdDate).format("DD.MM.YYYY - HH:mm")}
                </div>
                {StatusTemp(x.status)}
                <div className="text-center">
                  <div
                    className="font-bold"
                    style={{ textTransform: "capitalize" }}
                  >
                    {x.customer.name}
                  </div>
                  {x.type === 2 && (
                    <div className="font-semibold text-sm">{t("manuel")}</div>
                  )}
                  {x.type === 3 && (
                    <div className="font-semibold text-sm">
                      {t("cevrimici")}
                    </div>
                  )}
                </div>
                <div className="font-semibold text-md mt-2">
                  {x.reservationToken}
                </div>
                <div className="font-semibold text-md mt-2">
                  {x.guestCount} {t("kisi")}
                </div>
                <div className="mt-3 text-sm font-semibold">
                  {x.customerNote}
                </div>
                <div className="mt-3 text-sm font-semibold">{x.notes}</div>
              </div>
            )}
          />
        </DataTable>
      )}
    </>
  );
}

export default ReservationReports;
